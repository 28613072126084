import { OrderDetailsState } from "../Components/OrderList/OrderDetails";
import { Focus } from "../lib/DOMActions";
import { isEmpty } from "../lib/ValueChecks";
import { ImgFileToObject } from "../Utils/ImageReader";
import API from "./API";

export default class OrderActions {
    static Aprobar(state: OrderDetailsState) {
        const { _pagos, total } = state;
        if (total && !(_pagos && _pagos.length)) return alert("Necesitas registrar al menos un pago.");
        for (let index = 0; index < _pagos.length; index++) {
            if (!PagoEsValido(_pagos[index], index)) return;
        }
        let cf = window.confirm("Esta seguro de APROBAR este pedido?");
        if (!cf) return;
        return API.ActionOrder(state.id, { action: "APROBAR", value: _pagos });
    }
    static Rechazar(state: OrderDetailsState) {
        let cf = window.confirm("Esta seguro de RECHAZAR este pedido?");
        if (!cf) return;
        let value = window.prompt("Digita el Motivo de Rechazo");
        if (!value) return;
        return API.ActionOrder(state.id, { action: "RECHAZAR", value })
    }
    static Enviar(state: OrderDetailsState) {
        const { _fechaEnvio, _envioImgFile, _envioImgSrcStatus } = state;
        if (!(_fechaEnvio && _fechaEnvio.toString().trim().length && !isNaN(+new Date(_fechaEnvio)))) {
            alert("Agregue una fecha de envío válida");
            return Focus("_fechaEnvio");
        }
        if (_envioImgSrcStatus) return alert("No puede continuar, la imagen se está procesando");

        const cf = window.confirm("Esta seguro de seleccionar este pedido como ENVIADO?");
        if (!cf) return;
        const value = new Date(
            +_fechaEnvio.split("-")[0],
            (+_fechaEnvio.split("-")[1]) - 1,
            +_fechaEnvio.split("-")[2]
        );
        var promise = Promise.resolve() as Promise<any>;
        if (_envioImgFile)
            promise = ImgFileToObject(_envioImgFile);

        return promise.then(imgFile =>
            API.ActionOrder(state.id, { action: "ENVIAR", value, imgFile })
        );
    }/*
    static Recibir(state: OrderDetailsState) {
        const { _fechaRecepcion, bienRecibido, observacionRecepcion } = state;
        if (!(_fechaRecepcion && _fechaRecepcion.toString().trim().length && !isNaN(+new Date(_fechaRecepcion)))) {
            alert("Agregue una fecha de recepción válida");
            window.requestAnimationFrame(function () {
                document.getElementById("_fechaRecepcion").focus();
            });
            return;
        }
        if (!bienRecibido && !(observacionRecepcion && observacionRecepcion.toString().trim().length)) {
            alert("Agregue la observación de la Recepción");
            window.requestAnimationFrame(function () {
                document.getElementById("observacionRecepcion").focus();
            });
            return;
        }
        let value = new Date(
            +_fechaRecepcion.split("-")[0],
            (+_fechaRecepcion.split("-")[1]) - 1,
            +_fechaRecepcion.split("-")[2]
        );
        if (value.valueOf() < new Date(state.fechaEnvio).valueOf()) {
            alert("La fecha de Recepción no puede ser menor a la fecha de envío");
            window.requestAnimationFrame(function () {
                document.getElementById("_fechaRecepcion").focus();
            });
        }

        let cf = window.confirm("Esta seguro de seleccionar este pedido como RECIBIDO?");
        if (!cf) return;

        return API.ActionOrder(state.id, {
            action: "RECIBIR",
            value,
            bienRecibido,
            observacionRecepcion
        });
    }*/
    static Facturar(state: OrderDetailsState) {
        if (!(state.numeroFactura && state.numeroFactura.toString().trim().length)) {
            alert("Agregue el número de factura");
            return Focus("numeroFactura");
        }
        let cf = window.confirm("Esta seguro de FACTURAR este pedido?");
        if (!cf) return;
        return API.ActionOrder(state.id, { action: "FACTURAR", value: state.numeroFactura });
    }
}


function PagoEsValido(pago: SavePayment, index: number) {
    if (isEmpty(pago.metodoPago)) {
        alert("Selecciona el metodo de pago");
        return Focus("pago#" + index + ".metodoPago");
    }
    if (isEmpty(pago.numeroTransaccion)) {
        alert("Digita el número de transaccion");
        return Focus("pago#" + index + ".numeroTransaccion");
    }
    if (isEmpty(pago.monto)) {
        alert("Digita el monto recibido");
        return Focus("pago#" + index + ".monto");
    }
    return true;
}