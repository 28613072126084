import React from "react";
import API from "../../Services/API";

export default function (userId: number, total_pagar: number) {
    const [comisiones, set_comisiones] = React.useState([] as Comission[]);
    const [status, set_status] = React.useState<comission_type>("initializing");
    const [error, set_error] = React.useState(null as Error | null);
    React.useEffect(() => {
        if (status !== "fetch-comission") return;
        if (!(userId && total_pagar)) {
            set_comisiones([]);
            set_status("");
            set_error(null);
            return;
        }
        API.GetPendingComissions(userId, total_pagar || 0)
            .then(results => {
                set_comisiones(results);
                set_error(null);
                set_status("");
            }).catch(ex => {
                set_error(ex);
                set_status("error");
            });
    }, [status]);

    React.useEffect(() => {
        set_status("fetch-comission");
    }, [userId, total_pagar]);


    return [["fetch-comission", "initializing"].indexOf(status) >= 0, comisiones, error] as [boolean, Comission[], Error | null];
}

type comission_type = "fetch-comission" | "error" | "" | "initializing";