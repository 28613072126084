import React from "react";
import { Grid, TextField, InputAdornment, Typography, CircularProgress } from "@material-ui/core";
import ProductCard from "./ProductCard";
import { Search } from "@material-ui/icons";
import { OrderReduceState } from "../Order/Redux/OrderReducer";

export default function ProductGrid(props: {
    items: FullProduct[];

    onActionClick?(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
    editablePrices?: boolean;
    onPriceChange?(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void;

    controls?: "ADD-REMOVE";
    onCardClick?(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
    showNewItem?: boolean;
    async_status?: OrderReduceState["status"]
    async_error?: string;
}) {
    const [search, set_search] = React.useState("");

    const Products = props.items.filter(
        x => search ? x.nombre.toUpperCase().trim().indexOf((search || "").toUpperCase().trim()) >= 0 : true
    );

    if (props.async_status === "fetch-data:loading")
        return (<div style={{ width: "100%", textAlign: "center", paddingTop: 100 }} >
            <CircularProgress size={100} />
            <Typography variant="h6">CARGANDO...</Typography>
        </div>);


    if (props.async_status === "fetch-data:completed" && !Products.length) var ThereIsNotProducts = true;

    if (props.async_status === "fetch-data:error")
        return (<div style={{ width: "100%", textAlign: "center", paddingTop: 100 }} >
            <Typography variant="h4">Ocurrió un error</Typography>
            {props.async_error && <Typography variant="h4">{props.async_error}</Typography>}
        </div>);

    return (<>
        <TextField
            variant="outlined"
            fullWidth
            placeholder="Buscar..."
            style={{
                padding: 10
            }}
            value={search}
            type="search"
            onChange={event => set_search(event.target.value)}
            InputProps={{
                startAdornment: <InputAdornment position="start"> <Search /></InputAdornment>
            }} />
        {!ThereIsNotProducts ? null : (<div style={{ width: "100%", textAlign: "center", paddingTop: 100 }}>
            <Typography variant="h6">No hay productos</Typography>
        </div>)}
        <Grid
            container
            alignItems="center"
            style={{ flex: 1, marginTop: 5 }}
            spacing={3}>
            {[...Products].sort((a, b) => a.orden - b.orden).map(item => (
                <Grid item sm={4} md={3} xs={12} key={item.id}>
                    <ProductCard
                        {...item}
                        editablePrice={props.editablePrices}
                        onPriceChange={props.onPriceChange}
                        onActionClick={props.onActionClick}
                        onCardClick={props.onCardClick}
                        controls={props.controls}
                    />
                </Grid>
            ))}
            {props.showNewItem && <Grid item sm={4} md={3} xs={12}>
                <ProductCard
                    id="NUEVO"
                    imgSrc={process.env.PUBLIC_URL + "/add.svg"}
                    pvp_final={0}
                    nombre="AGREGA UN NUEVO ITEM"
                    onCardClick={props.onCardClick}
                />
            </Grid>}
        </Grid>
    </>)
}