import React from "react";
import PersonalInfoBlock, { PersonalInfoBlockInputContent } from "../Users/UserForm/PersonalInfoBlock";
import LoadingButton from "../Common/LoadingButton";
import API from "../../Services/API";
export default function CustomerPersonalData(props: {
    id?: React.ReactText
    onSaved(item?: { id: string | number }): void
    onLoadingStateChanged?(isLoading: boolean): void
}) {

    const [personal_info, set_personal_info] = React.useState({} as PersonalInfoBlockInputContent & { id?: React.ReactText });
    const [status, set_status] = React.useState("LOADING");
    const validationErrors = {};

    const onChange: React.ComponentProps<typeof PersonalInfoBlock>["onChange"] = e => {
        set_personal_info({ ...personal_info, [e.target.name]: e.target.value });
    }
    const isLoading = ["LOADING", "SUBMIT", "DELETE"].includes(status);

    React.useEffect(() => {
        console.info("Loading status " + status);
        if (status !== "LOADING") return;
        if ((props.id || "new") === "new") {
            set_personal_info({});
            set_status("");
            return;
        }
        var promise = API.GetCRMCustomer(props.id);
        promise.then(data => {
            set_personal_info(data);
            set_status("");
        }).catch(ex => {
            alert(ex.message);
            set_status("ERROR");
        })
        return () => promise.abort();

    }, [status]);

    React.useEffect(() => {
        if (status !== "SUBMIT") return;
        var promise = props.id ? API.updateCRMCustomer({ ...personal_info, id: props.id })
            : API.createCRMCustomer(personal_info);
        promise.then(x => {
            set_status("SAVED");
            set_personal_info({ ...personal_info, id: x["id"] })
            props.onSaved && props.onSaved(x);
        }).catch(ex => {
            alert(ex.message);
            set_status("");
        })
        return () => promise.abort();
    }, [status]);

    return (<form onSubmit={e => {
        e.preventDefault();
        if (isLoading) return;
        set_status("SUBMIT");
    }}>
        <PersonalInfoBlock
            {...personal_info}
            loading={isLoading}
            error={validationErrors}
            onChange={onChange}
        />
        <div>
            <LoadingButton isLoading={isLoading} type="submit" color="primary" variant="outlined" >
                {status === "SAVED" ? "Guardado con éxito" : "Guardar"}
            </LoadingButton>
            {null
                ? <LoadingButton onClick={() => {
                    if (isLoading) return;
                    set_status("DELETE");
                }} isLoading={isLoading} disabled={!props.id} type="button" color="secondary" variant="text" >Eliminar</LoadingButton>
                : null}
        </div>
    </form>)
}