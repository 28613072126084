import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../../../Services/API";


const FetchPremioInitialData = createAsyncThunk(
    "Premio/FetchData"
    , function (userId: number, Thunk) {
        return Promise.all([
            API.GetProducts({ tipo_catalogo: "premios" })
            , API.GetUserPoints(userId)
        ]).catch(ex => {
            return Thunk.rejectWithValue(ex);
        });
    }
);

export default FetchPremioInitialData;