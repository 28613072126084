import { IconButton, List, ListItem, ListItemSecondaryAction } from "@material-ui/core";
import { Delete, Add } from "@material-ui/icons";
import React from "react";
import RegisterPayment from "../Payment/RegisterPayment";

export default function (props: {
    onChange(new_value: SavePayment[]): void;
    value?: SavePayment[]
}) {
    var pagos = props.value || [];

    const deleteItem = function (item) {
        var index = pagos.indexOf(item);
        const new_array = [...pagos];
        new_array.splice(index, 1);
        props.onChange(new_array);
    }
    const changeValue = function (name: string, value: string, item: SavePayment) {
        var index = pagos.indexOf(item);
        const new_pago = { ...item, [name]: value };
        const new_array = [...pagos];
        new_array[index] = new_pago;
        props.onChange(new_array);
    }
    const addItem = function () {
        props.onChange([...pagos, {
            metodoPago: "",
            monto: "",
            numeroTransaccion: "",
        }])
    }

    return (<List>
        <ListItem button onClick={() => addItem()} >AGREGAR PAGO <Add /></ListItem>
        {pagos.map((item, index) => {
            return (<ListItem key={index}>
                <div style={{ flex: 1 }}>
                    <RegisterPayment
                        {...item}
                        add_to_id={"" + index}
                        onChange={(propName, value) => changeValue(propName, value, item)}
                    />
                </div>
                <ListItemSecondaryAction >
                    <IconButton onClick={() => deleteItem(item)} type="button">
                        <Delete />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>)
        })}
    </List>)
}