import { OrderDetalle } from "../Components/Order/mapOrderState";

export function Totals(items: OrderDetalle[]) {
    const result = {
        total: 0,
        cantidad: 0,
        puntosAcumulados: 0
    }
    for (let i = 0; i < items.length; i++) {
        const item = items[i];
        result.cantidad += item.cantidad;
        result.total += (item.pvp_final * item.cantidad);
        if (item.puntos && item.cantidad)
            result.puntosAcumulados = result.puntosAcumulados + (item.puntos * item.cantidad);
        result.puntosAcumulados = result.puntosAcumulados.moneyRound(0);
    }
    return result;
}

export function EstimarDescuento(total: number, tablaDescuentos: Descuento[]) {
    // de MAYOR a MENOR
    var sorted = tablaDescuentos.sort((a, b) => b.montoDesde - a.montoDesde);
    var founded = -1;
    var results: DescuentoCalc = {
        porcentaje: 0,
        valor: 0,
        sgte_descuento_porcentaje: 0,
        sgte_descuento_falta: 0,
        id: undefined
    }
    for (let i = 0; i < sorted.length; i++) {
        const d = sorted[i];
        if (total >= d.montoDesde) {
            founded = i;
            break;
        }
    }
    if (founded >= 0) {
        var desc = sorted[founded];
        results.porcentaje = desc.porcentaje;
        results.valor = (total * desc.porcentaje / 100);
        results.id = desc.id;
        // si no es el ultimo, busco al siguiente en la tabla
    }
    // si no existe, simulo que es el (MAXIMO+1), es decir esta fuera del rango
    if (founded === -1) founded = sorted.length;
    if ((founded - 1) >= 0) {
        var next = sorted[founded - 1];
        if (next) {
            results.sgte_descuento_falta = next.montoDesde - total;
            results.sgte_descuento_porcentaje = next.porcentaje;
        }
    }
    return results;
}

export function DocToString(doc: "1" | "2" | "3") {
    if (!doc) doc = "" as any;
    if (doc.toString() === "1") return "RUC";
    if (doc.toString() === "2") return "Cedula";
    if (doc.toString() === "3") return "Pasaporte";
    return "";
}

export function MonthToString(index: number) {
    var months = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sept", "Oct", "Nov", "Dic"];
    return months[index];
}
