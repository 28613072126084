import { mapOrderStateProps } from "../Components/Order/mapOrderState";

export default class GiftRulesService {
    ApplyRules(rules: GiftRule[], pedido: mapOrderStateProps["order"]): Array<FullProduct & { cantidad: number }> {
        const regalos: Array<FullProduct & { cantidad: number }> = [];
        for (const rule of rules) {
            var passed_assertions = rule.condiciones.filter(x =>
                new Function(
                    `var pedido= arguments[0]; return ${x.propName} ${x.operator} ${x.value};`
                )(pedido)
            );
            if (passed_assertions.length !== rule.condiciones.length) continue;
            regalos.push(
                ...rule.regalos.map(r => ({
                    id: r.id,
                    nombre: r.nombre,
                    imgSrc: "",
                    pvp_final: r.pvp_final,
                    aplica_comision: false,
                    aplica_descuento: false,
                    cantidad: r.cantidad,
                    idReglaRegalo: rule.id,
                    tipo_catalogo: "regular" as "regular"
                }))
            );
        }
        return regalos;
    }
}