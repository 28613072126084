import React from "react";
import { FormControlLabel, Checkbox } from "@material-ui/core";

export default function (props: {
    onChange(event: React.ChangeEvent<HTMLInputElement>),
    checked: boolean,
    name?: string,
    disabled?: boolean
}) {
    return (<FormControlLabel
        style={{ textAlign: "left", margin: "auto" }}
        control={<Checkbox
            disabled={props.disabled}
            checked={props.checked}
            onChange={props.onChange}
            name={props.name}
            style={{ marginRight: 10 }}
        />}
        label={<>
            Acepto los <a target="_blank" href="https://natucosmetic.com/terminos-y-condiciones-de-uso/">términos y condiciones</a>,
            y acepto la <a target="_blank" href="https://natucosmetic.com/politicas-de-privacidad/">Política de Privacidad</a>
        </>}
    />);
}
