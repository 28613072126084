import React from "react";
import { Router, Switch, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import Login from "./Components/Login";
import Main from "./Layouts/Main";
import API from "./Services/API";
import SignIn from "./Layouts/SignIn";
import Preferences from "./Utils/Preferences";

export default class App extends React.Component {
    state: { user?: LoggedIn } = { user: undefined };
    history = createBrowserHistory();
    constructor(props: any) {
        super(props);
        if (props.user) {
            this.state.user = props.user;
            API.KEY = props.user.token;
        }
    }
    handleLoggedIn = (user: LoggedIn) => {
        // aun esta pendiente, por lo que tiene que ir directamente al curso
        API.KEY = user.token;
        try {
            Preferences.setPrefix(user.id ? (user.id + "_") : "");
        } catch (ex) {
            console.info("Ocurrio un error en Preferences.setPrefix", ex);
        }
        // esto debre ir al final, para que API ya esté inicializado antes
        this.setState({ user });
    }
    render() {
        const { user } = this.state;
        var user_routes;
        if (user) {
            switch (user.estado) {
                case "RECHAZADO":
                    user_routes = <Route>USUARIO ESTÁ DESHABILITADO</Route>;
                    break;
                default:
                    user_routes = (<Route render={() => <Main {...user} />} />);
                    break;
            }
        }
        return (<Router history={this.history} >
            {user
                ? user_routes
                : (<Switch>
                    <Route path="/signin" component={SignIn} />
                    <Route path="/recover-password">AQUI PUEDE RECUPERAR TU CONTRAEÑA</Route>
                    <Route render={_ => <Login onLoggedIn={this.handleLoggedIn} />} />
                </Switch>)
            }
        </Router>)
    }
}