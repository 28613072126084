import React from "react";
import { makeStyles, Chip, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { Link } from "react-router-dom";
const useStyles = makeStyles({
    container: {
        textAlign: "center"
    }
});
export default function (props: {
    disponible: number
}) {
    const classes = useStyles();

    const theme = useTheme();
    const isXS = useMediaQuery(theme.breakpoints.down('xs'));

    if (props.disponible === null || props.disponible === undefined) return null;
    return (<Chip
        component={Link}
        to={props.disponible ? "/user-points" : "/order"}
        color="secondary"
        variant={props.disponible ? "default" : "outlined"}
        className={classes.container}
        clickable
        label={
            <Typography variant={isXS ? "body1" : "subtitle1"}>
                {props.disponible
                    ? (<>
                        ✨ Tienes <strong>{props.disponible}</strong> puntos disponibles. <strong>Canjealos aquí</strong> ✨
                </>)
                    : (<>No tienes puntos disponibles. Haz tu <strong>pedido</strong> ahora</>)}
            </Typography>
        }
    />)
}