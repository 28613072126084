import React from "react";
import { Container, makeStyles, CircularProgress } from "@material-ui/core"


const useStyles = makeStyles({
    container: {
        display: "table"
        , width: "100%"
        , height: "100%"
    }

    , cell: {
        display: "table-cell",
        verticalAlign: "middle",
        textAlign: "center"
    }
});


export default function LoadingPage(props: {
    size?: number
    , color?: React.ComponentProps<typeof CircularProgress>["color"]
}) {
    const classes = useStyles();
    return (<Container className={classes.container} >
        <div className={classes.cell}>
            <CircularProgress size={props.size || 80} color={props.color || "primary"} />
        </div>
    </Container>)
}