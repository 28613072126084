import React from "react";

export default function (users: MultiLevelUser[]) {
    return React.useMemo(() => users.reduce((a, b) => {
        if (b.isCurrentUser) return a;
        if (b.esUsuarioNuevo) a.nuevos++;
        if (b.total_comisionable > 0) a.activos++;
        a.total++;
        return a;
    }, {
        total: 0,
        activos: 0,
        nuevos: 0
    }), [users, users.length]);
}