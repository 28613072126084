import React from "react";
import { Grid, Typography, Theme, makeStyles } from "@material-ui/core";
import RankingCard, { RankProps } from "./RankingCard";
import API from "../../Services/API";
import LineCard from "./LineCard"
import ProfitCard from "./ProfitCard";
import PieCard from "./PieCard";
import WithTimeTravel from "./WithTimeTravel";
import CardWithCycleTravel from "./lib/CardWithCycleTravel";
import HierarchyFeed from "./HierarchyFeed";
import HierarchyGraph, { ExplanationContainer } from "./HierarchyFeed/HierarchyGraph";
import ComisionCard from "./ComisionCard";
import AvailablePointsBadge from "./AvailablePointsBadge";
import Alert from "@material-ui/lab/Alert";
import LoadingPage from "../Common/Loading";
import ErrorPage from "../Common/Error";
import { useRouteMatch } from "react-router-dom";
import DataTable from "../DataTable";

const TimeTravelRank = WithTimeTravel<RankProps>(RankingCard);
const PersonalGraph = WithTimeTravel(LineCard);
const TimeTravelHierarchyFeed = CardWithCycleTravel(HierarchyFeed);
const TimeTravelDescendantsHierarchyGraph = CardWithCycleTravel(props => (<>
    <HierarchyGraph data={props.data.descendants || []} />
    <ExplanationContainer usuarios={props.data.descendants || []} />
</>));
const TimeTravelFullDescendantsHierarchyGraph = CardWithCycleTravel(props => (<>
    <HierarchyGraph data={props.data.full_descendants_tree || []} />
    <ExplanationContainer usuarios={props.data.full_descendants_tree || []} />
</>));


const useStyles = makeStyles((theme: Theme) => ({
    root: {
        margin: "auto",
        width: `calc(100% - ${(theme.spacing(2) * 2)}px)`,
        textAlign: "center",
        overflow: "hidden"
    }
}));

export default function FeedPage(props: {
    username: string
    onMounted(path: string): void
}) {
    const [isLoading, error, data] = useData();
    const classes = useStyles();
    const match = useRouteMatch();
    React.useEffect(() => {
        match && props.onMounted && props.onMounted(match.path);
    }, []);

    if (isLoading) return (<LoadingPage size={80} />);
    if (error) return (<ErrorPage {...error} />);

    const { user_alerts, available_points } = data;
    const visuals = transformDataToVisuals(data);
    return (<Grid container spacing={2} className={classes.root}>
        <Grid item xs={12}>
            {
                user_alerts && user_alerts.map(a => (<Alert severity={a.severity} style={{ margin: 10 }}>
                    <div dangerouslySetInnerHTML={{ __html: a.content }}>
                    </div>
                </Alert>))
            }
            <Typography color="primary" variant="h6">
                Hola {props.username}. Esta es tu oficina virtual.
            </Typography>
            <Typography variant="subtitle1">
                Estamos aquí para prosperar juntos.
            </Typography>
            {available_points && <AvailablePointsBadge disponible={available_points} />}
        </Grid>

        {visuals.map((visual, index) => (<Grid key={index} item sm={visual.col} xs={12}>
            {visual.component}
        </Grid>))}

    </Grid>);
}

function useData(): [boolean, Error, DashboardResult] {
    const [status, set_status] = React.useState("INITIALIZING");
    const [error, set_error] = React.useState(null);
    const [data, set_data] = React.useState<DashboardResult>({});

    React.useEffect(() => {
        const promise = API.GetFeed();
        promise
            .then(value => set_data(value))
            .catch(ex => set_error(ex))
            .finally(() => set_status(""))
        return () => promise.abort();
    }, []);

    return [status === "INITIALIZING", error, data];
}

function transformDataToVisuals(data: DashboardResult) {

    const { current_week, current_cycle, ...reports } = data;
    const visuals: { component: React.ReactNode, col: 6 | 12 }[] = [];
    if (reports.group_ranking) visuals.push({
        component: (<TimeTravelRank
            statName="group_ranking"
            data={reports.group_ranking}
            current_week={current_week}
            title="RANKING DE GRUPOS"
            colType="MONEY"
            secondColType="MONEY"
            col1="stat"
            col2="ventas_lider"
        />)
        , col: 6
    });

    if (reports.personal_sales_graph) visuals.push({
        component: (<PersonalGraph
            data={reports.personal_sales_graph}
            statName="personal_sales_graph"
            current_week={current_week}
            title="ESTADISTICA SEMANAL"
        />)
        , col: 6
    });
    if (reports.global_sales_graph) visuals.push({
        component: (<PersonalGraph
            data={reports.global_sales_graph}
            statName="global_sales_graph"
            current_week={current_week}
            title="VENTA TOTAL SEMANAL"
        />)
        , col: 6
    });

    if (reports.descendants_sales_graph) visuals.push({
        component: (<PersonalGraph
            data={reports.descendants_sales_graph}
            statName="descendants_sales_graph"
            current_week={current_week}
            title="VENTAS GENEALOGIA"
        />)
        , col: 6
    });
    if (reports.personal_reaches_graph) visuals.push({
        component: (<PersonalGraph
            data={reports.personal_reaches_graph}
            statName="personal_reaches_graph"
            current_week={current_week}
            title="PERSONAS CONTACTADAS"
        />)
        , col: 6
    });
    if (reports.global_reaches_graph) visuals.push({
        component: (<PersonalGraph
            data={reports.global_reaches_graph}
            statName="global_reaches_graph"
            current_week={current_week}
            title="PERSONAS CONTACTADAS"
        />)
        , col: 6
    });

    if (reports.profit_summary) visuals.push({
        component: (
            <ProfitCard {...reports.profit_summary!} />
        ), col: 6
    });

    if (reports.product_resume) visuals.push({
        component: (
            <PieCard data={reports.product_resume} title="PRODUCTOS VENDIDOS" />
        )
        , col: 6
    });

    if (reports.product_resume_list) visuals.push({
        component: (
            <TimeTravelRank
                current_week={current_week}
                statName="product_resume_list"
                data={reports.product_resume_list}
                title="PRODUCTOS VENDIDOS"
                colType="NUMBER"
                col1="stat"
            />
        )
        , col: 6
    });


    if (reports.week_ranking) visuals.push({
        component: (<TimeTravelRank
            statName="week_ranking"
            current_week={current_week}
            data={reports.week_ranking}
            title="RANKING DE LA SEMANA"
            colType="MONEY"
            secondColType="DATE"
            col1="stat"
            col2="ultimoPedido"
        />),
        col: 6
    });

    if (reports.cumulative_ranking) visuals.push({
        component: (
            <RankingCard
                data={reports.cumulative_ranking.length
                    ? reports.cumulative_ranking
                    : reports.week_ranking}
                title="RANKING ACUMULATIVO"
                colType="MONEY"
                col1="monto_acumulativo"
            />),
        col: 6
    });

    if (reports.descendants && reports.descendants_resume) {
        visuals.push({
            component: (
                <TimeTravelHierarchyFeed
                    stats={["descendants", "descendants_resume"]}
                    data={{
                        descendants_resume: reports.descendants_resume,
                        descendants: reports.descendants
                    }}
                    current_cycle={current_cycle}
                    title="Genealogía"
                />),
            col: 12
        });
    } else if (reports.descendants) {
        visuals.push({
            component: (<TimeTravelDescendantsHierarchyGraph
                stats={["descendants"]}
                data={reports}
                current_cycle={current_cycle}
                title="Genealogía"
            />),
            col: 12
        });
    } else if (reports.full_descendants_tree) {
        visuals.push({
            component: (<TimeTravelFullDescendantsHierarchyGraph
                stats={["full_descendants_tree"]}
                data={reports}
                current_cycle={current_cycle}
                title="Genealogía Completa"
            />),
            col: 12
        });
    }

    if (reports.global_people_not_reached) visuals.push({
        component: (<DataTable
            columns={[
                { name: "id", label: "#" }
                , { name: "nombre", label: "Nombre" }
                , { name: "Rol", label: "Rol" }
                , { name: "assignedTo", label: "Asistente" }
                , {
                    name: "ultimaLlamada", label: "Ultimo Contacto",
                    options: {
                        customBodyRender: function (value) {
                            if (!value) return null;
                            return new Date(value).toLocaleString("es-EC", {
                                day: "numeric", month: "short", year: "numeric"
                            });
                        }
                    }
                }
                , { name: "diasRetraso", label: "Dias Retraso" }
            ]}
            data={reports.global_people_not_reached}
            title="Personas sin contactar"
            options={{
                print: false,
                download: false,
                sort: true,
                filter: true,
                search: true,
                selectableRows: "none",
                viewColumns: false,
                pagination: true,
                textLabels: {
                    body: {
                        noMatch: "No se encontraron registros 🤷‍♀️"
                    }
                }
            }}
        />)
        , col: 12
    });

    if (reports.comission_balance) visuals.push({
        component: (
            <ComisionCard data={reports.comission_balance} />
        )
        , col: 6
    });

    return visuals.sort((a, b) => a.col - b.col);
}