import React from "react";
import { MUIDataTableColumn } from "mui-datatables";
import { Button, IconButton, InputAdornment, TextField, Typography } from "@material-ui/core";
import { Close, CloudDownload } from "@material-ui/icons";
import API from "../../Services/API";
import DataTable from "../DataTable";
export default function () {

    const [status, set_status] = React.useState(
        "fetch_cycles" as "fetch_data" | "fetch_cycles" | "validate_data" | "submit_data" | string
    );

    const [cycle, set_cycle] = React.useState(null as Cycle);
    const [cycleList, set_cycleList] = React.useState([] as Cycle[]);
    const [data, set_data] = React.useState([] as MultiLevelUserData[]);

    React.useEffect(() => {
        if (status !== "fetch_data") return;
        if (!(cycle && cycle.index >= 0)) {
            return set_status("Selecciona un ciclo válido");
        }
        API.GetMultiLevelUserData(cycle)
            .then(data => {
                set_data(data);
                set_status("");
            }).catch(ex => set_status(ex.message));
    }, [status]);

    React.useEffect(() => {
        if (status !== "fetch_cycles") return;
        API.GetCycles().then(cycles => {
            set_cycleList(cycles);
            set_status("");
        }).catch(ex => set_status(ex.message));
    }, [status]);

    React.useEffect(() => {
        if (status !== "validate_data") return;
        API.processMultiLevelUserData(cycle.index)
            .then(results => {
                set_status("");
                alert("Guardado con éxito");
            }).catch(ex => {
                set_status(ex.message);
            });
    }, [status]);


    const isLoading = ["fetch_data", "fetch_cycles", "validate_data", "submit_data"].indexOf(status) >= 0;
    const errorMsg = (isLoading || !status.length) ? "" : status;

    const buttonsAreDisabled = isLoading || !(cycle && cycle.index >= 0);

    return (<DataTable
        title={isLoading ? "Cargando datos..." : (<Typography variant="body1" color="error" component="pre" >{errorMsg}</Typography>)}
        columns={COLUMNS}
        data={data}
        options={{
            selectableRows: "none",
            textLabels: {
                body: {
                    noMatch: "No se encontraron registros"
                }
            },
            customToolbar() {
                return (<>
                    <TextField
                        style={{ margin: 5, minWidth: 200 }}
                        select
                        disabled={isLoading || data.length > 0}
                        value={cycle ? cycle.index : ""}
                        onChange={ev => {
                            set_cycle(cycleList.find(x => x.index.toString() == ev.target.value));
                        }}
                        SelectProps={{
                            native: true
                            , IconComponent: () => data.length
                                ? (<IconButton title="Limpiar todo" disabled={buttonsAreDisabled} size="small" type="button" onClick={() => {
                                    set_data([]);
                                    set_cycle(null);
                                }}>
                                    <Close />
                                </IconButton>)
                                : (<IconButton title="Visualizar ciclo" disabled={buttonsAreDisabled} size="small" type="button" onClick={() => set_status("fetch_data")} >
                                    <CloudDownload />
                                </IconButton>)
                        }}
                    >
                        <option>- Selecciona un ciclo -</option>
                        {cycleList.map(c => <option key={c.number + "-" + c.year} value={c.index}>{c.name || `Ciclo ${c.number} - ${c.year}`}</option>)}
                    </TextField>
                    <div style={{ minWidth: 100, width: 100, minHeight: 20 }} ></div>
                    <Button
                        disabled={buttonsAreDisabled || !data.length}
                        color="primary"
                        variant="contained"
                        type="button"
                        onClick={() => set_status("validate_data")}
                    >
                        GUARDAR Y CERRAR CICLO 🔒
                    </Button>
                </>)
            }
        }}
    />)
}


const COLUMNS = [
    { name: "id", label: "ID" },
    { name: "nombre", label: "Nombre" },
    { name: "parent1", label: "ID Patrocinador" },
    { name: "total_comisionable", label: "Venta Individual Comisionable" },
    { name: "total_calificable", label: "Venta Individual" },
    { name: "nX_total_calificable", label: "Venta Grupal" },

    { name: "n1_comisionable", label: "Comisionable N1" },
    { name: "n1_activos", label: "Activos N1" },

    { name: "n2_comisionable", label: "Comisionable N2" },
    { name: "n2_activos", label: "Activos N2" },

    { name: "n3_comisionable", label: "Comisionable N3" },
    { name: "n3_activos", label: "Activos N3" },

    { name: "titulo", label: "Titulo" },
    { name: "subtotal_comision", label: "Comision a Pagar" },
    { name: "total_comision", label: "Total a Pagar" }
] as MUIDataTableColumn[];