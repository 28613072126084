import React from "react";
import DataTable from "../DataTable";
import { useRouteMatch, Route, Link, useHistory } from "react-router-dom";
import { Tabs, Tab, Tooltip, IconButton, Typography } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import EditCustomer from "./EditCustomer";
import API from "../../Services/API";

export default function Customers(props: {
    onMounted?(selected?: string): void
    userId: string | number
}) {

    const root_match = useRouteMatch();
    const history = useHistory();
    React.useEffect(() => {
        props.onMounted && props.onMounted(root_match.path);
    }, []);

    const [group, set_group] = React.useState<"mine" | "others">("mine");

    const { data: selected_records, isLoading, error, reloadData } = useData(group, props.userId);

    const columns = [
        { name: "id", label: "Cliente #" }
        , {
            name: "nombre", label: "Nombre"
        }
        , {
            name: "ultimaLlamada", label: "Ultima Llamada", options: {
                customBodyRender(value) {
                    if (value)
                        return new Date(value).toLocaleDateString("es-EC", {
                            day: "numeric", month: "short", year: "numeric"
                        });
                    return "";
                }
            }
        }
        , {
            name: "fechaSiguienteLlamada", label: "Sgte Llamada"
            , options: {
                customBodyRender(value) {
                    if (value)
                        return new Date(value).toLocaleDateString("es-EC", {
                            day: "numeric", month: "short", year: "numeric"
                        });
                    return "";
                }
            }
        }
    ];
    if (group === "others") {
        columns.push({
            name: "CreadoPor", label: "Asesora"
        });
    }

    if (error) {
        var content = (<div>
            <Typography color="error">{error?.message}</Typography>
        </div>);
    } else {
        content = (<DataTable
            title=""
            columns={columns}
            data={selected_records}
            options={{
                print: false,
                download: false,
                sort: true,
                filter: true,
                search: true,
                selectableRows: "none",
                viewColumns: false,
                pagination: true,
                textLabels: {
                    body: {
                        noMatch: isLoading ? "Buscando Registros 🔎 .... " : "No se encontraron registros 🤷‍♀️"
                    }
                },
                onRowClick(array_data) {
                    const id = array_data[0];
                    if (id === null || id === undefined) return;
                    history.push(root_match.path + "/" + id);
                }
                , customToolbar() {
                    return (<Tooltip title="Nuevo Cliente">
                        <IconButton color="primary" component={Link} to={root_match.path + "/new"}>
                            <Add />
                        </IconButton>
                    </Tooltip>);
                }
            }}
        />);
    }

    return (<div>
        <Tabs value={group} onChange={(_, value) => set_group(value)}>
            <Tab value="mine" label="Mis clientes" />
            <Tab value="others" label="Clientes de la tribu" />
        </Tabs>
        {content}
        <Route path={root_match.path + "/:id"} children={({ match }) => <EditCustomer
            open={match?.params?.id ? true : false}
            id={(match?.params?.id || "new") === "new" ? null : match?.params?.id}
            onClose={() => {
                history.push(root_match.path);
                reloadData();
            }}
        />} />
    </div>
    )
}


function useData(groupFilter: "mine" | "others", userId: number | string) {
    const [records, set_records] = React.useState([]);
    const [error, set_error] = React.useState<Error | null>(null);
    const [status, set_status] = React.useState("LOADING");

    React.useEffect(() => {
        if (status !== "LOADING") return;
        var promise = API.GetCRMCustomers(["id", "nombre", "ultimaLlamada", "siguienteLlamada", "createdBy", "CreadoPor"]);
        promise.then(items => {
            set_records(items);
            set_status("");
        }).catch(ex => {
            set_error(ex);
            set_status("ERROR");
        });
        return () => promise.abort();
    }, [status]);

    const data = records.filter(x => {
        var personCreatorId = x.createdBy || userId;
        if (groupFilter === "mine")
            return personCreatorId === userId;
        if (groupFilter === "others")
            return personCreatorId !== userId;
        return false;
    });

    return {
        data
        , error
        , isLoading: ["LOADING"].includes(status)
        , reloadData() {
            set_status("LOADING");
        }
    };
}