import React from "react";
import { Typography, Divider } from "@material-ui/core";

export function SectionHeader(props: any) {
    const style = { marginTop: 12, marginBottom: 5 } as any;
    if (props.center) style.textAlign = "center";
    return (
        <div style={style}>
            <Typography variant="h6">{props.children}</Typography>
            <Divider />
        </div>);
}


export function TextRow(props: { value: any, label: any }) {
    const { value, label } = props;
    return <div style={{ width: "100%", textAlign: "left" }}>
        <div style={{ width: 120, display: "inline-block" }}>
            <Typography variant="body1" >{label}</Typography>
        </div>
        <Typography variant="body2" style={{ width: "auto", display: "inline" }}>{value}</Typography>
    </div>
}