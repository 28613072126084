import React from "react";
import { List, ListItem, Grid, Paper, Typography, Hidden, CircularProgress } from "@material-ui/core";
import API from "../../Services/API";
import { Route } from "react-router-dom";
import OrderDetails from "./OrderDetails";
import Message from "../Message";
export default class extends React.PureComponent<any> {
    state = {
        data: [] as GetOrder[],
        msg: null,
        loading: true
    }
    constructor(props) {
        super(props);
        props.onMounted(props.match.path);
        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleMessage = this.handleMessage.bind(this);
    }
    componentWillMount() {
        API.GetOrders()
            .then(data => this.setState({ data, loading: null }))
            .catch(err => this.setState({
                loading: null,
                msg: { title: "Ocurrió un error", message: err.message, info: err.code }
            }));
    }
    handleMessage() {
        this.setState({ msg: null });
    }
    handleClick(event: React.MouseEvent<HTMLElement>) {
        const { id } = event.currentTarget;
        this.props.history.push(this.props.match.path + "/" + id);
    }
    handleClose(refresh: boolean) {
        if (refresh) {
            this.setState({ loading: true });
            API.GetOrders()
                .then(data => this.setState({ data, loading: null }))
                .catch(ex => this.setState({
                    msg: { title: "No se pudo actualizar el listado", message: ex.message, info: ex.code },
                    loading: null
                }));
        }
        this.props.history.push(this.props.match.path);
    }
    render() {
        const { data, msg, loading } = this.state;

        if (loading) return (<div style={{ width: "100%", textAlign: "center", paddingTop: 100 }}>
            <CircularProgress size={100} />
            <Typography variant="h6">CARGANDO...</Typography>
        </div>);

        const summary = data.reduce((a, b) => {
            a.count++;
            a.descuentoTotal += b.descuentoTotal;
            a.total += b.total;
            a.puntosAcumulados += (b.puntosAcumulados || 0);
            return a;
        }, {
            count: 0, total: 0, descuentoTotal: 0, puntosAcumulados: 0
        });

        return (<Paper style={{ width: "calc(100%-20px)", height: "calc(100% - 30px)", display: "flex", flexDirection: "column", margin: 10, padding: 10, paddingBottom: 0, overflowX: "hidden" }}>
            <List>
                <Hidden xsDown implementation="css" >
                    <ListItem>
                        <Grid container spacing={1} style={{ borderBottom: "1px solid #ebebeb" }}>
                            <Grid sm={2} item><Typography variant="overline">Numero</Typography></Grid>
                            <Grid sm={2} item><Typography variant="overline">Creado</Typography></Grid>

                            <Grid sm={1} item style={{ textAlign: "right" }}><Typography variant="overline">Total</Typography></Grid>

                            <Grid sm={1} item style={{ textAlign: "right" }}><Typography variant="overline">Ganancia</Typography></Grid>

                            <Grid sm={1} item style={{ textAlign: "right" }}><Typography variant="overline">Puntos</Typography></Grid>

                            <Grid sm={2} item style={{ textAlign: "center" }}><Typography variant="overline">Estado</Typography></Grid>

                            <Grid sm={2} item><Typography variant="overline">Fecha de Envío</Typography></Grid>
                        </Grid>
                    </ListItem>
                </Hidden>
                {data.map(d => <ListItem key={d.id} id={"" + d.id} button onClick={this.handleClick}>
                    <Grid container spacing={1} style={{ paddingTop: 5, paddingBottom: 5, borderBottom: "1px solid #e4e4e5" }}>

                        <Grid xs={12} sm={2} item >
                            <Hidden smUp >PEDIDO #</Hidden>
                            {d.id}
                        </Grid>
                        <Grid xs={12} sm={2} item >
                            {new Date(d.fechaCreacion).toLocaleDateString("es-EC", {
                                day: "numeric", month: "short", year: "numeric", hour: "2-digit", minute: "2-digit"
                            })}
                        </Grid>

                        <Grid xs={12} sm={1} item style={{ textAlign: "right" }}>{d.total.toCurrency(2)}</Grid>

                        <Grid xs={12} sm={1} item style={{ textAlign: "right" }}>{(d.descuentoTotal || 0).toCurrency(2)}</Grid>

                        <Grid xs={12} sm={1} item style={{ textAlign: "right" }}>{d.puntosAcumulados}</Grid>

                        <Grid xs={12} sm={2} item style={{ textAlign: "center" }}><Typography color={EstadoToVariant(d.estado)}>{d.estado}</Typography></Grid>

                        <Grid xs={12} sm={2} item >{d.fechaEnvio && new Date(d.fechaEnvio).toLocaleString("es-EC", {
                            year: "numeric", month: "short", day: "numeric"
                        })}</Grid>
                    </Grid>
                </ListItem>)}

                <ListItem >
                    <Grid container spacing={1} style={{ borderTop: "1px solid #ebebeb" }}>
                        <Grid sm={4} item><Typography variant="button">Total filas: {summary.count}</Typography></Grid>

                        <Grid sm={1} item style={{ textAlign: "right" }} ><Typography variant="button">{summary.total.toCurrency()}</Typography></Grid>

                        <Grid sm={1} item style={{ textAlign: "right" }}><Typography variant="button">{summary.descuentoTotal.toCurrency()}</Typography></Grid>

                        <Grid sm={1} item style={{ textAlign: "right" }}><Typography variant="button">{parseInt("" + summary.puntosAcumulados)}</Typography></Grid>

                        <Grid sm={2} item><Typography variant="button"></Typography></Grid>

                        <Grid sm={2} item><Typography variant="button"></Typography></Grid>
                    </Grid>
                </ListItem>
            </List>

            {msg && <Message {...msg} onClose={this.handleMessage} />}

            <Route
                path={this.props.match.path + "/:id"}
                render={props => <OrderDetails id={+props.match.params.id} onClose={this.handleClose} />}
            />
        </Paper>);
    }
}

function EstadoToVariant(estado): any {
    if (estado === "APROBADO") return "primary";
    if (estado === "RECHAZADO") return "secondary";
    return "initial";
}