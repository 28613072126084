import React from "react";
import API from "../../Services/API";
import DataTable from "../DataTable";
import { Typography, Tooltip, IconButton } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import NewComissionPayment from "./NewComissionPayment";

export default function () {

    const [status, set_status] = React.useState("loading");
    const [records, set_records] = React.useState([]);
    const [error, set_error] = React.useState(null as any);
    const [editView, set_editView] = React.useState(false);

    React.useEffect(() => {
        if (status !== "loading") return;
        API.GetComissionPayments()
            .then(results => {
                set_records(results);
                set_status("");
            }).catch(ex => {
                set_error(ex);
                set_status("error");
            });
    }, [status]);

    React.useEffect(() => {
        if (status.indexOf("delete") !== 0) return;
        const id = status.split("delete:")[1];
        API.DeletePaymentComission(id as any)
            .then(result => {
                set_status("loading");
            }).catch(ex => {
                set_error(ex);
                set_status("error");
            });
    }, [status]);

    const isLoading = status === "loading" || status.indexOf("delete") === 0;

    return (<>
        <div style={{ textAlign: "center" }}>
            {error && <>
                <Typography variant="h4" color="error">Ocurrió un error</Typography>
                <Typography variant="h6" color="error">{error.message}</Typography>
            </>}
        </div>
        <DataTable
            title=""
            columns={[
                { name: "id", label: "ID" }
                , { name: "Usuario", label: "Nombre" }
                , { name: "ciclo", label: "ciclo" }
                , { name: "monto", label: "Monto" }
                , { name: "idPedido", label: "Pedido #" }
                , {
                    name: "img", label: "Comprobante", options: {
                        customBodyRender: value => (<a href={value} title="Imagen del comprobante" target="_blank">Ver Comprobante</a>)
                    }
                }
            ]}
            data={records}
            options={{
                print: false,
                download: false,
                sort: true,
                filter: true,
                search: true,
                viewColumns: false,
                pagination: true,
                selectableRows: "single",
                onRowsDelete(rowsDeleted: { data: { index: number }[] }) {
                    var confirmed = window.confirm("Desea eliminar el registro?");
                    if (!confirmed) return false;
                    return set_status("delete:" + records[rowsDeleted.data[0].index].id);
                },
                textLabels: {
                    body: {
                        noMatch: isLoading ? "Buscando Registros 🔎 .... " : "No se encontraron registros 🤷‍♀️"
                    }
                },
                customToolbar() {
                    return (<Tooltip title="Agregar Pago" >
                        <IconButton color="primary" onClick={() => set_editView(true)} >
                            <Add />
                        </IconButton>
                    </Tooltip>);
                }
            }}
        />
        {
            editView ?
                <NewComissionPayment onFinished={insertId => {
                    if (insertId) set_status("loading");
                    set_editView(false);
                }} />
                : null
        }
    </>);
}