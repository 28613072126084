import React from "react";
import { TextField, TextFieldProps } from "@material-ui/core";
import { getValorEnvio } from "./mapOrderState";

export default function (props: TextFieldProps & {
    envio_siguiente_pedido?: boolean,
    value?: (TIPO_ENVIO & "ENVIO CON EL SIGUIENTE PEDIDO") | string | null,
    /**
     * Especifica el valor del pedido para que se actualicen los precios del envio conforme
     * al total del pedido
     */
    valor_pedido?: number
}) {

    const { envio_siguiente_pedido, ...textFieldProps } = props;

    const precioItem = function (tipo_envio: TIPO_ENVIO) {
        var value = getValorEnvio(props.valor_pedido || 0, tipo_envio);
        if (value) return value.toLocaleString("es-EC", {
            style: "currency",
            currency: "USD"
        });
        return "GRATIS";
    }

    return (
        <TextField
            {...textFieldProps}
            name={props.name || "tipo_envio"}
            value={props.value}
            inputProps={{ id: "tipo_envio", ...(props.inputProps || {}) }}
            variant="outlined"
            title={props.title}
            select
            SelectProps={{ native: true, ...(props.SelectProps || {}) }}
            onChange={props.onChange}
            label="Tipo de Envío *"
            style={{ margin: 10, width: "calc(100% - 20px)", ...(props.style || {}) }}
        >
            <option value="PICK-UP">RETIRO EN OFICINA QUITO | GRATIS</option>
            {envio_siguiente_pedido
                ? <option value="ENVIO CON EL SIGUIENTE PEDIDO">GRATIS | ENVÍO CON EL SIGUIENTE PEDIDO</option>
                : null}
            <option value="DENTRO DE CIUDAD">ENVÍO A QUITO | {precioItem("DENTRO DE CIUDAD")}</option>
            <option value="ZONA CONTINENTAL">ENVÍO AL RESTO DEL PAÍS | {precioItem("ZONA CONTINENTAL")}</option>
            <option value="GALAPAGOS ISLA CRUZ - BALTRA">GALÁPAGOS ISLA CRUZ - BALTRA Y SAN CRISTÓBAL | {precioItem("GALAPAGOS ISLA CRUZ - BALTRA")}</option>
            <option value="GALAPAGOS ISLA ISABELA Y FERNANDINA">GALÁPAGOS ISLA ISABELA Y FERNANDINA | {precioItem("GALAPAGOS ISLA ISABELA Y FERNANDINA")}</option>
            {props.children}
        </TextField >);
}