import React from "react";
import { Grid, Paper, Typography, List, ListItem, ListItemText, ListItemIcon } from "@material-ui/core";
import { RootState } from "../../Redux/Store";
import { PremioReduceState } from "./Redux/UserPointsReducer";
import { connect } from "react-redux";

function MisPuntosComponent(props: PremioReduceState["resumen_puntos"]) {
    return (<div style={{ padding: 20 }}>
        <Grid container spacing={5}>
            <Grid item sm={6} xs={12}>
                <Paper elevation={10} style={{ textAlign: "center", margin: "auto" }} >
                    <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                        <Typography variant="h3">{props.disponible}</Typography>
                        <Typography variant="h6">Puntos Disponibles</Typography>
                    </div>
                </Paper>
            </Grid>
            <Grid item sm={6} xs={12}>
                <Paper elevation={10} style={{ textAlign: "center", margin: "auto" }} >
                    <List>
                        <ListItem>
                            <ListItemText>Puntos Acumulados (+):</ListItemText>
                            <ListItemIcon>{props.acumulados}</ListItemIcon>
                        </ListItem>
                        <ListItem>
                            <ListItemText>Puntos Canjeados (-):</ListItemText>
                            <ListItemIcon>{props.canjeados}</ListItemIcon>
                        </ListItem>
                        <ListItem>
                            <ListItemText>Puntos Perdidos (-):</ListItemText>
                            <ListItemIcon>{props.perdidos}</ListItemIcon>
                        </ListItem>
                        <ListItem>
                            <ListItemText>Puntos Disponibles:</ListItemText>
                            <ListItemIcon>{props.disponible}</ListItemIcon>
                        </ListItem>
                    </List>
                </Paper>
            </Grid>
        </Grid>
        <div>

        </div>
    </div>)
}

export default connect(({ UserPoints }: RootState) => UserPoints.resumen_puntos)(MisPuntosComponent);