import React from "react";
import MUIDataTable, { MUIDataTableProps } from "mui-datatables";
import { TextField } from "@material-ui/core";
export default function (props: MUIDataTableProps) {

    const { options, ...remainProps } = props;

    const { download, downloadOptions, customToolbar, ...remainOptions } = (options || {});

    const [separator, set_separator] = React.useState(",");

    return <MUIDataTable
        options={{
            ...(remainOptions || {})
            , customToolbar(...args) {
                return (<>
                    <TextField
                        value={separator}
                        onChange={ev => set_separator(ev.target.value)}
                        select
                        SelectProps={{
                            native: true
                        }}
                        helperText="descarga de csv"
                        label="Separador"
                    >
                        <option value="," >, (MAC)</option>
                        <option value=";">; (Windows)</option>
                    </TextField>
                    <div style={{ height: "100%", right: "1px solid #eaeaea", marginLeft: 5, marginRight: 10 }} ></div>
                    {customToolbar ? customToolbar(...args) : null}
                </>);
            }
            , onDownload(buildHead: (columns) => string, buildBody: (data) => string, columns: any[], data: any[]): string {
                var forExcel = window.confirm("Hacer archivo compatible con Microsoft Excel (para lectura de caracteres especiales) ?");
                if (forExcel) return "\uFEFF" + buildHead(columns) + buildBody(data);
                return buildHead(columns) + buildBody(data);
            }
            , downloadOptions: {
                separator,
                ...(downloadOptions || {})
            }
        }}
        {...(remainProps || {} as any)}
    />
}