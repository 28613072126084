import React from "react";
import { List, ListItem, Typography } from "@material-ui/core";

interface Props {
    items: {
        id?: number;
        nombre: string,
        sku?: string;
        cantidad: number;
        pvp_final: number;
        idReglaRegalo?: number;
        componentes?: {
            id?: number, nombre: string, sku?:string, cantidad: number
        }[]
    }[];
    subtotal: number;
    subtotal_con_descuento?: number;
    total_pedido: number;
    valor_envio: number;
    gran_total: number;
    show_next_discount?: boolean;
    showComponentes?: boolean;
    descuento?: DescuentoCalc;
    LabelDescuentoComponent?: React.ReactElement;
}

export default function ConfirmOrder(props: Props) {
    var { items
        , show_next_discount
        , descuento
        , valor_envio
        , gran_total
        , total_pedido
        , subtotal
    } = props;

    var subtotal_con_descuentos = props.subtotal_con_descuento || 0;

    if (!descuento) descuento = { porcentaje: 0, valor: 0 };
    const mostrarDescuentos = show_next_discount && descuento.sgte_descuento_porcentaje;

    return (<List>
        {items.map((item, index) => <Row
            key={item.id + "-" + index}
            col1={item.cantidad}
            col2={(props.showComponentes && item.sku ? (item.sku + " - ") : "") + (item.idReglaRegalo ? "🎁 " : "") + item.nombre}
            col3={item.idReglaRegalo ? undefined : (item.pvp_final * item.cantidad).toCurrency()}
            componentes={props.showComponentes ? item.componentes : null}
        />)}

        {items.length
            ? null
            : (<ListItem>
                <div style={{ width: "100%", textAlign: "center" }}>
                    No hay productos en la lista. 🤷
                </div>
            </ListItem>)
        }

        <Row col1="SUBTOTAL (NO APLICA DESCUENTOS)" col3={(subtotal - subtotal_con_descuentos).toCurrency()} />
        <Row col1="SUBTOTAL (APLICA DESCUENTOS)" col3={(subtotal_con_descuentos).toCurrency()} />

        <Row
            col1={props.LabelDescuentoComponent || `TU GANANCIA (${descuento.porcentaje}%) EN ESTE PEDIDO`}
            col3={descuento.valor.toCurrency()}
            color="primary"
        />


        {mostrarDescuentos
            && <ListItem>
                <Typography variant="subtitle1" color="secondary">{`Obtén el ${descuento.sgte_descuento_porcentaje}% de descuento agregando ${descuento.sgte_descuento_falta.toCurrency()} más a tu pedido`}</Typography>
            </ListItem>}
        <Row col1="TOTAL DEL PEDIDO" col3={total_pedido.toCurrency()} />

        <Row col1="ENVÍO" col3={valor_envio.toCurrency()} />
        <Row col1="VALOR A PAGAR" variant="subtitle2" col3={gran_total.toCurrency()} />
    </List>);
}

type Componente = Props["items"][number]["componentes"][number]
function Row(props: {
    col1: any,
    col2?: string | number,
    col3: string,
    color?: any,
    variant?: any,
    componentes?: Array<Componente & {componentes?: Componente[]}>
}) {
    const { col1, col2, col3, color, variant, componentes } = props;
    return (<ListItem>
        <div style={{ width: "100%" }}>
            <div>
                <div style={{ width: "100%", display: "table" }}>
                    <Typography component="div" color={color || "initial"} style={{ display: "table-cell", width: (col2 ? "15%" : "75%"), maxWidth: 100, textAlign: (col2 ? "left" : "right") }} variant={variant || "body1"}>{col1}</Typography>
                    {col2 && <Typography color={color || "initial"} style={{ display: "table-cell", width: "60%" }} variant={variant || "body1"}>{col2}</Typography>}
                    <Typography color={color || "initial"} style={{ display: "table-cell", width: "25%", maxWidth: 100, textAlign: "right" }} variant={variant || "body1"}>{col3}</Typography>
                </div>
            </div>

            {(componentes && componentes.length)
                ? (<div>
                    {componentes.map(c => <Row
                        key={c.id}
                        col1={c.cantidad}
                        col2={(c.sku ? c.sku + " - " : "") + c.nombre}
                        col3=""
                        variant="body2"
                        componentes={c.componentes}
                    />)}
                </div>)
                : null}
        </div>
    </ListItem>)
}