import React from "react";
import { Typography, TextField } from "@material-ui/core";
import { SectionHeader } from "./DetailComponents";
import ActionButtons from "./OrderActionButtons";
import ImageInput from "../ImageInput";
export default function (this: undefined, props: {
    _fechaEnvio?: string,
    onTextChange(event: React.ChangeEvent<HTMLInputElement>): void,
    loadingStatus?: string,
    src?: string,
    onImageChange(file: File): void;
    onSubmit(event: React.MouseEvent<HTMLButtonElement>): void
}) {
    const { _fechaEnvio, src, onSubmit, onImageChange, onTextChange, loadingStatus } = props;
    return (<>
        <SectionHeader>ENVIAR (imagen opcional)</SectionHeader>
        <ImageInput
            onChange={onImageChange}
            src={src}
            className=""
            title="Guía de Envío"
        />
        <div style={{ display: "flex", flexDirection: "row", marginTop: 15, marginBottom: 10 }}>
            <Typography variant="button" style={{ marginRight: 10, paddingTop: 5 }} >Fecha de Envío</Typography>
            <TextField
                type="date"
                fullWidth
                inputProps={{ id: "_fechaEnvio" }}
                name="_fechaEnvio"
                value={_fechaEnvio || ""}
                style={{ flex: 1 }}
                onChange={onTextChange}
            />
        </div>
        <ActionButtons
            loadingAction={loadingStatus}
            label="ENVIAR Y FACTURAR"
            name="ENVIAR"
            showReject={true}
            onClick={onSubmit}
        />
    </>)
}