import React, { ComponentProps } from "react";
import { connect } from "react-redux";
import ProductGrid from "../Products/ProductGrid";
import { RootState } from "../../Redux/Store";
import { UserPointsActions } from "./Redux/UserPointsReducer";
import BottomAppBar from "./BottomAppBar";
import ProductCard from "../Products/ProductCard";

function CanjearPuntosProductList(props: {
    state: "LOADING" | "ERROR" | "SUBMIT" | ""
    , productList: Product[]
    , disponible: number
    , continuarSiguienteSeccion: boolean
    , onActionClick: typeof UserPointsActions["productAction"]
}) {
    return (<div>
        <div style={{ paddingBottom: 70 }}>
            <ProductGrid
                controls="ADD-REMOVE"
                onActionClick={event => {
                    const { value, name } = event.currentTarget;
                    props.onActionClick({
                        Id: +value
                        , type: name as "+" | "-"
                    });
                }}
                items={props.productList}
            />
        </div>
        <BottomAppBar disponible={props.disponible} continuarSiguienteSeccion={props.continuarSiguienteSeccion} />
    </div>);
}

export default connect(({ UserPoints }: RootState) => {
    const disponible = (UserPoints.resumen_puntos.disponible -
        UserPoints.productList.reduce((a, b) => a + ((b.cantidad || 0) * b.puntosPrecio), 0)
    ).moneyRound(0);
    const productList = UserPoints.productList.map(x => ({
        ...x,
        noPuedeAumentar: x.puntosPrecio > disponible
    }) as ComponentProps<typeof ProductCard>);

    var continuarSiguienteSeccion = (function () {
        let { cantidad, se_puede_escoger_productos } = productList.reduce((a, b) => {
            if (!b.noPuedeAumentar) a.se_puede_escoger_productos = true;
            if (b.cantidad) a.cantidad += b.cantidad;
            return a;
        }, { cantidad: 0, se_puede_escoger_productos: false });

        return cantidad && !se_puede_escoger_productos;
    })();
    return {
        status: UserPoints.status
        , disponible
        , productList
        , continuarSiguienteSeccion
    }
}, {
    onActionClick: UserPointsActions.productAction
})(CanjearPuntosProductList);