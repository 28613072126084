import React from "react";
import ImageReader, { ImgFileToObject } from "../../Utils/ImageReader";
export default function () {

    const [file, set_file] = React.useState(null as File);
    const [imgFile, set_imgFile] = React.useState(null as JsonFile);
    const [imgSrc, set_imgSrc] = React.useState(null as string);
    const [status, set_status] = React.useState<"loading" | "error" | "">("");

    React.useEffect(() => {
        if (status === "loading") return;
        set_imgFile(null);
        set_imgSrc(null);
        if (!file) return;
        set_status("loading");
        Promise.all([
            ImgFileToObject(file)
            , ImageReader(file
            )])
            .then(([imgUrl, imgSrc]) => {
                set_imgFile(imgUrl);
                set_imgSrc(imgSrc);
                set_status("");
            }).catch(ex => {
                console.info(ex);
                set_status("error");
            });
    }, [file]);

    return [imgFile, imgSrc, set_file, status] as [JsonFile, string | null, (input: File | null) => void, "loading" | "error" | ""];
}