export default function (file: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
        if (file.size > (15 * 1024 * 1024)) {
            var mb = (file.size / 1024 / 1024).toFixed(2);
            return reject({ message: `Tu imagen (${mb} Mb) es muy pesada, selecciona otra máximo de 15 Mb` });
        }
        setTimeout(x => {
            var reader = new FileReader();
            reader.onload = function (event) {
                resolve(event.target.result as string);
            }
            reader.onerror = reject;
            reader.readAsDataURL(file);
        }, 100);
    });
}
export function ImgFileToObject(file: Blob): Promise<{ ext: string, base64: string }> {
    return new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.onload = function (event) {
            resolve({
                base64: base64ArrayBuffer(event.target.result as any),
                ext: file.type.split("/")[1]
            });
        }
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
    });
}

function base64ArrayBuffer(arrayBuffer: ArrayBuffer): string {
    var base64 = ''
    var encodings = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/'

    var bytes = new Uint8Array(arrayBuffer);
    var byteLength = bytes.byteLength;
    var byteRemainder = byteLength % 3;
    var mainLength = byteLength - byteRemainder;

    var a, b, c, d;
    var chunk;

    // Main loop deals with bytes in chunks of 3
    for (var i = 0; i < mainLength; i = i + 3) {
        // Combine the three bytes into a single integer
        chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2]

        // Use bitmasks to extract 6-bit segments from the triplet
        a = (chunk & 16515072) >> 18 // 16515072 = (2^6 - 1) << 18
        b = (chunk & 258048) >> 12 // 258048   = (2^6 - 1) << 12
        c = (chunk & 4032) >> 6 // 4032     = (2^6 - 1) << 6
        d = chunk & 63               // 63       = 2^6 - 1

        // Convert the raw binary segments to the appropriate ASCII encoding
        base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d]
    }

    // Deal with the remaining bytes and padding
    if (byteRemainder === 1) {
        chunk = bytes[mainLength]

        a = (chunk & 252) >> 2 // 252 = (2^6 - 1) << 2

        // Set the 4 least significant bits to zero
        b = (chunk & 3) << 4 // 3   = 2^2 - 1

        base64 += encodings[a] + encodings[b] + '=='
    } else if (byteRemainder === 2) {
        chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1]

        a = (chunk & 64512) >> 10 // 64512 = (2^6 - 1) << 10
        b = (chunk & 1008) >> 4 // 1008  = (2^6 - 1) << 4

        // Set the 2 least significant bits to zero
        c = (chunk & 15) << 2 // 15    = 2^4 - 1

        base64 += encodings[a] + encodings[b] + encodings[c] + '='
    }
    return base64
}