import React from "react";
import { match } from "react-router-dom";
import TabContainer from "../../Layouts/TabLayout";
import ComissionPayments from "./ComissionPayments";
import ProcessCycle from "./ProcessCycle";

const TABS = [
    { tabId: "cycles", tabLabel: "Ciclos", Component: ProcessCycle }
    , { tabId: "payments", tabLabel: "Pagos", Component: ComissionPayments }
]
export default function (props: {
    match: match,
    onMounted(path: string): void;
}) {
    return <TabContainer
        tabs={TABS}
        match={props.match}
        onMounted={props.onMounted}
    />
}