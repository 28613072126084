import { createSlice, PayloadAction } from "@reduxjs/toolkit";

class UserData implements Omit<LoggedIn, "id" | "estado"> {
    username = "";
    userId = undefined as number;
    ParentName = "";
    token = "";
    rol_simbolo = "";
    Rol = "";
    accesos = {} as { nombre: string, displayName: string }[];
    descuentos = [] as Descuento[];
    mustAcceptTermsAndConds = false;
    solicitarDatosAlIniciar = false;
}

const UserSlice = createSlice({
    name: "User"
    , reducers: {
        set(_, { payload }: PayloadAction<UserData>) {
            const template = new UserData();
            Object.keys(template).forEach(function (key) {
                if (key in payload) { // or obj1.hasOwnProperty(key)
                    template[key] = payload[key];
                }
            });
            return { ...template };
        }
    }
    , initialState: Object.assign({}, new UserData())
});

export default UserSlice.reducer;
export const UserActions = UserSlice.actions;
