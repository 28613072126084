/// <reference path="../../../shared/MultiLevel.d.ts"/>
import React from 'react';
import * as go from 'gojs';
import { ReactDiagram } from 'gojs-react';
import { Typography } from '@material-ui/core';
import ResumenUsuarios from '../lib/ResumenUsuarios';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { toCamelCase } from '../../../Utils/TextTransforms';
import Input from '../../Input';

const ACTIVE = "#31d45f";
const INACTIVE = "#1F4963";

export default function HierarchyGraph(props: {
    data: MultiLevelUser[]
}) {
    const users = [...props.data]
        .sort((a: any, b: any) => {
            if (a.nombre > b.nombre) return 1;
            if (a.nombre < b.nombre) return -1;
            return 0;
        })
        .map(x => ({
            key: x.id
            , name: (x.esUsuarioNuevo ? "⭐ " : (x.rol_simbolo || ""))
                + toCamelCase(x.nombre)
                + " - " + (x.total_comisionable || 0).toCurrency()
            , parent: x.parent1 || undefined
            , background: (x.total_comisionable > 0 ? ACTIVE : INACTIVE)
        }));

    const diagramRef = React.useRef<ReactDiagram>(undefined);
    const focusNode = React.useCallback((nodeKey: string) => {
        const diagram = diagramRef.current?.getDiagram();
        if (!diagram) return;
        diagram.clearHighlighteds();
        if (!nodeKey) return;
        diagram.nodes.each((node) => {
            if (nodeKey && node.data?.key && nodeKey == node.data?.key) {
                diagram.highlight(node);
                diagram.commandHandler.scrollToPart(node);
            }
        });
    }, [diagramRef]);

    return (<div>
        <div style={{ margin: "auto", maxWidth: 500 }}>
            <SearchInput users={users} onSearch={focusNode} />
        </div>
        <ReactDiagram
            initDiagram={initDiagram}
            ref={diagramRef}
            divClassName="diagram-component"
            nodeDataArray={users}

        />
    </div>);
}

function SearchInput(props: {
    users: { key: string | number, name: string, background: string }[]
    onSearch(key: string): void
}) {
    const [value, set_value] = React.useState(null);
    React.useEffect(() => {
        props.onSearch(value);
    }, [value]);



    if (value !== undefined && value !== null) {
        var selectedItem = props.users.find(x => x.key === value);
    }
    if (selectedItem === undefined) selectedItem = null;

    return (
        <Autocomplete
            options={props.users}
            noOptionsText="No se encontraron registros"
            renderInput={params => <Input variant="outlined" placeholder="Busca a una persona" {...params} />}
            getOptionLabel={item => item ? `${item.key} - ${item.name} ${item.background === ACTIVE ? "(activo)" : ""}` : ""}
            value={selectedItem}
            onChange={(_, item: typeof props.users[number]) => set_value(item?.key as string || "")}
            getOptionSelected={(item, selectedItem) => item.key === selectedItem.key}
        />);
}

export function ExplanationContainer(props: {
    usuarios: MultiLevelUser[]
}) {

    const resumen = ResumenUsuarios(props.usuarios);

    return (<div style={{ padding: 10 }}>
        <div>
            <div style={{ marginRight: 5, background: ACTIVE, minWidth: 13, minHeight: 13, display: "inline-block" }} />
            <Typography style={{ display: "inline-block" }}>Usuarios activos - {resumen.activos.toLocaleString(undefined, { maximumFractionDigits: 0 })}</Typography>
        </div>
        <div>
            <div style={{ marginRight: 5, background: INACTIVE, minWidth: 13, minHeight: 13, display: "inline-block" }} />
            <Typography style={{ display: "inline-block" }}>Usuarios inactivos - {(resumen.total - resumen.activos).toLocaleString(undefined, { maximumFractionDigits: 0 })}  | Total de Usuarios : {resumen.total} </Typography>
        </div>
        <div>
            <div style={{ marginRight: 5, minWidth: 13, minHeight: 13, display: "inline-block" }} >⭐</div>
            <Typography style={{ display: "inline-block" }}>Usuarios nuevos - {resumen.nuevos.toLocaleString(undefined, { maximumFractionDigits: 0 })}</Typography>
        </div>
    </div>);
}


function initDiagram() {
    // Since 2.2 you can also author concise templates with method chaining instead of GraphObject.make
    // For details, see https://gojs.net/latest/intro/buildingObjects.html
    const $ = go.GraphObject.make;  // for conciseness in defining templates

    const myDiagram =
        $(go.Diagram,
            {
                initialAutoScale: go.Diagram.UniformToFill,
                // define the layout for the diagram
                layout: $(go.TreeLayout, { nodeSpacing: 5, layerSpacing: 30 }),
                model: new go.TreeModel({
                    isReadOnly: true
                }),
                allowSelect: false
            });

    // Define a simple node template consisting of text followed by an expand/collapse button
    myDiagram.nodeTemplate =
        $(go.Node, "Horizontal",
            //{ selectionChanged: nodeSelectionChanged },  // this event handler is defined below
            $(go.Panel, "Auto",
                { alignment: go.Spot.Center },
                $(go.Shape, { stroke: "white", strokeWidth: 2 }
                    , new go.Binding("fill", "background")
                    , new go.Binding("stroke", "isHighlighted", value => value ? "orange" : "white").ofObject("")
                ),
                $(go.TextBlock,
                    {
                        font: "bold 13px Helvetica, bold Arial, sans-serif",
                        stroke: "white", margin: new go.Margin(5, 10, 5, 10),
                        textAlign: "center"
                    },
                    new go.Binding("text", "name"))
            ),
            $("TreeExpanderButton")
        );

    // Define a trivial link template with no arrowhead.
    myDiagram.linkTemplate = $(go.Link,
        { selectable: false },
        $(go.Shape));  // the link shape


    return myDiagram;
}
