export const entrada_columns = [
    {
        name: "id",
        label: "#",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "fechaCreacion",
        label: "Fecha",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "username",
        label: "Usuario",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "razonSocial",
        label: "Cliente",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "total",
        label: "Total",
        options: {
            filter: false,
            sort: true
        }
    },
    {
        name: "numeroTransaccion",
        label: "# Comprobante",
        options: {
            filter: false,
            sort: true
        }
    }
];

export const aprobado_columns = [
    {
        name: "id",
        label: "#",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "fechaCreacion",
        label: "Fecha",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "username",
        label: "Usuario",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "razonSocial",
        label: "Cliente",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "total",
        label: "Total",
        options: {
            filter: true
        }
    },
    {
        name: "puntosAcumulados",
        label: "Puntos",
        options: {
            filter: true
        }
    }
];
export const enviado_columns = [
    {
        name: "id",
        label: "#",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "fechaCreacion",
        label: "Fecha",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "fechaEnvio",
        label: "Enviado",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "username",
        label: "Usuario",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "razonSocial",
        label: "Cliente",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "comisionableTotal",
        label: "Comisionable",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "total",
        label: "Total",
        options: {
            filter: true
        }
    },
    {
        name: "puntosAcumulados",
        label: "Puntos",
        options: {
            filter: true
        }
    }
];
export const recibido_columns = [
    {
        name: "id",
        label: "#",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "fechaCreacion",
        label: "Fecha",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "fechaRecepcion",
        label: "Recepcion",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "username",
        label: "Usuario",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "razonSocial",
        label: "Cliente",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "total",
        label: "Total",
        options: {
            filter: false,
            sort: true
        }
    },
    {
        name: "puntosAcumulados",
        label: "Puntos",
        options: {
            filter: true
        }
    },
    {
        name: "bienRecibido",
        label: "Incompleto",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "observacionRecepcion",
        label: "Observaciones",
        options: {
            sort: false,
            filter: false
        }
    }
];
export const facturado_columns = [
    {
        name: "id",
        label: "#",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "fechaCreacion",
        label: "Fecha",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "fechaRecepcion",
        label: "Recepcion",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "username",
        label: "Usuario",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "razonSocial",
        label: "Cliente",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "comisionableTotal",
        label: "Comisionable",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "total",
        label: "Total",
        options: {
            filter: false,
            sort: true
        }
    },
    {
        name: "puntosAcumulados",
        label: "Puntos",
        options: {
            filter: true
        }
    },
    {
        name: "bienRecibido",
        label: "Incompleto",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "observacionRecepcion",
        label: "Observaciones",
        options: {
            sort: false,
            filter: false
        }
    },
    {
        name: "numeroFactura",
        label: "Factura",
        options: {
            filter: false,
            sort: true
        }
    }
];

export const rechazado_columns = [
    {
        name: "id",
        label: "#",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "fechaCreacion",
        label: "Fecha",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "fechaAnulacion",
        label: "Rechazado",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "username",
        label: "Usuario",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "razonSocial",
        label: "Cliente",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "total",
        label: "Total",
        options: {
            filter: false,
            sort: true
        }
    },
    {
        name: "motivoAnulacion",
        label: "Motivo",
        options: {
            filter: false,
            sort: false
        }
    }
];