import React from "react";
import Message from "./Message";
import { withRouter } from "react-router-dom";

export default withRouter(class RouteMessage extends React.PureComponent<any> {
    unblock = () => null;
    componentDidMount() {
        var params: any = new URLSearchParams(this.props.location.search);
        var focusafter = params.get("focusafter");
        if (focusafter)
            this.unblock = this.props.history.block(x => {
                setTimeout(function () {
                    window.requestAnimationFrame(function () {
                        try {
                            document.getElementById(focusafter).focus();
                        } catch (ex) { }
                    });
                }, 100);
            });
    }
    componentWillUnmount() {
        this.unblock && this.unblock();
    }
    render() {
        var params: any = new URLSearchParams(this.props.location.search);
        return (<Message title={params.get("a")} message={params.get("b")} onClose={x => this.props.history.goBack()} />)
    }
});