import React from 'react';
import { Typography, CssBaseline, withStyles, Hidden } from '@material-ui/core';
import { Switch, Route, Redirect, BrowserRouter } from "react-router-dom";
import Order from './../Components/Order';
import OrderList from './../Components/OrderList';
import Feed from './../Components/Feed';
import Reports from './../Components/Reports';
import OrderQueue from '../Components/OrderQueue';
import Products from '../Components/Products';
import Users from '../Components/Users';
import TermsAndConds from './TermsAndConds';
import UserProfile from '../Components/UserProfile';
import { Provider } from 'react-redux';
import { store } from '../Redux/Store';
import Settings from '../Components/Settings';
import MultiLevel from '../Components/MultiLevel';
import NavigationDrawer, { PathIsSecure } from '../Components/NavigationDrawer';
import MyDocuments from '../Components/My-Documents';
import UserPoints from '../Components/User-Points';
import { UserActions } from '../Redux/Slices/UserSlice';
import Customers from '../Components/Customers';
import GlobalFollowUp from '../Components/Global-Follow-Up';
const drawerWidth = 200;

export default withStyles(theme => ({
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    [theme.breakpoints.up('sm')]: {
      marginLeft: drawerWidth
    }
  }
}))(class Main extends React.Component<LoggedIn & { window?: any, classes?: any }, any> {

  state = {
    selected: ""
    , username: ""
    , userId: undefined as number
    , ParentName: ""
    , rol_simbolo: ""
    , Rol: ""
    , token: ""
    , accesos: [] as { nombre: string, displayName: string }[]
    , descuentos: [] as Descuento[]
    , mustAcceptTermsAndConds: false
    , solicitarDatosAlIniciar: false
  };

  constructor(props: any) {
    super(props);
    this.onMounted = this.onMounted.bind(this);
    this.TermsAndCondsAccepted = this.TermsAndCondsAccepted.bind(this);
    this.state.username = props.username;
    this.state.ParentName = props.ParentName;
    this.state.Rol = props.Rol;
    this.state.rol_simbolo = props.rol_simbolo;
    this.state.descuentos = props.descuentos || [];
    this.state.accesos = props.accesos || [];
    this.state.mustAcceptTermsAndConds = props.mustAcceptTermsAndConds;
    this.state.solicitarDatosAlIniciar = props.solicitarDatosAlIniciar;
    this.state.userId = props.id;
    store.dispatch(UserActions.set(this.state));
  }

  onMounted(selected: string) {
    this.setState({ selected });
  }
  handleMenu() {
    this.setState(prev => {
      const state = { ...prev };
      state.isMenuOpen = !state.isMenuOpen;
      return state;
    });
  }
  TermsAndCondsAccepted() {
    this.setState({ mustAcceptTermsAndConds: false });
  }
  render() {
    const { mustAcceptTermsAndConds, solicitarDatosAlIniciar } = this.state;
    const { classes } = this.props;

    if (mustAcceptTermsAndConds)
      return (<TermsAndConds onDone={this.TermsAndCondsAccepted} />);

    if (solicitarDatosAlIniciar)
      return (<div style={{ textAlign: "center", paddingTop: 15 }}>
        <Typography variant="overline">Hola! estamos actualizando la información de nuestros miembros. Por favor llena los campos que faltan para continuar.</Typography>
        <UserProfile userId={this.state.userId} onSubmit={() => this.setState({ solicitarDatosAlIniciar: false })} />
      </div>);


    return (<BrowserRouter>
      <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>
        <CssBaseline />
        <NavigationDrawer
          ParentName={this.state.ParentName}
          access_list={this.state.accesos}
          rol_simbolo={this.state.rol_simbolo}
          Rol={this.state.Rol}
          selectedPath={this.state.selected}
          username={this.state.username}
          window={this.props.window}
        />
        <main className={classes.content}>
          <Provider store={store}>
            <Hidden smUp implementation="css">
              <div className={classes.toolbar}></div>
            </Hidden>
            {process.env.NODE_ENV === "development" ? <Typography style={{ color: "blue", background: "yellow", textAlign: "center" }} variant="h6" > AMBIENTE DE PRUEBAS </Typography> : null}
            <Switch>
              <RestrictiveRoute access_list={this.state.accesos} path="/feed" render={props => <Feed {...props} onMounted={this.onMounted} username={this.state.username} />} />
              <RestrictiveRoute access_list={this.state.accesos} path="/order" render={_ => <Order onMounted={this.onMounted} userId={this.state.userId} />} />
              <RestrictiveRoute access_list={this.state.accesos} path="/my-orders" render={props => <OrderList {...props} onMounted={this.onMounted} />} />
              <RestrictiveRoute access_list={this.state.accesos} path="/queue" render={props => <OrderQueue {...props} onMounted={this.onMounted} />} />

              <RestrictiveRoute access_list={this.state.accesos} path="/docs" render={props => <MyDocuments {...props} onMounted={this.onMounted} />} />

              <RestrictiveRoute access_list={this.state.accesos} path="/users" render={props => <Users {...props} onMounted={this.onMounted} />} />
              <RestrictiveRoute access_list={this.state.accesos} path="/products" render={props => <Products key="regular" tipo_catalogo="regular" {...props} onMounted={this.onMounted} />} />
              <RestrictiveRoute access_list={this.state.accesos} path="/products-by-points" render={props => <Products key="premios" tipo_catalogo="premios" {...props} onMounted={this.onMounted} />} />

              <RestrictiveRoute access_list={this.state.accesos} path="/global-follow-up" render={props => <GlobalFollowUp {...props} onMounted={this.onMounted} />} />
              <RestrictiveRoute access_list={this.state.accesos} path="/my-customers" render={props => <Customers userId={this.props?.id} {...props} onMounted={this.onMounted} />} />
              <RestrictiveRoute access_list={this.state.accesos} path="/customers" render={props => <Customers userId={this.props?.id} {...props} onMounted={this.onMounted} />} />
              <RestrictiveRoute access_list={this.state.accesos} path="/multi-level-cycle" render={props => <MultiLevel {...props} onMounted={this.onMounted} />} />
              <RestrictiveRoute access_list={this.state.accesos} path="/reports" render={props => <Reports {...props} onMounted={this.onMounted} />} />
              <RestrictiveRoute access_list={this.state.accesos} path="/my-profile" render={props => <UserProfile {...props} userId={this.state.userId} onMounted={this.onMounted} />} />
              <RestrictiveRoute access_list={this.state.accesos} path="/user-points" render={props => <UserPoints {...props} userId={this.state.userId} onMounted={this.onMounted} />} />
              <RestrictiveRoute access_list={this.state.accesos} path="/settings" render={props => <Settings {...props} onMounted={this.onMounted} />} />
              <Route><Redirect to="/feed" /></Route>
            </Switch>
          </Provider>
        </main>
      </div>
    </BrowserRouter>)
  }
});

function RestrictiveRoute(props: {
  path: string,
  access_list: { nombre: string, displayName: string }[],
  render: React.ComponentProps<typeof Route>["render"]

}) {
  if (PathIsSecure(props.access_list, props.path)) {
    return (<Route path={props.path} render={props.render} />);
  }
  return null;
}
