import { configureStore } from '@reduxjs/toolkit'
import Order from "../Components/Order/Redux/OrderReducer";
import UserPoints from "../Components/User-Points/Redux/UserPointsReducer";
import User from "./Slices/UserSlice";

export const store = configureStore({
    reducer: {
        Order
        , UserPoints
        , User
    },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;