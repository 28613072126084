import React from "react";
import { CircularProgress, Typography, FormControlLabel, Checkbox } from "@material-ui/core";
export default function (props: {
    error?: Error
    , comisiones?: any[]
    , comissions?: any[]
    , info_message?: string
    , loading?: boolean
    , onChange?(params: any[]): void
}) {
    const { loading, error, comisiones, comissions, info_message, onChange } = props;
    const total_comisiones = comisiones.reduce((a, b) => a + b.monto, 0).toCurrency();

    if (!onChange) return null;

    return (<div>
        {loading ? <CircularProgress color="primary" size={30} /> : null}
        {error ? <Typography variant="caption">No se pudieron cargar las comisiones:{error.message} {(error as any).code}</Typography> : null}
        {comisiones.length
            ? (<div style={{ margin: 20, textAlign: "left" }} >
                {info_message ? <Typography variant="caption" color="primary" >{info_message}</Typography> : null}
                <FormControlLabel
                    control={<Checkbox value={comissions.length} onChange={ev => {
                        onChange(ev.target.checked ? comisiones : []);
                    }} />}
                    label={`Utilizar ${total_comisiones} de comisión disponible en este pedido.`}
                />
            </div>)
            : null}
    </div>);
}