import Preferences from "../../../Utils/Preferences";
import OrderState from "./OrderState";

export default function (): [Partial<OrderState>, { cantidad: number, id: number }[]] {
    try {
        var new_state = new OrderState();
        var updates = {} as Partial<OrderState>;
        var choosen = [] as { cantidad: number, id: number }[];

        const stored_direccion = Preferences.Get("direccion") || {};
        for (const key in stored_direccion) {
            if (new_state.hasOwnProperty(key) && stored_direccion[key]) {
                updates[key] = stored_direccion[key];
            }
        }

        const stored_factura = Preferences.Get("factura") || {};
        for (const key in stored_factura) {
            if (new_state.hasOwnProperty(key) && stored_factura[key]) {
                updates[key] = stored_factura[key];
            }
        }

        const before = Preferences.Get("current_order") || {};
        for (const key in before) {
            if (new_state.hasOwnProperty(key)) {
                updates[key] = before[key];
            }
        }
        if (before && before.choosen && before.choosen.length) choosen = before.choosen;
    } catch (ex) { }
    return [updates, choosen];
}
