export function toCamelCase(texto: string) {
    var palabras = texto.toLowerCase().split(' ');
    for (var i = 0; i < palabras.length; i++) {
        var palabra = palabras[i];
        palabras[i] = palabra.charAt(0).toUpperCase() + palabra.slice(1);
    }

    return palabras.join(' ');
}
export function unLatinize(texto) {
    return texto.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/ñ/g, "n").toLowerCase();
}