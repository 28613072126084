import { CardMedia, List, ListItem, ListItemSecondaryAction, ListItemText, Typography } from "@material-ui/core";
import React from "react";
import ExpandImage from "../../lib/ExpandImage";
import { SectionHeader, TextRow } from "./DetailComponents";

export default function (props: { numeroTransaccion?: string, imgTransaccion?: string, comision_aplicada?: number, pagos?: GetOrder["pagos"] }) {

    const { numeroTransaccion, comision_aplicada, imgTransaccion, pagos } = props;

    const imagenes = ((imgTransaccion || "").indexOf(",") >= 0 ? imgTransaccion.split(",") : [imgTransaccion]).filter(x => x);

    return <>
        <SectionHeader>PAGO SUBIDO:</SectionHeader>

        {comision_aplicada ? <div style={{ margin: 10 }}><Typography>* Comision aplicada: {comision_aplicada.moneyRound()}</Typography></div> : null}

        {numeroTransaccion && <TextRow
            value={numeroTransaccion}
            label="Comprobante"
        />}

        {imagenes.map(src => <CardMedia
            component="img"
            style={{ height: "auto", width: "calc(100% - 20px)", objectFit: "contain", border: "1px solid black", margin: 10 }}
            image={src}
            title="Comprobante"
            onClick={() => ExpandImage(src)}
        />)}
        {imagenes.length ? null : <Typography variant="body1">No hay comprobantes subidos en este pedido.</Typography> }
        {pagos?.length
            ? (<>
                <Typography>PAGOS VERIFICADOS:</Typography>
                <List style={{ maxWidth: 300 }}>
                    {pagos.map(x => (
                        <ListItem key={x.id} >
                            <ListItemText>{x.metodoPago} {parseFloat(x.monto as string).toCurrency()}</ListItemText>
                            <ListItemSecondaryAction># {x.numeroTransaccion}</ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>
            </>)
            : null}
    </>
}
