import { createAsyncThunk } from "@reduxjs/toolkit";
import ImageReader from "../../../../Utils/ImageReader";

const LoadingMessageWindow = document.getElementById("loading-full-message");
const LoadImageThunk = createAsyncThunk(
    "Image/LoadImage", function (params: { imgFile: File, index?: number, reportError?(message: string): void }) {

        const { reportError, imgFile, index } = params;

        return new Promise(resolve => {

            LoadingMessageWindow.style.display = "none";

            if (window.requestAnimationFrame)
                return window.requestAnimationFrame(() => resolve(void 0));

            resolve(void 0);

        }).then(() => ImageReader(imgFile)
            .then(imgUrl => {
                LoadingMessageWindow.style.display = "none";
                return { imgUrl, imgFile, index };
            }).catch(ex => {
                LoadingMessageWindow.style.display = "none";
                reportError(ex.message);
                throw ex;
            }));
    });

export default LoadImageThunk;

/*
document.getElementById("loading-full-message").style.display = "block";
if (window.requestAnimationFrame) {
window.requestAnimationFrame(() => this._renderImage(imageFile));
} else {
this._renderImage(imageFile);
}
}
private _renderImage(imageFile) {
ImageReader(imageFile)
    .then(imgTransaccion => {
        this.setState({ imgTransaccion, imageFile }, () => {
            document.getElementById("loading-full-message").style.display = "none";
        });
    }).catch(ex => {
        document.getElementById("loading-full-message").style.display = "none";
        this.failed_msg("No se pudo cargar tu imagen. " + ex.message);
    });
}*/