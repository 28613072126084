import React from "react";
import { Pie } from "react-chartjs-2";
import { Card, CardContent, CardHeader } from "@material-ui/core";

export default class PieCard extends React.PureComponent<{ data: any[], title: string }> {
    state = {
        options: {
            title: { display: false },
            legend: { display: false },
            maintainAspectRatio: false
        }
    }
    render() {
        var labels = [];
        var values = [];
        var backgroundColor = [];
        var info = this.props.data.sort((a, b) => b.value - a.value);
        for (let i = 0; i < info.length; i++) {
            const el = info[i];
            labels.push(el.label);
            values.push(el.value);
            backgroundColor.push(el.color);
        }
        const data = {
            labels,
            datasets: [{
                backgroundColor,
                data: values
            }]
        }
        return (<Card className="dashboard-card" style={{ display: "flex", flexDirection: "column" }} >
            <CardHeader style={{ textAlign: "center" }} title={this.props.title} />
            <CardContent style={{ width: "100%", flex: 1, padding: 5 }}>
                <Pie options={this.state.options} data={data} />
            </CardContent>
        </Card >)
    }
}