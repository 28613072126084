import { Typography, makeStyles } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";
import API from "../../Services/API";
import Input from "../Input";

const useStyles = makeStyles({ root: { display: "flex", flexDirection: "row" } })
export default function UserListInput(props: {
    onChange: (parentId: number) => void;
    value: number;
    readOnlyValue?: string;
    id?: string;
    error?: string;
    loading?: boolean;
}) {
    const classes = useStyles();
    if (props.readOnlyValue) {
        return (<div className={classes.root}>
            <Typography>Madre/Padre: </Typography>
            <Typography variant="button" >{props.readOnlyValue}</Typography>
        </div>);
    }
    const [users, status] = GetUsersHook();


    if (props.value !== undefined && props.value !== null) {
        var value = users.find(x => x.id === props.value);
    }
    if (value === undefined) value = null;


    return <Autocomplete
        options={users}
        loading={status === "LOADING"}
        loadingText="Cargando datos..."
        noOptionsText="No se encontraron registros"
        renderInput={params => <Input
            placeholder={status === "LOADING" ? "CARGANDO DATOS..." : "Selecciona el Asesor/a Natú"}
            {...params}
            error={props.error}
            name="parentId"
        />}
        getOptionLabel={item => `${item.id} - ${item.nombre}`}
        value={value}
        onChange={(_, value: Parent) => props.onChange(value && value.id)}
        getOptionSelected={(item, value) => item.id === value.id}
    />
}



function GetUsersHook(): [Parent[], "" | "LOADING" | "COMPLETED" | "ERROR", Error?] {
    const [status, setStatus] = React.useState<"" | "LOADING" | "COMPLETED" | "ERROR">("LOADING");
    const [users, setUsers] = React.useState<Parent[]>([]);
    const [error, setError] = React.useState(null);

    React.useEffect(() => {
        if (status === "LOADING") {
            API.GetParents()
                .then(data => {
                    setUsers(data);
                    setStatus("COMPLETED");
                })
                .catch(ex => {
                    setStatus("ERROR");
                    setError(ex);
                });
        }
    }, [status]);
    return [users, status, error];
}

interface Parent {
    id: number;
    nombre: string;
}