import GiftRulesService from "../../Services/GiftRulesService";
import { Totals, EstimarDescuento } from "../../Utils/Calcs";
import { RootState } from "../../Redux/Store";
import ROLE_ACCESS from "../../Services/Role_Access.enum";


export interface OrderDetalle extends Product {
    cantidad: number;
    idReglaRegalo?: number;
}
export default function mapOrderState(rootState: RootState) {
    const { Order, User } = rootState;

    const selectableDiscounts = !!User?.accesos?.find(x => x.nombre === ROLE_ACCESS.puedeSeleccionarDescuentoPedido);
    const editablePrices = !!User?.accesos?.find(x => x.nombre === ROLE_ACCESS.puedeEditarPreciosPedido);

    const tablaDescuentos = (User.descuentos || [])
        .map((d, index) => {
            return { ...d, id: d.id || index };
        });

    /** Lista de productos incluyendo los productos seleccionados desde OPCIONES */
    var normalizedProductList = [...Order.data.productList].sort((a, b) => a.orden - b.orden).map(x => {
        if (x.opciones && x.opciones.length) {
            x = { ...x, cantidad: 0 };
            var exists = Order.data.productoSelecciones[x.id];
            // Creo un nuevo articulo, para evitar la Mutación
            if (exists) x.cantidad = exists.length;
        }
        return x;
    });

    const detalle = [...normalizedProductList.filter(x => x.cantidad)] as OrderDetalle[];

    var { total: subtotal, puntosAcumulados } = Totals(detalle);
    const subtotal_aplicable_descuento = Totals(detalle.filter(x => x.aplica_descuento)).total;

    if (selectableDiscounts) {
        if (Order.data.descuentoAplica) var descuentoAplica = {
            ...Order.data.descuentoAplica,
            valor: (Order.data.descuentoAplica.porcentaje * subtotal_aplicable_descuento / 100).moneyRound()
        }
    } else {
        descuentoAplica = EstimarDescuento(subtotal_aplicable_descuento, tablaDescuentos);
    }

    const total_pedido = subtotal - ((descuentoAplica && descuentoAplica.valor) || 0);
    const envio = getValorEnvio(subtotal, Order.data.tipo_envio);
    const total = (total_pedido + envio).moneyRound();

    const order = {
        ...Order.data
        , descuentoAplica
        , detalle
        , productList: normalizedProductList
        , subtotal
        , valor_pedido_calculo_envio: subtotal
        , subtotal_regalos: 0
        , subtotal_aplicable_descuento
        , total_pedido
        , envio
        , total
        , puntosAcumulados
    };
    const detalle_regalo = new GiftRulesService().ApplyRules(rootState.Order.giftRules, order);
    order.detalle.push(...detalle_regalo);
    // el subtotal se actualiza aquí, pero no importa ya que 
    const subtotal_regalos = detalle_regalo.reduce((resume, x) => resume + (x.cantidad * x.pvp_final), 0);
    order.subtotal = (subtotal_regalos + order.subtotal).moneyRound();
    order.subtotal_regalos = subtotal_regalos;
    order.subtotal_aplicable_descuento = (subtotal_regalos + order.subtotal_aplicable_descuento).moneyRound();

    return {
        order,
        isDirty: Order.isDirty,
        tablaDescuentos,
        currentTab: Order.currentTab,
        async_status: Order.status,
        async_error: Order.error,
        selectableDiscounts,
        editablePrices
    }
}

const ENVIO_GRATIS_A_PARTIR_DE = 60;

export function getValorEnvio(total_pedido: number, tipo_envio: RootState["Order"]["data"]["tipo_envio"]) {
    if (total_pedido >= ENVIO_GRATIS_A_PARTIR_DE && tipo_envio.indexOf("GALAPAGOS") < 0) return 0;
    if (tipo_envio === "PICK-UP") return 0;
    if (tipo_envio === "DENTRO DE CIUDAD") return 3;
    if (tipo_envio === "ZONA CONTINENTAL") return 5;
    if (tipo_envio === "GALAPAGOS ISLA CRUZ - BALTRA") return 10;
    if (tipo_envio === "GALAPAGOS ISLA ISABELA Y FERNANDINA") return 14;
    if (tipo_envio === "ENVIO CON EL SIGUIENTE PEDIDO") return 0;
}

export type mapOrderStateProps = ReturnType<typeof mapOrderState>