import API from "../../../../Services/API";
import { DocToString } from "../../../../Utils/Calcs";
import { ValidarIdentificacion, ValidateInput } from "../../../../Utils/Validation";
import Preferences from "../../../../Utils/Preferences";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { mapOrderStateProps } from "../../mapOrderState";
import { ImgFileToObject } from "../../../../Utils/ImageReader";
import { isEmpty } from "../../../../lib/ValueChecks";
import { OrderStateCustomerData } from "../../lib/OrderState";


const OrderSaveThunk = createAsyncThunk(
    "Order/SaveOrderIntoAPI", async function (params: {
        order: mapOrderStateProps["order"]
        , reportError(message: Error, propertyName?: string): void
            , reportCompleted(id: number): void
    }) {
    const { order, reportError, reportCompleted } = params;
    try {
        const error = validateCustomerData(order);
        if (error) {
            if (error.productList) throw {
                message: error.productList,
                name: "ProductsValidationError"
            };

            var keys = Object.keys(error);
            if (keys.length) {
                if ([
                    "ciudad", "referencia", "direccion", "sector", "tipo_envio",
                    "identificacion", "documento", "razonSocial",
                    "tipo_envio", "direccion_factura", "telefono_factura", "email_factura",
                    "identificacion_contacto", "nombre_contacto", "telefono_contacto"
                ].indexOf(keys[0]) >= 0) throw {
                    message: error[keys[0]],
                    propertyName: keys[0],
                    name: "OrderDataValidationError"
                };

                throw {
                    message: error[keys[0]],
                    propertyName: keys[0],
                    name: "OrderValidationError"
                };
            }
        }

        const { observacion, tipo_envio,
            documento_contacto, identificacion_contacto, nombre_contacto, telefono_contacto,
            documento, identificacion, razonSocial, telefono_factura, direccion_factura, email_factura,
            descuentoAplica, usarImagen, productoSelecciones,
            imgTransaccionList,
            subtotal, envio, total, comisiones_cruzar
        } = order;

        let { ciudad, sector, direccion, referencia } = order;
        if (tipo_envio === "PICK-UP") {
            ciudad = "";
            sector = "";
            direccion = "";
            referencia = "";
        }
        if (tipo_envio === "DENTRO DE CIUDAD") {
            ciudad = "QUITO";
        }

        if (usarImagen) {
            var filesTransaccion = await Promise.all(imgTransaccionList.map(x => ImgFileToObject(x.file)));
        } else {
            var numeroTransaccion = order.numeroTransaccion;
        }
        const { id } = await API.SaveOrder({
            ciudad, sector, direccion, observacion, referencia, tipo_envio,
            documento_contacto, identificacion_contacto, nombre_contacto, telefono_contacto,
            documento, identificacion, razonSocial, telefono_factura, direccion_factura, email_factura,
            detalle: order.detalle.map(x => ({
                ...x
                , idProducto: x.id
                , cantidad: x.cantidad as number
                , descuentoPorcentaje: x.idReglaRegalo
                    ? 100
                    : (x.aplica_descuento ? (descuentoAplica && descuentoAplica.porcentaje) : 0)
                , descuentoTotal: x.idReglaRegalo
                    ? (x.cantidad * x.pvp_final)
                    : 0
            })).map(x => {
                delete x.id;
                if (x.descuentoPorcentaje && !x.descuentoTotal) {
                    x.descuentoTotal = ((x.cantidad * x.pvp_final) * x.descuentoPorcentaje) / 100;
                    x.descuentoTotal = x.descuentoTotal.moneyRound();
                }
                return x;
            }),
            numeroTransaccion,
            filesTransaccion,

            subtotal,
            envio,
            regaloTotal: order.detalle.reduce((a, b) => a + (b.idReglaRegalo ? (b.pvp_final * b.cantidad) : 0), 0).moneyRound(),
            baseIva: 0,
            iva: 0,
            total,
            comisiones_cruzar,

            descuentoPorcentaje: descuentoAplica && descuentoAplica.porcentaje,
            descuentoTotal: descuentoAplica && descuentoAplica.valor,
            productoSelecciones
        });
        Preferences.Set("current_order");
        Preferences.Set("direccion", {
            direccion, ciudad, sector, referencia, tipo_envio,
            documento_contacto, identificacion_contacto, nombre_contacto, telefono_contacto,
        });
        Preferences.Set("factura", {
            identificacion, documento, razonSocial,
            telefono_factura, direccion_factura, email_factura,
        });
        reportCompleted(id);
        return id;
    } catch (ex) {
        reportError(ex);
        throw ex;
    }
});



export function validateCustomerData(order: OrderStateCustomerData & { detalle?: any[] }) {
    var { numeroTransaccion, ciudad, sector, direccion, tipo_envio,
        documento, identificacion, razonSocial, telefono_factura, direccion_factura,
        documento_contacto, identificacion_contacto, nombre_contacto, telefono_contacto,
        email_factura, usarImagen, imgTransaccionList, detalle
    } = order;

    var results: any = {};

    if (!detalle.length) return { productList: "Debes elegir por lo menos un producto" };

    if (isEmpty(tipo_envio)) results.tipo_envio = "Selecciona el tipo de envío";

    if (["PICK-UP", "DENTRO DE CIUDAD"].indexOf(tipo_envio) < 0 && isEmpty(ciudad)) results.ciudad = "Digita la ciudad";
    if (tipo_envio !== "PICK-UP" && isEmpty(sector)) results.sector = "Digita el sector";
    if (tipo_envio !== "PICK-UP" && isEmpty(direccion)) results.direccion = "Digita la direccion";

    if (isEmpty(documento_contacto)) results.documento_contacto = "Selecciona el documento de identificación de la persona que recibe";
    if (isEmpty(identificacion_contacto)) {
        results.identificacion_contacto = "Digita el número de " + DocToString(documento_contacto) + " de la persona que recibe";
    } else {
        let error = ValidarIdentificacion(identificacion_contacto, documento_contacto)
        if (error) results.identificacion_contacto = "La identificación de la persona que recibe es incorrecta: " + error;
    }
    if (isEmpty(nombre_contacto)) results.nombre_contacto = "Digita el nombre de la persona que recibe";
    if (isEmpty(telefono_contacto)) {
        results.telefono_contacto = "Digita el numero de Telefono de la persona que recibe";
    } else {
        let error = ValidateInput.tel(telefono_contacto);
        if (error) results.telefono_contacto = "Telefono de persona que recibe inválido: " + error;
    }


    if (isEmpty(documento)) results.documento = "Selecciona un documento de identificación";
    if (isEmpty(identificacion)) {
        results.identificacion = "Digita el número de " + DocToString(documento) + " de facturación";
    } else {
        let error = ValidarIdentificacion(identificacion, documento)
        if (error) results.identificacion = "La identificación de facturación está incorrecta: " + error;
    }
    if (isEmpty(razonSocial)) results.razonSocial = "Digita la Razon Social de la factura";
    if (isEmpty(telefono_factura)) {
        results.telefono_factura = "Digita el número de Telefono de facturación";
    } else {
        let error = ValidateInput.tel(telefono_factura);
        if (error) results.telefono_factura = "Telefono de facturación inválido: " + error;
    }
    if (isEmpty(direccion_factura)) results.direccion_factura = "Digita la dirección de facturación";
    if (isEmpty(email_factura)) results.email_factura = "Digita el email de facturación";
    if (isEmpty(email_factura)) {
        results.email_factura = "Digita el email de facturación";
    } else {
        let error = ValidateInput.email(email_factura);
        if (error) results.email_factura = error;
    }

    if (usarImagen) {
        if (!(imgTransaccionList && imgTransaccionList.length)) results.imgTransaccionList = "Agrega la imagen del comprobante de pago";
    } else {
        if (isEmpty(numeroTransaccion)) results.numeroTransaccion = "Digita el numero de comprobante de pago";
    }

    if (Object.keys(results).length) return results;
    return null;
}

export default OrderSaveThunk;