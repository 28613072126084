import React from "react";
import { CardMedia, Card, CardContent, Typography, Input, CardActions, InputAdornment, IconButton, CardActionArea } from "@material-ui/core";
import { AttachMoney, Add, Remove } from "@material-ui/icons";

interface Props extends Omit<Partial<Product>, "id" | "pvp_final"> {
    id: any;
    pvp_final: any;
    stock?: number;
    habilitado?: boolean;
    editablePrice?: boolean;
    onActionClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    onPriceChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    controls?: "ADD-REMOVE";
    noPuedeAumentar?: boolean;
    onCardClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export default function (props: Props) {
    const { onCardClick, onActionClick, controls, ...remainProps } = props;

    const item = props;

    return (<Card style={props.habilitado ? undefined : { opacity: 0.35 }} >
        {onCardClick
            ? (<CardActionArea name="card" value={props.id} onClick={onCardClick}>
                <Content {...remainProps} />
            </CardActionArea>)
            : <Content {...remainProps} />}

        {controls === "ADD-REMOVE"
            && (<CardActions>
                <IconButton disabled={!(item.cantidad > 0)} onClick={onActionClick} name="-" value={item.id}>
                    <Remove />
                </IconButton>
                <Typography variant="body1">{item.cantidad}</Typography>
                <IconButton
                    disabled={!canAddQuantity(item)}
                    onClick={onActionClick}
                    name="+"
                    value={item.id}
                >
                    <Add />
                </IconButton>
            </CardActions>)}
    </Card>);
}

function canAddQuantity(item: Props) {
    if (Object.prototype.hasOwnProperty.call(item, "stock")
        && item.cantidad >= item.stock) {
        return false;
    }
    if (item.noPuedeAumentar) return false;
    return true;
}

function Content(props: Props) {
    const item = props;
    return (<>
        <CardMedia component="img" className="card-product-img" image={item.imgSrc || "https://diegopq95.github.io/take-orders/img/default.png"} />
        <CardContent>
            {props.tipo_catalogo === "premios"
                ? <PremioBottomContent {...props} />
                : <RegularBottomContent {...props} />
            }
        </CardContent>
    </>);
}


function RegularBottomContent(props: Props) {
    const item = props;
    return (<>
        <div style={{ display: "flex", flexDirection: "row" }}>
            <Typography style={{ flex: 1, height: 60, overflow: "hidden" }} title={item.nombre} variant="subtitle1">{item.nombre}</Typography>
            <div style={{ display: item.id === "NUEVO" ? "none" : "block" }}>
                {props.editablePrice
                    ? <Input
                        style={{ width: 100 }}
                        type="number"
                        onChange={props.onPriceChange}
                        value={"" + item.pvp_final}
                        name={"" + item.id}
                        startAdornment={<InputAdornment position="start" >
                            <AttachMoney />
                        </InputAdornment>}
                    />
                    : <Typography variant="h6">$ {item.pvp_final.toFixed(2)}</Typography>}
                {item.puntos
                    ? (<Typography title={"Gana " + item.puntos + " puntos por cada unidad."} variant="overline" style={{ cursor: "help", display: "block" }}>
                        +{item.puntos} puntos
                    </Typography>)
                    : null}
            </div>
        </div>
        <div style={{ display: item.id === "NUEVO" ? "none" : "block" }}>
            <Descripcion>{item.descripcion_url}</Descripcion>
            <YesNoText value={item.aplica_descuento}>Aplica descuento.</YesNoText>
            <YesNoText value={item.aplica_comision}>Comisionable.</YesNoText>
        </div>
    </>);
}
function PremioBottomContent(props: Props) {
    const item = props;
    return (<>
        <div style={{ display: "flex", flexDirection: "row" }}>
            <Typography style={{ flex: 1, height: 60, overflow: "hidden" }} variant="subtitle1" title={item.nombre}>{item.nombre}</Typography>
            <div style={{ textAlign: "right" }}>
                <Typography variant="h6">Puntos: {item.puntosPrecio || 0}</Typography>
                <Typography variant="overline" style={{ display: "block" }}>{(item.stock || 0) > 0 ? ("Stock " + item.stock) : "Sin Stock"}</Typography>
            </div>
        </div>
        <div>
            <Descripcion>{item.descripcion_url}</Descripcion>
        </div>
    </>);
}
function Descripcion(props: {
    children: string
}) {
    const value = props.children;
    return (<Typography variant="caption" style={{ display: "inline" }}
        component={value ? "a" : "p"}
        target="_blank" href={value}
    >
        {value ? "Información del Producto." : "Sin información."}
    </Typography>);
}
function YesNoText(props: {
    value?: boolean,
    children: React.ReactNode
}) {
    return (<Typography
        variant="button"
        style={{ display: "inline" }}
    >
        {props.value ? "" : "No"} {props.children}
    </Typography>)
}