import { Tooltip, IconButton, Typography } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import React from "react";
import API from "../../../Services/API";
import Edit from "./Edit";
import DataTable from "../../DataTable";

export default function () {

    const [records, set_records] = React.useState([] as GiftConditionRow[]);
    const [status, set_status] = React.useState("data");
    const [openEdit, set_openEdit] = React.useState(false as true | false | number);

    React.useEffect(() => {
        if (status === "data")
            API.GetGiftRules(true)
                .then(results => {
                    set_records(results.map(x => {
                        return {
                            ID: x.id,
                            Productos: x.regalos.map(x => `${x.nombre} x${x.cantidad}`).join(", "),
                            Condiciones: x.condiciones.map(x => `${x.propName} ${x.operator} ${x.value}`).join("y "),
                            Desde: new Date(x.desde).toLocaleDateString("es-EC", {
                                month: "short", day: "numeric", year: "numeric"
                            }),
                            Hasta: new Date(x.hasta).toLocaleDateString("es-EC", {
                                month: "short", day: "numeric", year: "numeric"
                            })
                        }
                    }));
                }).catch(ex => {
                    alert(ex.message);
                }).then(() => {
                    set_status("");
                });
        if (status.indexOf("delete:") === 0)
            API.DeleteGiftRule(+status.split(":")[1])
                .then(() => {
                    set_status("data");
                }).catch(ex => {
                    set_status(ex.message);
                });


    }, [status]);

    const isLoading = status.indexOf("delete:") >= 0 || status === "data" ? true : null;
    const errMsg = status.length && !isLoading ? status : null;
    const openEditView = openEdit ? true : null;

    return (<>
        <div style={{ textAlign: "center" }}>
            {errMsg && <>
                <Typography variant="h4" color="error">Ocurrió un error</Typography>
                <Typography variant="h6" color="error">{errMsg}</Typography>
            </>}
        </div>
        <DataTable
        title=""
            columns={[
                { name: "ID" }
                , { name: "Condiciones" }
                , { name: "Desde" }
                , { name: "Hasta" }
                , { name: "Productos" }
            ]}
            data={records}
            options={{
                print: false,
                download: false,
                sort: false,
                filter: false,
                search: false,
                viewColumns: false,
                pagination: false,
                selectableRows: "single",
                onRowsDelete(rowsDeleted: { data: { index: number }[] }) {
                    var confirmed = window.confirm("Desea eliminar el registro?");
                    if (!confirmed) return false;
                    return set_status("delete:" + records[rowsDeleted.data[0].index].ID);
                },
                textLabels: {
                    body: {
                        noMatch: isLoading ? "Buscando Registros 🔎 .... " : "No se encontraron registros 🤷‍♀️"
                    }
                },
                customToolbar() {
                    return (<Tooltip title="Agregar Regla" >
                        <IconButton color="primary" onClick={() => set_openEdit(true)} >
                            <Add />
                        </IconButton>
                    </Tooltip>);
                }
            }}
        />
        {openEditView && <Edit
            onClose={should_reload => {
                set_openEdit(false);
                if (should_reload) set_status("data");
            }}
        />}
    </>);
}

interface GiftConditionRow {
    Condiciones: string
    , Productos: string
    , ID: number
    , Desde: string
    , Hasta: string
}