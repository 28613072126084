import React from "react";
import { Dialog, DialogContent, Typography, DialogTitle, DialogActions, Button } from "@material-ui/core";

export default function Message(props: {
    title?: string,
    message?: string,
    info?: string,
    onClose: (ev) => void,
    children?: any
}) {
    const { title, message, info, onClose } = props;
    return (<Dialog open={Boolean(message) || Boolean(props.children)} onClose={onClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
            {props.children || (<div>
                <Typography variant="subtitle1">{message}</Typography>
                <Typography variant="body1">{info}</Typography>
            </div>)}
        </DialogContent>
        <DialogActions>
            <Button type="button" onClick={props.onClose} >SALIR</Button>
        </DialogActions>
    </Dialog>);
}