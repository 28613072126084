import React from "react";
import { Dialog, DialogActions, Button, makeStyles } from "@material-ui/core";
import TabLayout from "../../Layouts/TabLayout";
import CRMTab from "../Users/CRM";
import CustomerPersonalData from "./CustomerPersonalData";

const useStyles = makeStyles({
    tabContent: {
        padding: 10,
        overflow: "auto"
    }
});

export default function EditCustomer(props: {
    open: boolean;
    onClose(): void;
    id?: React.ReactText;
    isUser?: boolean;
}) {
    const [id, set_id] = React.useState(props.id);
    const classes = useStyles();
    React.useEffect(() => {
        if (props.open) {
            set_id(props.id);
        } else {
            set_id(undefined);
        }
    }, [props.open]);

    const tabs = [
        {
            Component: componentProps => (<div className={classes.tabContent}>
                <CustomerPersonalData {...componentProps} id={id} onSaved={item => set_id(item.id)} />
            </div>)
            , tabLabel: "Datos Personales"
            , tabId: "personal-data"
        },
        {
            Component: componentProps => (<div className={classes.tabContent}>
                <CRMTab {...componentProps} id={id} onSaved={item => set_id(item.id)} />
            </div>)
            , tabLabel: "CRM"
            , tabId: "crm"
        }
    ];

    if (!id) tabs.splice(1, 1);

    return (<Dialog maxWidth="sm" fullWidth open={props.open} onClose={() => props.onClose()}>
        <TabLayout
            key={id}
            tabs={tabs}
        />
        <DialogActions>
            <Button onClick={props.onClose} color="primary">SALIR</Button>
        </DialogActions>
    </Dialog>)
}