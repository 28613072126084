import { MUIDataTableColumn } from "mui-datatables";

const UserColumns = [
    {
        name: "id",
        label: "ID"
    }, {
        name: "nombre",
        label: "Nombre"
    }, {
        name: "ParentName",
        label: "Patrocinador"
    }, {
        name: "parentId",
        label: "ID Patrocinador"
    }, {
        name: "username",
        label: "Usuario"
    }, {
        name: "Rol",
        label: "Rol"
    }, {
        name: "ultimoPedido",
        options: { display: "none" }
    }, {
        name: "UltimoPedido",
        label: "Ultimo Pedido"
    }, {
        name: "Estado",
        label: "Estado"
    }
] as Array<MUIDataTableColumn>
export default UserColumns;