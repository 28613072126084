import { Card, CardContent } from "@material-ui/core";
import React from "react";
import API from "../../../Services/API";
//import { MonthToString } from "../../../Utils/Calcs";
import LeftRigthHeader from "../LeftRightHeader";

export interface CycleTraveleableProps {
    data: DashboardResult
    , current_cycle: Cycle
    , loading?: boolean
}
export default function <P extends CycleTraveleableProps>(Component: React.ComponentType<P>) {
    return class CardWithCycleTravel extends React.Component<P & CardWithCycleTravelProps> {
        state = {
            data: {} as DashboardResult,
            current_cycle: null as Cycle,
            stats: [] as Array<keyof DashboardResult>,
            loading: false
        }
        constructor(props) {
            super(props);
            this.state.stats = props.stats;
            this.state.current_cycle = props.current_cycle;
            this.state.data = props.data;
        }
        handleBack = () => {
            if (this.state.loading) return;
            this.setState({ loading: true });
            this.fetchData(this.state.current_cycle.index - 1);
        }
        fetchData(cycleIndex: number) {
            API.GetFeed({ stats: [...this.state.stats, "current_cycle"], cycleIndex })
                .then(this.updateData).catch(this.showError);
        }
        handleNext = () => {
            if (this.state.loading) return;
            this.setState({ loading: true });
            this.fetchData(this.state.current_cycle.index + 1);
        }
        updateData = (response: DashboardResult) => {
            const { current_cycle, ...data } = response;
            this.setState({
                data,
                current_cycle,
                loading: false
            });
        }
        showError = (ex) => {
            this.setState({ loading: false });
            alert(ex.message);
        }
        render() {

            var { stats, title, ...componentProps } = this.props;
            const { current_cycle, loading, data } = this.state;
            var subtitle = current_cycle ? (current_cycle.name || `Ciclo ${current_cycle.number}`) : "";

            const ComponentProps = {
                ...componentProps
                , current_cycle
                , loading
                , data
            } as P;

            return (<Card style={{ display: "flex", flexDirection: "column" }} >
                <LeftRigthHeader
                    loading={loading}
                    onBackClick={(current_cycle && current_cycle.index) ? this.handleBack : null}
                    onNextClick={this.handleNext}
                    subtitle={subtitle}
                    title={title}
                />
                <CardContent style={{ flex: 1, padding: 0, overflow: "hidden" }}>
                    <Component
                        {...ComponentProps}
                    />
                </CardContent>
            </Card>);
        }
    }
}

interface CardWithCycleTravelProps {
    stats: Array<keyof DashboardResult>;
    current_cycle: Cycle;
    title: string;
    reduceFn?: (data: any[]) => any[];
}