import React from "react";
import Input from "../../Input";
import { Card, CardContent, Grid, Typography, makeStyles } from "@material-ui/core";
import LoadingButton from "../../Common/LoadingButton";
import API from "../../../Services/API";
import * as dateformat from "date-format";

export interface Interaction {
    resumen?: string;
    fechaLlamada?: string;
    fechaSiguienteLlamada?: string;
    crmId?: string;
    id?: string;
}
export default function NewInteraction(props: {
    onCompleted?(data?: any): void
        , crmId?: React.ReactText
}) {

    const [interaction, set_interaction] = React.useState<Interaction>({});
    const [status, set_status] = React.useState("");
    const [error, set_error] = React.useState<InputError | null>(null);
    React.useEffect(() => {
        if (status !== "SUBMIT") return;
        set_error(null);
        var promise = API.addCRMCustomerFollowUp({
            resumen: interaction.resumen
            , fechaLlamada: toDate(interaction.fechaLlamada)
            , fechaSiguienteLlamada: toDate(interaction.fechaSiguienteLlamada)
            , id: interaction.id
            , crmId: props.crmId
        });
        promise.then(data => {
            set_interaction({});
            props.onCompleted && props.onCompleted(data);
        }).catch(ex => {
            set_error(ex);
        }).finally(() => {
            set_status("");
        })
        return () => promise.abort();
    }, [status]);

    const isLoading = ["SUBMIT"].includes(status);

    return (<Card elevation={5}>
        <CardContent component="form" onSubmit={e => {
            e.preventDefault();
            var err = valdidateDate(interaction?.fechaLlamada);
            if (err) return set_error(new InputError(err, "fechaLlamada"));

            err = valdidateDate(interaction?.fechaSiguienteLlamada);
            if (err) return set_error(new InputError(err, "fechaSiguienteLlamada"));

            if (!interaction?.resumen?.toString().trim().length)
                return set_error(new InputError("El campo `resumen` no puede estar vacío.", "resumen"));

            if (toDate(interaction?.fechaSiguienteLlamada).valueOf() <= toDate(interaction?.fechaLlamada).valueOf()) {
                return set_error(new Error("La fecha de la siguiente llamada debe ser mayor a la fecha de la llamada actual."))
            }

            set_status("SUBMIT");
        }}>
            <Typography variant="h6">Nuego seguimiento</Typography>
            <Grid container>
                <Grid item sm={8}>
                    <Input multiline minRows={9} label="Resumen de la llamada"
                        disabled={isLoading}
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{
                            shrink: true
                        }}
                        placeholder="Me indica que le está yendo muy bien con los productos y pronto abrirá una tienda exclusiva para vender Natú. Tuvo consultas sobre el Serum y Humectante, le envié los enlaces del sitio web con la información."
                        error={error?.input === "resumen" ? error.message : ""}
                        name="resumen"
                        value={interaction?.resumen || ""}
                        onChange={e => set_interaction({ ...interaction, resumen: e.target.value })}
                    />
                </Grid>
                <Grid item>
                    <div>
                        <Input
                            disabled={isLoading}
                            type="date"
                            label="Fecha de llamada"
                            fullWidth
                            InputLabelProps={{
                                shrink: true
                            }}
                            error={error?.input === "fechaLlamada" ? error.message : ""}
                            name="fechaLlamada"
                            value={interaction?.fechaLlamada || ""}
                            onChange={e => set_interaction({ ...interaction, fechaLlamada: e.target.value })}
                        />
                    </div>
                    <div>
                        <Input
                            disabled={isLoading}
                            type="date"
                            label="Siguiente llamada"
                            fullWidth
                            InputLabelProps={{
                                shrink: true
                            }}
                            error={error?.input === "fechaSiguienteLlamada" ? error.message : ""}
                            name="fechaSiguienteLlamada"
                            value={interaction?.fechaSiguienteLlamada || ""}
                            onChange={e => set_interaction({ ...interaction, fechaSiguienteLlamada: e.target.value })}
                        />
                    </div>
                    <div>
                        <LoadingButton fullWidth isLoading={isLoading} type="submit" variant="outlined" color={error ? "secondary" : "primary"}>Guardar</LoadingButton>
                    </div>
                    <div>
                        <LoadingButton disabled={isLoading} fullWidth type="button" onClick={e => {
                            if (!Object.keys(interaction || {}).length) return;
                            const confirmed = window.confirm("Desea limpiar la información?");
                            if (!confirmed) return;
                            set_interaction({});
                        }} variant="text">Limpiar</LoadingButton>
                    </div>
                </Grid>
            </Grid>
            {error && (<div style={{ textAlign: "right" }}>
                <Typography variant="caption" color="error">{error?.message}</Typography>
            </div>)}
        </CardContent>
    </Card>);
}


class InputError extends Error {
    name = "UserInputError"
    constructor(message: string, readonly input?: string) {
        super(message);
    }
}

function valdidateDate(value: string) {
    if (!value) return "Campo vacío.";
    const from = toDate(value);
    if (!(from && from.toJSON())) return "fecha inválida";
}

function toDate(value: string): Date | null {
    return dateformat.parse("yyyy-MM-dd hh:mm:ss.SSS", value + " 00:00:00.000");
}