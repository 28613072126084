import React from "react";
import { Line } from "react-chartjs-2";
import { Card, CardContent } from "@material-ui/core";
import { MonthToString } from "../../Utils/Calcs";
import LeftRigthHeader from "./LeftRightHeader";

export default class LineCard extends React.PureComponent<{ data: any[], title: string }> {
    state = {
        options: {
            title: { display: false },
            legend: { display: false },
            maintainAspectRatio: false,
            bezierCurve: false,
            scales: {
                yAxes: [{

                    ticks: {
                        maxTicksLimit: 10,
                        padding: 5,
                        beginAtZero: true
                    }
                }],
                xAxes: [{
                    ticks: {
                        padding: 5
                    }
                }]
            }
        }
    }
    render() {
        const { data: RawData, ...remain } = this.props;
        var labels = [];
        var values = [];
        for (let i = 0; i < RawData.length; i++) {
            const el = RawData[i];
            if (el.we) {
                var x = new Date(el.we);
                labels.push(`${x.getDate()} ${MonthToString(x.getMonth())}`);
            } else {
                labels.push(el.label);
            }
            values.push(el.value);
        }
        const data = {
            labels,
            datasets: [{
                backgroundColor: "#3f51b5",
                borderColor: "#3f51b5",
                data: values,
                lineTension: 0,
                fill: false
            }]
        }
        return (<Card className="dashboard-card" style={{ display: "flex", flexDirection: "column" }} >
            <LeftRigthHeader
                {...remain}
            />
            <CardContent style={{ width: "100%", flex: 1, padding: 5 }}>
                <Line options={this.state.options} data={data} />
            </CardContent>
        </Card >);
    }
}
