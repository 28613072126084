import React from "react";
import { Button } from "@material-ui/core";
import API from "../Services/API";
import AcceptAgreementsInput from "../Components/AcceptAgreementsInput";

export default class extends React.Component<{
    onDone: () => void
}> {
    state = {
        checked: false,
        loading: false
    }
    constructor(props) {
        super(props);
        this.handleCheck = this.handleCheck.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }
    handleCheck(event) {
        const checked = event.currentTarget.checked;
        this.setState({ checked });
    }
    handleClick(event) {
        if (this.state.loading) return;
        if (!this.state.checked) return;
        this.setState({ loading: true });

        API.SendUserAction("ACCEPT_TERMS_AND_CONDS")
            .then(this.props.onDone)
            .catch(ex => {
                alert(ex.message);
                this.setState({ loading: false });
            });
    }
    render() {
        const { checked, loading } = this.state;
        return (<div className="full-height" style={{ display: "flex", flexDirection: "column", justifyContent: "center", justifyItems: "center" }}>
            <div style={{
                margin: "auto",
                textAlign: "center",
                paddingTop: 10,
                paddingBottom: 10,
                width: "calc(100% - 40px)",
                paddingLeft: 20,
                paddingRight: 20
            }}>
                <div>
                    <AcceptAgreementsInput
                        disabled={loading}
                        checked={checked}
                        onChange={this.handleCheck}
                    />
                </div>
                <div>
                    <Button
                        disabled={loading || !checked}
                        color="primary"
                        variant="contained"
                        onClick={this.handleClick}
                    >
                        CONTINUAR
                </Button>
                </div>
            </div>
        </div>);
    }
}