import React from "react";
import { Tabs, Tab, AppBar } from "@material-ui/core";
import { Switch, Route, Redirect } from "react-router-dom";
import API from "../../Services/API";
import Queue from "./Queue";
import OrderDetails from "../OrderList/OrderDetails";
import { entrada_columns, aprobado_columns, enviado_columns, facturado_columns, rechazado_columns } from "./Columns";

export default class OrderQueue extends React.Component<{
    match?: any,
    history?: any,
    location?: any,
    onMounted?: Function
}> {
    state = {
        data: [],
        loading: true,
        error: null,
        id: null
    }
    componentDidMount() {
        if (this.props.onMounted) this.props.onMounted(this.props.match.path);
        this.loadData();
    }
    loadData() {
        API.GetOrders()
            .then(rawData => {
                const data = rawData.map(x => {
                    var fechaCreacion = new Date(x.fechaCreacion);
                    x.fechaCreacion = fechaCreacion.toLocaleDateString() as any;
                    x.fechaEnvio = x.fechaEnvio && new Date(x.fechaEnvio).toLocaleDateString() as any;
                    x.fechaRecepcion = x.fechaRecepcion && new Date(x.fechaRecepcion).toLocaleDateString() as any;
                    x.fechaAnulacion = x.fechaAnulacion && new Date(x.fechaAnulacion).toLocaleDateString() as any;
                    x.total = x.total.toFixed(2) as any;
                    x.bienRecibido = (x.bienRecibido ? "" : "SI") as any;
                    x.changedAt = (x.changedAt
                        ? new Date(x.changedAt).valueOf()
                        : 0) as any;

                    //x.numeroTransaccion = x.imgTransaccion ? "IMAGEN" : x.numeroTransaccion;
                    return x;
                });
                this.setState({ data, loading: null });
            })
            .catch(error => this.setState({ error }));
    }
    handleTab = (_, value) => {
        this.props.history.push(this.props.match.path + value);
    }
    openOrder = id => {
        this.props.history.push(this.props.match.path + this.currentTab() + "/" + id);
    }
    closeOrder = shouldUpdate => {
        if (shouldUpdate) this.loadData();
        this.props.history.push(this.props.match.path + this.currentTab());
    }
    currentTab() {
        return "/" + (this.props.location.pathname.split("/")[2] || "inbox");
    }
    render() {
        return (<div>
            <AppBar position="relative" color="primary">
                <Tabs onChange={this.handleTab} value={this.currentTab()} variant="fullWidth">
                    <Tab label="Entrada" value="/inbox" />
                    <Tab label="Aprobado" value="/ok" />
                    <Tab label="Enviado" value="/sent" />
                    {/*<Tab label="Recibido" value="/ack" />*/}
                    <Tab label="Facturado" value="/invoice" />
                    <Tab label="Rechazado" value="/denied" />
                </Tabs>
            </AppBar>
            <Switch>
                <Route path={this.props.match.path + "/inbox"}
                    render={_ => (<Queue
                        columns={entrada_columns}
                        data={this.state.data}
                        onClick={this.openOrder}
                        estado="PENDIENTE"
                    />)}
                />
                <Route path={this.props.match.path + "/ok"}
                    render={_ => (<Queue
                        columns={aprobado_columns}
                        data={this.state.data}
                        onClick={this.openOrder}
                        estado="APROBADO"
                    />)}
                />
                <Route path={this.props.match.path + "/sent"}
                    render={_ => (<Queue
                        columns={enviado_columns}
                        data={this.state.data}
                        onClick={this.openOrder}
                        estado="ENVIADO"
                    />)}
                />
                {/*<Route path={this.props.match.path + "/ack"}
                    render={_ => (<Queue
                        columns={recibido_columns}
                        data={this.state.data}
                        onClick={this.openOrder}
                        estado="RECIBIDO"
                    />)}
                />*/}
                <Route path={this.props.match.path + "/invoice"}
                    render={_ => (<Queue
                        columns={facturado_columns}
                        data={this.state.data}
                        onClick={this.openOrder}
                        estado="FACTURADO"
                    />)}
                />
                <Route path={this.props.match.path + "/denied"}
                    render={_ => (<Queue
                        columns={rechazado_columns}
                        data={this.state.data}
                        onClick={this.openOrder}
                        estado="RECHAZADO"
                    />)}
                />
                <Route path={this.props.match.path + "/"} exact>
                    <Redirect to={this.props.match.path + "/inbox"} />
                </Route>
            </Switch>
            <Route path={this.props.match.path + "/:group/:id"}
                render={routeProps => <OrderDetails
                    id={+routeProps.match.params.id}
                    onClose={this.closeOrder}
                    editable={true} />}
            />
        </div>);
    }
}
