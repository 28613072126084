import React from "react";
import { ListItem, List, ListItemText, ListItemAvatar } from "@material-ui/core";

export default function (props: {
    items: {
        id: number
        , nombre: string
        , cantidad: number
    }[]
}) {
    return (<List>
        {props.items.map(it => (
            <ListItem dense>
                <ListItemAvatar>
                    <>{it.cantidad}</>
                </ListItemAvatar>
                <ListItemText>{it.nombre}</ListItemText>
            </ListItem>
        ))}
        {props.items.length
            ? null
            : (<ListItem>
                <ListItemText>No hay premios para enviar con este pedido.</ListItemText>
            </ListItem>)
        }
    </List>);
}