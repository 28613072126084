import React from "react";
import DataTable from "../../DataTable";
import AddIcon from "@material-ui/icons/Add";
import { Button, CircularProgress, Typography } from "@material-ui/core";
import API from "../../../Services/API";
import { BugReport } from "@material-ui/icons";

function createPointsQuestionarie() {
    const accion = window.prompt("Escribe la acción que quieres realizar: 'ACUMULAR' o 'EXPIRAR'. ");
    if (accion === null || accion === undefined) window.alert("Acción cancelada");

    if (["ACUMULAR", "EXPIRAR"].indexOf((accion || "").toString().trim().toUpperCase()) < 0)
        return window.alert("La acción ingresada no es válida. Intentelo nuevamente.");

    const puntos = window.prompt("Digite la cantidad de puntos:");
    if (accion === null || accion === undefined) window.alert("Acción cancelada");
    if (isNaN(+puntos)) return window.alert("Los puntos solo pueden ser números. Intentelo nuevamente");

    const observacion = window.prompt("Ingrese la observación.");
    if (accion === null || accion === undefined) window.alert("Acción cancelada");

    return {
        accion: accion.toString().trim().toUpperCase(), puntos: +puntos, observacion
    };
}

export default function UserPointsHistory(props: {
    userId?: number
}) {
    const { userId } = props;

    const [loading, set_loading] = React.useState(false);
    const [error, set_error] = React.useState<Error | null>(null);
    const [data, set_data] = React.useState([]);

    React.useEffect(() => {
        set_loading(true);
    }, []);

    React.useEffect(() => {
        if (loading) return;
        if (userId === null || userId === undefined) return;
        set_loading(true);
        const promise = API.GetUserPointsBalance(props.userId);
        promise.then(results => {
            set_data(results as any);
        }).catch(ex => {
            set_error(ex);
        }).finally(() => {
            set_loading(false);
        })
        return () => promise.abort();
    }, [userId]);

    const onAdd = () => {
        if (loading) return;

        const obj = createPointsQuestionarie();
        if (!obj) return;

        set_loading(true);
        API.addCustomPoints({ ...obj, puntos: +obj.puntos, userId }).then(() => {
            window.alert("Registro guardado con éxito. Cambie de pantalla para actualizar la lista.");
        }).catch(ex => {
            set_error(ex);
        }).finally(() => {
            set_loading(false);
        })
    }

    if (userId === null || userId === undefined) return <p>Usuario no ha sido creado aún.</p>;

    if (loading) return (<div style={{ margin: "auto", textAlign: "center", width: "100%", padding: 30 }} ><CircularProgress size={50} /></div>);

    if (error) return (<div style={{ margin: "auto", textAlign: "center", width: "100%", padding: 30 }} >
        <BugReport color="inherit" />
        <Typography color="secondary" variant="body1">{error?.message}</Typography>
    </div>);

    return <DataTable
        columns={[
            {
                name: "createdAt"
                , label: "Fecha"
                , options: {
                    customBodyRender(value: Date) {
                        if (!value) return "";
                        return new Date(value).toLocaleDateString("es-EC", {
                            day: "numeric", month: "short", year: "numeric", hour: "2-digit", minute: "2-digit"
                        })
                    }
                    , sortDescFirst: true
                }
            },
            {
                name: "creatorName"
                , label: "Hecho por:"
            }, {
                name: "observacion"
                , label: "Observacion"
            }, {
                name: "debe"
                , label: "+"
            }, {
                name: "haber"
                , label: "-"
            }, {
                name: "saldo"
                , label: "Saldo"
            }
        ]}
        options={{
            sortOrder: { name: "createdAt", direction: "desc" }
            , selectableRows: "none"
            , customToolbar() {
                return <>
                    <Button type="button" onClick={onAdd}>
                        <AddIcon />
                    </Button>
                </>
            }
        }}
        data={data}
        title={null}
    />
}