import React from "react";
import UserColumns from "./Columns";
import { Tooltip, IconButton, Typography, CircularProgress } from "@material-ui/core";
import { Add, Policy } from "@material-ui/icons";
import API from "../../Services/API";
import Edit from "./Edit";
import { Route } from "react-router-dom";
import RouteMessage from "../RouteMessage";
import DataTable from "../DataTable";

export default class Users extends React.Component<UserProps> {
    unmounted = false;
    state = {
        data: [] as VisibleUser[],
        grupos: [],
        loading: true,
        error: null,
        downloadSeparator: ","
    }
    componentWillMount() {
        if (this.props.onMounted) this.props.onMounted(this.props.match.path);
        this.FetchData();
    }
    componentWillUnmount() { this.unmounted = true };
    FetchData = () => {
        this.setState({ loading: true });
        API.GetUsers(UserColumns.map(x => x.name as keyof VisibleUser))
            .then(data => {
                if (this.unmounted) return;
                this.setState({
                    data: (data || []).map(x => {
                        if (x.ultimoPedido)
                            x.UltimoPedido = new Date(x.ultimoPedido).toLocaleDateString();
                        return x;
                    }),
                    loading: false
                });
            }).catch(error => {
                this.setState({ loading: false });
                if (this.unmounted) return;
                this.reportError(error.message);
            });
    }
    reportError(b: string, a?: string, focusafter: string = "") {
        if (this.unmounted) return;
        var params = new URLSearchParams({
            a: a || "Ocurrió un problema",
            b,
            focusafter
        });
        this.props.history.push(this.props.match.path + "/msg?" + params.toString());
    }
    handleNew = () => {
        this.props.history.push(this.props.match.path + "/view/NUEVO");
    }
    handleClose = update => {
        if (update) this.FetchData();
        this.props.history.push(this.props.match.path);
    }

    handleClick = (arr_data) => {
        var ID = arr_data[0];
        this.props.history.push(this.props.match.path + "/view/" + ID);
    }
    handleTermsAndConds = () => {
        var isOk = window.confirm("Esta seguro de Mostrar en TODOS los usuarios ACEPTAR TERMINOS Y CONDICIONES al entrar");
        if (isOk) {
            API.SendUserAction("ACCEPT_TERMS_AND_CONDS", "all")
                .then(this.FetchData)
                .catch(ex => this.reportError(ex.message));
        }
    }
    render() {
        const { data, loading, grupos } = this.state;

        if (loading) {
            var content_part = (<div style={{ paddingTop: 10, textAlign: "center" }}>
                <Typography variant="h6">CARGANDO...</Typography>
                <CircularProgress size={50} color="primary" />
            </div>);
        } else {
            content_part = (<DataTable
                title=" "
                data={data}
                columns={UserColumns}
                options={{
                    selectableRows: "none",
                    onRowClick: this.handleClick,
                    customToolbar: () => {
                        return (<>
                            <div style={{ maxWidth: 100, minWidth: 100, width: 100 }} ></div>
                            <Tooltip title="Mostrar en TODOS los usuarios ACEPTAR TERMINOS Y CONDICIONES al entrar">
                                <IconButton color="secondary" onClick={this.handleTermsAndConds}  >
                                    <Policy />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Agregar Usuario" >
                                <IconButton color="primary" onClick={this.handleNew} >
                                    <Add />
                                </IconButton>
                            </Tooltip>
                        </>);
                    },
                    customSort(data, colIndex: number, order: "asc" | "desc") {
                        var col = UserColumns[colIndex];
                        return data.sort((a, b) => {
                            var value1 = a.data[colIndex];
                            var value2 = b.data[colIndex];

                            if (col.name === "UltimoPedido") {
                                value1 = (a.data[colIndex - 1] && new Date(a.data[colIndex - 1]).valueOf()) || 0;
                                value2 = (b.data[colIndex - 1] && new Date(b.data[colIndex - 1]).valueOf()) || 0;
                            } else {
                                if (typeof value1 === typeof "") value1 = value1.toLowerCase().trim();
                                if (typeof value2 === typeof "") value2 = value2.toLowerCase().trim();
                            }
                            if (order === "desc") {
                                if (value1 > value2) return -1;
                                if (value2 > value1) return 1;
                                return 0;
                            }
                            if (value1 < value2) return -1;
                            if (value2 < value1) return 1;
                            return 0;
                        });
                    }
                }}
            />);
        }

        return (<div>
            {content_part}
            <Route path={this.props.match.path + "/msg"} component={RouteMessage} />
            <Route path={this.props.match.path + "/view/:id"} children={({ match }) => <Edit
                show={!!match?.params?.id}
                grupos={grupos}
                id={(match?.params?.id || "NUEVO") === "NUEVO" ? undefined : match?.params?.id}
                onClose={this.handleClose}
            />} />
        </div >);
    }
}

interface UserProps {
    onMounted?: any,
    match?: any,
    history?: any
}