/// <reference path="../../shared/Comission.d.ts"/>
import React, { ReactNode } from "react";
import { Tabs, Tab, Paper, Typography, TextField, Fab } from "@material-ui/core";
import ProductGrid from "../Products/ProductGrid";
import ProductOptions from "./ProductOptions";
import ConfirmOrder from "../ConfirmOrder";
import Data from "./Data";
import Payment from "./Payment";
import Survey from "./Survey";
import { withRouter, Route } from "react-router-dom";
import RouteMessage from "../RouteMessage";
import { Save } from "@material-ui/icons";
import ProductSelections from "./ProductSelections";
import OrderState from "./lib/OrderState";
import { connect } from "react-redux";
import FetchOrderDataThunk from "./Redux/Thunks/FetchOrderDataThunk";
import { actions } from "./Redux/OrderReducer";
import LoadImageThunk from "./Redux/Thunks/LoadImageThunk";
import LocalStoreOrder from "./Redux/Thunks/LocalStorePedido";
import mapOrderState, { mapOrderStateProps } from "./mapOrderState";
import OrderSaveThunk from "./Redux/Thunks/OrderSaveThunk";
import { FlexPaperContentTab, FlexTabContainer } from "../../Layouts/FlexTab";

type OrderReduceActions = typeof actions;
interface OrderProps extends OrderReduceActions, mapOrderStateProps {
    onMounted: (path: string) => void;
    history: any;
    match: any;
    userId: number;

    fetchInitialData: typeof FetchOrderDataThunk;
    loadTransaccionImg: typeof LoadImageThunk;
    setStoredOrder: typeof LocalStoreOrder;
    saveOrder: typeof OrderSaveThunk;
}
const OrderComponent = withRouter(class Order extends React.PureComponent<OrderProps, OrderState> {
    unblockNavigation() { }
    constructor(props: any) {
        super(props);
        if (props.onMounted) props.onMounted(props.match.path);
    }
    componentDidMount() {
        window.onbeforeunload = _ =>
            this.props.isDirty
                ? "Tu pedido aún está incompleto, deseas salir sin guardarlo?"
                : null;
        this.unblockNavigation = this.props.history.block(this.routerWillLeave.bind(this));
        this.props.fetchInitialData();
    }
    componentWillUnmount() {
        this.props.updateCurrentTab("ProductList");
        this.unblockNavigation && this.unblockNavigation();
        window.onbeforeunload && (window.onbeforeunload = undefined);
    }
    routerWillLeave(nextLocation?) {
        if (nextLocation && nextLocation.pathname.indexOf(this.props.match.path) === 0) return;
        if (!this.props.isDirty) return;

        var confirm = window.confirm("Tu pedido está incompleto, quieres guardarlo para completarlo después?");
        if (confirm) {
            this.props.setStoredOrder({});
        } else {
            // actualizar todo!
            this.props.setStoredOrder({ clean: true });
        }
    }
    guardarBorrador = (event) => {
        if (!this.props.isDirty) return;
        this.props.setStoredOrder({
            reportCompleted: () => this.message("Tu pedido fue guardado como borrador", "Listo!")
        });
    }
    handleDone = () => {
        if (this.props.async_status.indexOf(":loading") >= 0) return;
        this.props.saveOrder({
            order: this.props.order
            , reportCompleted: (id) => {
                this.props.history.push(this.props.match.path + "/survey/" + id);
            },
            reportError: (exception) => {
                this.message(exception.message, undefined, (exception as any).propertyName);
            }
        });
    }
    message = (message: string, title?: string, focusafter?: string) => {
        var params = new URLSearchParams();
        params.set("a", title || "Ocurrió un problem");
        params.set("b", message);
        if (focusafter) params.set("focusafter", focusafter);
        this.props.history.push(this.props.match.path + "/msg?" + params.toString());
    }
    handleProductAction = (event: React.MouseEvent<HTMLButtonElement>) => {
        const { name, value } = event.currentTarget;
        const item = this.props.order.productList.find(x => x.id === (+value));
        if (item && item.opciones && item.opciones.length)
            return this.props.history.push(this.props.match.path + "/select-product-options/" + item.id);

        this.props.changeProductQuantity({ Id: +value, type: name as any });
    }
    handleValueChange = (name: string, value: any) => {
        this.props.changeDataValue({ name: name as any, value });
    }
    imageChange = (imgFile?: File, index?: number) => {
        if (!imgFile) return;
        this.props.loadTransaccionImg({ imgFile, index, reportError: this.message });
    }
    imageDelete = (index: number) => {
        if (index < 0) return;
        this.props.deleteImgTransaccion(index);
    }

    handleAddSelection = (result?) => {
        if (result) this.props.addProductSelection(result);
        this.props.history.push(this.props.match.path);
    }
    handleDeleteSelection = (result) => {
        if (result) this.props.removeProductSelection(result);
        this.props.history.push(this.props.match.path);
    }
    handlePriceChange = (event) => {
        const id = +event.currentTarget.name;
        const pvp_final = event.currentTarget.value;
        this.props.changeProductPrice({ id, pvp_final });
    }
    handleComisionesChange = (value: Comission[]) => {
        this.props.changeDataValue({
            name: "comisiones_cruzar", value
        });
    }
    render() {
        const { currentTab, order, async_status, tablaDescuentos, editablePrices, selectableDiscounts } = this.props;

        const isLoading = async_status.indexOf(":loading") >= 0;

        return (<FlexTabContainer>
            <Tabs variant="fullWidth" onChange={(_, currentTab) => this.props.updateCurrentTab(currentTab)} value={currentTab}>
                <Tab value="ProductList" label="Productos" />
                <Tab value="OrderData" label="Datos" />
                <Tab value="ConfirmOrder" label="Pedido" />
                <Tab value="OrderPayment" label="Pago" />
            </Tabs>
            <Hide if={currentTab !== "ProductList"}>
                <ProductGrid
                    controls="ADD-REMOVE"
                    onActionClick={this.handleProductAction}
                    items={order.productList}
                    editablePrices={editablePrices}
                    onPriceChange={this.handlePriceChange}
                    async_status={this.props.async_status}
                    async_error={(this.props.async_status || "").indexOf("products") >= 0 ? this.props.async_error : undefined}
                />
            </Hide>
            <Hide if={currentTab !== "OrderData"} >
                <Data visible={true} {...order} onValueChange={this.handleValueChange} />
            </Hide>
            {currentTab === "ConfirmOrder" && (<FlexPaperContentTab style={{ maxWidth: 500 }}>
                <Typography variant="h5">TU PEDIDO</Typography>
                <ConfirmOrder
                    show_next_discount={true}
                    items={order.detalle}

                    descuento={order.descuentoAplica}
                    valor_envio={order.envio}
                    gran_total={order.total}
                    total_pedido={order.total_pedido}
                    subtotal={(order.subtotal - order.subtotal_regalos).moneyRound()}
                    subtotal_con_descuento={(order.subtotal_aplicable_descuento - order.subtotal_regalos).moneyRound()}

                    LabelDescuentoComponent={selectableDiscounts
                        ? (<TextField
                            onChange={(ev) => this.handleValueChange("descuentoAplica", tablaDescuentos.find(x => x.id === (+ev.currentTarget.value)))}
                            value={(order.descuentoAplica && order.descuentoAplica.id) || ""}
                            SelectProps={{ native: true }}
                            select
                        >
                            <option>- SELECCIONA UN DESCUENTO -</option>
                            {tablaDescuentos.map(d => (
                                <option key={d.id} value={d.id}>DESCUENTO {d.porcentaje}%</option>
                            ))}
                        </TextField>)
                        : null}
                />
                <TextField
                    variant="outlined"
                    value={order.observacion}
                    name="observacion"
                    onChange={(ev) => this.handleValueChange(ev.currentTarget.name, ev.currentTarget.value)}
                    style={{ width: "calc(100% - 20px)", margin: 10 }}
                    label="Observacion"
                    multiline
                    rows="2"
                    inputProps={{ maxLength: 300, id: "observacion" }}
                />
            </FlexPaperContentTab>)}
            {currentTab === "OrderPayment" && <Payment
                loading={isLoading}
                total_pagar={order.total}
                onDone={this.handleDone}

                imgTransaccionList={order.imgTransaccionList}
                onImgChange={this.imageChange}
                onImgDelete={this.imageDelete}

                onSelectedComissionsChange={this.handleComisionesChange}
                comissions={order.comisiones_cruzar}
                puntosAcumulados={order.puntosAcumulados}
                userId={this.props.userId}
            />}

            <Route path={this.props.match.path + "/msg"} component={RouteMessage} />
            <Route path={this.props.match.path + "/survey/:id"} component={Survey} />
            <Route path={this.props.match.path + "/select-product-options/:id"}
                children={({ match }) => <ProductOptions
                    showingID={match && +match.params.id}
                    onDone={this.handleAddSelection}
                    productList={order.productList}
                />}
            />
            <Route path={this.props.match.path + "/view-product-options/:id"}
                children={({ match }) => <ProductSelections
                    id={match && match.params.id}
                    onDeleteSelection={this.handleDeleteSelection}
                    productList={order.productList}
                    selections={order.productoSelecciones}
                />}
            />
            <Fab title="Guardar Borrador" onClick={this.guardarBorrador} variant="extended" style={{ position: "fixed", bottom: 20, right: 20 }} color="primary">
                <Save />
            </Fab>
        </FlexTabContainer>);
    }
});


export default connect(mapOrderState, {
    ...actions
    , fetchInitialData: FetchOrderDataThunk
    , loadTransaccionImg: LoadImageThunk
    , setStoredOrder: LocalStoreOrder
    , saveOrder: OrderSaveThunk
})(OrderComponent);


function Hide(props: { if: boolean, children: ReactNode }) {
    if (props.if) return null;
    return <>{props.children}</>;
}