import { Button, Dialog, DialogActions, DialogContent, TextField, Typography } from "@material-ui/core";
import * as dateformat from "date-format";
import React from "react";
import API from "../../../Services/API";
import ItemList from "../../ItemList";

export default function (props: {
    onClose(has_changed?: boolean): void;
}) {
    const [status, set_status] = React.useState("fetch_products");
    const [desde, set_desde] = React.useState(dateformat("yyyy-MM-dd", new Date()));
    const [hasta, set_hasta] = React.useState(dateformat("yyyy-MM-dd", new Date()));
    const [pedido_subtotal, set_pedido_subtotal] = React.useState("" as string);
    const [productos, set_productos] = React.useState([] as { id: number, nombre: string, cantidad: string }[]);


    const [filteredProductList, set_filteredProductList] = React.useState([] as { id: number, nombre: string, cantidad: string }[]);

    React.useEffect(() => {
        if (status === "fetch_products") {
            API.GetProducts({ tipo_catalogo: "regular" })
                .then(products => {
                    set_filteredProductList(products.filter(x => !((x.componentes && x.componentes.length) || (x.opciones && x.opciones.length)))
                        .map(x => ({
                            id: x.id,
                            nombre: x.nombre,
                            cantidad: ""
                        }))
                    );
                    set_status("");
                });
        }
        if (status === "guardar") {
            const results = Validate(desde, hasta, pedido_subtotal, productos);
            if (results.error) return set_status(results.error);
            API.SaveGiftRule(results.data)
                .then(isOk => props.onClose(true))
                .catch(ex => set_status(ex.message));
        }
    }, [status]);

    const isLoading = ["guardar", "eliminar", "fetch_products", "fetch_record"].indexOf(status) >= 0;
    const errMsg = (!isLoading && status.length) ? status : null;

    return (<Dialog open={true} onClose={() => {
        if (!isLoading) props.onClose();
    }}>
        <DialogContent>
            <div>
                <TextField
                    value={desde}
                    onChange={ev => set_desde(ev.target.value)}
                    label="Desde"
                    type="date"
                />
                <TextField
                    value={hasta}
                    onChange={ev => set_hasta(ev.target.value)}
                    label="Hasta"
                    type="date"
                />
            </div>
            <TextField
                label="Pedido.Subtotal"
                value={pedido_subtotal}
                onChange={ev => set_pedido_subtotal(ev.target.value)}
                type="number"
                inputProps={{
                    step: "0.01"
                }}
            />
            <Typography>Productos a Regalar</Typography>
            <ItemList
                dataSource={filteredProductList}
                DisplayProp="nombre"
                IdProp="id"
                items={productos}
                onAdd={results => {
                    const productos_nuevo = [...productos];
                    productos_nuevo.push({
                        cantidad: results.cantidad,
                        nombre: results.item.nombre,
                        id: results.item.id
                    });
                    set_productos(productos_nuevo);
                }}
                onRemove={result => {
                    const productos_nuevo = [...productos];
                    const index = productos_nuevo.indexOf(result.item);
                    if (index < 0) return;
                    productos_nuevo.splice(index, 1);
                    set_productos(productos_nuevo);
                }}
                inputs={[{
                    allowZero: false, label: "Cantidad", type: "number", name: "cantidad"
                }]}
                SecondaryDisplayProp="cantidad"
            />
        </DialogContent>
        <DialogActions>
            {errMsg && <Typography color="error">{errMsg}</Typography>}
            <Button disabled={isLoading} type="button" onClick={() => props.onClose()} >SALIR</Button>
            <Button disabled={isLoading} type="button" onClick={() => set_status("guardar")} >GUARDAR</Button>
        </DialogActions>
    </Dialog>)
}


function Validate(desde, hasta, pedido_subtotal, productos): { error?: string, data?: GiftRule } {
    const data = {
        desde: null as Date,
        hasta: null as Date,
        condiciones: [],
        regalos: []
    };
    try {
        data.desde = dateformat.parse("yyyy-MM-dd hh:mm:ss.SSS", desde + " 00:00:00.000");
    } catch (ex) {
        return { error: "La fecha `Desde` no es correcta" };
    }
    try {
        data.hasta = dateformat.parse("yyyy-MM-dd hh:mm:ss.SSS", hasta + " 23:59:59.999");
    } catch (ex) {
        return { error: "La fecha `Hasta` no es correcta" };
    }
    if (!(pedido_subtotal && (+pedido_subtotal) > 0 && !isNaN(+pedido_subtotal)))
        return { error: "El valor de `Pedido Subtotal` no es valido" };

    data.condiciones.push({
        propName: "pedido.subtotal", value: pedido_subtotal, operator: ">="
    });

    if (!(productos && productos.length && !productos.find(x => isNaN(+x.cantidad) || !(+x.cantidad))))
        return { error: "Seleccione al menos un producto y agregue una cantidad mayor a 0" };

    data.regalos = productos;

    return { data };
}