import React from "react";
import TipoEnvioInput from "../Order/TipoEnvioInput";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";
import { RootState } from "../../Redux/Store";
import RedeemPointsThunk from "./Redux/Thunks/RedeemPointsThunk";
import { UserPointsActions } from "./Redux/UserPointsReducer";
import OrderCustomerData from "../Order/Data";
import { OrderStateCustomerData } from "../Order/lib/OrderState";
import Payment from "../Order/Payment";
import LoadImageThunk from "../Order/Redux/Thunks/LoadImageThunk";
import { getValorEnvio } from "../Order/mapOrderState";
import Message from "../Message";

function CheckoutCanjearPuntos(props: OrderStateCustomerData & {
    total_pagar: number;
    error?: Error;
    onSubmit: typeof RedeemPointsThunk;
    setValue(name: string, value: string): void;
    onImgChange(imgFile?: File, index?: number): void;
    onImgDelete(index: number): void;
    status: RootState["UserPoints"]["status"]
}) {

    const {
        status
        , total_pagar
        , onSubmit
        , setValue
        , onImgChange
        , onImgDelete
        , error
        , ...order
    } = props;

    const renderCustomerData = total_pagar > 0;

    const isLoading = ["INITIALIZING", "LOADING", "SUBMIT"].indexOf(props.status) >= 0;

    const [message, set_message] = React.useState(undefined as string);

    React.useEffect(() => {
        /** Inicializacion (primera vez que se abre la pantalla. es util para evitar que se
         * muestren los mensajes varias veces, si se llega a mantener esta pantalla encendida) */
        if (message === undefined) return set_message("");
        /** Inicializacion */
        if (error?.message && error?.message !== message) set_message(error.message);
    }, [error]);

    return (<div>
        <TipoEnvioInput
            value={props.tipo_envio}
            onChange={function (event) {
                const { name, value } = event.target;
                setValue(name, value);
            }}
            envio_siguiente_pedido={true}
        />
        {renderCustomerData
            ? <OrderCustomerData
                {...order}
                onValueChange={setValue}
                hide_tipo_envio={true}
                thirdColumnContent={<Payment
                    imgTransaccionList={order.imgTransaccionList}
                    loading={isLoading}
                    onImgChange={onImgChange}
                    onImgDelete={onImgDelete}
                    total_pagar={total_pagar}
                    onDone={onSubmit}
                />}
            />
            : (<Button
                onClick={onSubmit}
                type="button"
                variant="outlined"
                disabled={isLoading}
            >
                Enviar
            </Button>)}
        {message
            ? <Message message={error?.message} title={"No se puede continuar."} onClose={() => set_message("")} />
            : null
        }
    </div>)
}

export default connect(({ UserPoints }: RootState) => ({
    ...UserPoints.order
    , status: UserPoints.status
    , error: UserPoints.error
    , total_pagar: getValorEnvio(0, UserPoints.order.tipo_envio)
}), {
    onSubmit: RedeemPointsThunk as any
    , setValue(prop: string, value: string) { return UserPointsActions.setOrderValue([prop, value]) }
    , onImgChange(imgFile?: File, index?: number) {
        return LoadImageThunk({
            imgFile, index
        });
    }
    , onImgDelete: UserPointsActions.deleteImage
})(CheckoutCanjearPuntos);