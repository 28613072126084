import React from "react";
import API from "../../Services/API";
import { MonthToString } from "../../Utils/Calcs";
export default function <T = { [key: string]: any }>(Component) {
    return class WithTimeTravel extends React.Component<RankWithTimeTravel & T> {
        state = {
            data: [] as any,
            current_week: null as Week,
            statName: null as any,
            reduceFn: null as (data: any[]) => any[],
            loading: false
        }
        constructor(props) {
            super(props);
            this.state = { ...props };
        }
        handleBack = () => {
            if (this.state.loading) return;
            this.setState({ loading: true });
            this.fetchData(this.state.current_week.id - 1);
        }
        fetchData(semana: number) {
            API.GetFeed({ stats: [this.state.statName, "current_week"], semana })
                .then(this.updateData).catch(this.showError);
        }
        handleNext = () => {
            if (this.state.loading) return;
            this.setState({ loading: true });
            this.fetchData(this.state.current_week.id + 1);
        }
        updateData = (results) => {
            const { current_week, ...remain } = results;
            this.setState({
                data: remain[this.state.statName] || [],
                current_week,
                loading: false
            });
        }
        showError = (ex) => {
            this.setState({ loading: false });
            alert(ex.message);
        }
        render() {
            var { current_week, data, reduceFn, loading } = this.state;
            var subtitle = "";
            if (current_week) {
                let desde = new Date(current_week.start);
                let hasta = new Date(current_week.end);
                subtitle = `${desde.getDate()} ${MonthToString(desde.getMonth())} - ` +
                    `${hasta.getDate()} ${MonthToString(hasta.getMonth())}`;
            }
            var { data, ...remainState } = this.state;
            if (reduceFn) data = reduceFn(data);

            return (<Component
                {...remainState}
                data={data}
                subtitle={subtitle}
                loading={loading}
                onBackClick={this.handleBack}
                onNextClick={this.handleNext}
            />);
        }
    }
}

interface RankWithTimeTravel {
    statName: string;
    reduceFn?: (data: any[]) => any[];
    current_week?: Week;
}