import { Grid, InputAdornment } from "@material-ui/core";
import React from "react";
import Input from "../Input";

export default function (props: SavePayment & {
    onChange(propName: "metodoPago" | "monto" | "numeroTransaccion", value: string)
        , add_to_id?: string
}) {

    const { metodoPago, monto, numeroTransaccion, onChange, add_to_id } = props;

    return (<Grid container>
        <Grid item md={3} sm={12}>
            <Input
                placeholder="Método de pago"
                select
                autoFocus
                SelectProps={{ native: true }}
                name={"pago" + (add_to_id ? ("#" + add_to_id) : "") + ".metodoPago"}
                value={metodoPago}
                onChange={ev => onChange("metodoPago", ev.currentTarget.value)}
            >
                <option>- Selecciona una opción -</option>
                <option value="PICHINCHA">BANCO PICHINCHA</option>
                <option value="PRODUBANCO">BANCO PRODUBANCO</option>
                <option value="PAYPHONE">PAYPHONE</option>
                {/*<option value="PAYPAL">PayPal</option>*/}
            </Input>
        </Grid>
        <Grid item md={3} sm={6}>
            <Input
                placeholder="# Comprobante"
                name={"pago" + (add_to_id ? ("#" + add_to_id) : "") + ".numeroTransaccion"}
                type="tel"
                value={numeroTransaccion}
                onChange={ev => onChange("numeroTransaccion", ev.currentTarget.value)}
            />
        </Grid>
        <Grid item md={3} sm={6}>
            <Input
                InputProps={{
                    startAdornment: (<InputAdornment position="start">$</InputAdornment>)
                }}
                placeholder="Monto"
                type="number"
                name={"pago" + (add_to_id ? ("#" + add_to_id) : "") + ".monto"}
                inputProps={{
                    step: "0.01"
                }}
                value={monto ? ("" + monto) : ""}
                onChange={ev => onChange("monto", ev.currentTarget.value)}
            />
        </Grid>
    </Grid>)
}