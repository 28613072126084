import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import FetchPremioInitialData from "./Thunks/UserPointsFetchInitialState";
import RedeemPointsThunk from "./Thunks/RedeemPointsThunk";
import { OrderStateCustomerData } from "../../Order/lib/OrderState";
import LoadImageThunk from "../../Order/Redux/Thunks/LoadImageThunk";


const UserPointsReducer = createSlice({
    name: "UserPoints",
    reducers: {
        productAction(state, { payload }: PayloadAction<{ Id: number, type: "+" | "-" }>) {
            const product = state.productList.find(x => x.id === payload.Id);
            if (!product) return state;
            if (!product.cantidad) product.cantidad = 0;
            if (payload.type === "+")
                product.cantidad++;
            if (payload.type === "-")
                product.cantidad--;
            if (!(product.cantidad > 0)) product.cantidad = 0;
            if (product.cantidad > product.stock) product.cantidad = product.stock;
        }
        , submit(state) {
            state.status = "SUBMIT";
        }
        , setOrderValue(state, { payload }: PayloadAction<[string, any]>) {
            state.order[payload[0]] = payload[1];
        }
        , deleteImage(state, { payload: index }: PayloadAction<number>) {
            const item = state.order.imgTransaccionList[index];
            if (item) state.order.imgTransaccionList.splice(index, 1);
        }
    },
    extraReducers(builder) {
        builder.addCase(FetchPremioInitialData.pending, state => {
            state.status = "INITIALIZING";
            state.resumen_puntos = {
                disponible: 0,
                perdidos: 0,
                canjeados: 0,
                acumulados: 0
            };
            state.error = null;
            state.productList = [];
            state.order = Object.assign({}, new OrderStateCustomerData({
                tipo_envio: "ENVIO CON EL SIGUIENTE PEDIDO"
            }));
        }).addCase(FetchPremioInitialData.rejected, (state, action) => {
            state.status = "INITIALIZATION_ERROR";
            state.error = action.payload as Error;
        }).addCase(FetchPremioInitialData.fulfilled, (state, action) => {
            state.productList = (action.payload[0] || []).map(x => ({ ...x, cantidad: 0 }));
            state.resumen_puntos = action.payload[1] as any;
            state.status = "";
        })

            .addCase(RedeemPointsThunk.pending, state => {
                state.status = "SUBMIT";
                state.error = null;
            })
            .addCase(RedeemPointsThunk.rejected, (state, action) => {
                state.status = "ERROR";
                state.error = action.payload as Error;
            })
            .addCase(RedeemPointsThunk.fulfilled, state => {
                // recarga toda la pantalla basicamente
                state.status = "SUBMITTED";
            })

            .addCase(LoadImageThunk.pending, state => {
                state.status = "LOADING";
            })
            .addCase(LoadImageThunk.fulfilled, (state, action) => {
                state.status = "";
                const item = {
                    src: action.payload.imgUrl
                    , file: action.payload.imgFile
                };
                const index = action.payload.index;
                if (state.order.imgTransaccionList[index]) {
                    state.order.imgTransaccionList[index] = item;
                } else {
                    state.order.imgTransaccionList.push(item);
                }
            })
            .addCase(LoadImageThunk.rejected, (state, action) => {
                state.status = "ERROR";
                state.error = action.error as Error;
            });
    },
    initialState: {
        productList: [] as Product[]
        , resumen_puntos: {
            disponible: 0,
            perdidos: 0,
            canjeados: 0,
            acumulados: 0
        }
        , order: Object.assign({}, new OrderStateCustomerData({
            tipo_envio: "ENVIO CON EL SIGUIENTE PEDIDO"
        }))
        , error: null as Error
        , status: "INITIALIZING" as StatusType
    }
});


export default UserPointsReducer.reducer;
export type PremioReduceState = ReturnType<typeof UserPointsReducer["getInitialState"]>
export const UserPointsActions = UserPointsReducer.actions;
export type StatusType = "INITIALIZE" | "INITIALIZING" | "INITIALIZATION_ERROR" | "LOADING" | "SUBMIT" | "SUBMITTED" | "ERROR" | "";