import React from "react";
import API from "../../Services/API";
import ProductGrid from "./ProductGrid";
import { Typography, CircularProgress } from "@material-ui/core";
import { Route, match } from "react-router-dom";
import { History } from "history";
import Edit from "./Edit";

export default class extends React.Component<{
    onMounted(string): void,
    tipo_catalogo: "premios" | "regular",
    match: match,
    history: History
}> {
    state = {
        data: [] as Product[],
        loading: true,
        error: null
    }

    componentDidMount() {
        if (this.props.onMounted) this.props.onMounted(this.props.match.path);
        this._Update();
    }

    _Update = () => {
        API.GetProducts({ todos: true, tipo_catalogo: this.props.tipo_catalogo })
            .then(data => this.setState({ data, loading: false }))
            .catch(error => this.setState({ loading: false, error }));
    }
    handleClick = e => {
        this.props.history.push(this.props.match.path + "/" + e.currentTarget.value);
    }
    handleDone = shouldUpdate => {
        if (shouldUpdate) this._Update();
        this.props.history.push(this.props.match.path);
    }
    render() {
        if (this.state.loading)
            return (<div style={{ width: "100%", textAlign: "center", paddingTop: 100 }} >
                <CircularProgress size={100} />
                <Typography variant="h6">CARGANDO...</Typography>
            </div>);

        return (<div style={{ width: "calc(100% - 25px)", margin: "auto", marginTop: 10 }}>
            <ProductGrid
                items={this.state.data}
                showNewItem={true}
                onCardClick={this.handleClick}
            />
            <Route
                path={this.props.match.path + "/:id"}
                children={({ match }) => (
                    <Edit
                        tipo_catalogo={this.props.tipo_catalogo}
                        productList={this.state.data}
                        showingID={match && match.params.id}
                        onDone={this.handleDone}
                    />
                )}
            />
        </div>);
    }
}