/// <reference path="./typings/index.d.ts" />
import "./lib/ExtendNumber";
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import App from "./App";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
/** 
  <React.StrictMode>
    <App />
  </React.StrictMode>
*/

const Theme = createTheme({
  palette: {
    primary: {
      main: "#9f7c3a"
      , contrastText: "#fff"
    },
    secondary: {
      main: "#be6337"
      , contrastText: "#fff"
    }
  }
})
ReactDOM.render(<React.StrictMode>
  <ThemeProvider theme={Theme}>
    <App />
  </ThemeProvider>
</React.StrictMode>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
