import React from "react";
import { Button, CircularProgress } from "@material-ui/core";
export default function LoadingButton(props: React.ComponentProps<typeof Button> & {
    isLoading?: boolean
    waitingCircleSize?: React.ReactText
}) {
    const { isLoading, waitingCircleSize, children, ...BtnProps } = props;

    return <Button {...BtnProps} disabled={isLoading ? true : BtnProps.disabled} >{isLoading ? <CircularProgress color="inherit" size={waitingCircleSize || 20} /> : children}</Button>;

}