import React from "react";
import { Grid, Card, CardMedia, CardActionArea, CardContent, Typography } from "@material-ui/core";
import Modal from "../Modal";

interface DoneObject {
    id: number,
    selections: number[]
}
interface Props {
    productList: Product[],
    onDone: (DoneObject?) => void,
    showingID: number,
    match?: any
}

function ItemCard(props) {
    const selectedStyle = props.selected ? { border: "3px solid #333" } : null;
    return (<Card>
        <CardActionArea onClick={props.onClick} value={props.id} style={selectedStyle} component="button">
            <CardMedia style={{ minHeight: 150, height: "25vh" }}
                image={props.imgSrc}
                title={props.nombre}
            />
            <CardContent>
                <Typography>{props.nombre}</Typography>
            </CardContent>
        </CardActionArea>
    </Card>);
}
export default class ProductOptions extends React.Component<Props> {
    state = { selections: [] }
    handleClose = () => {
        const Item = this._Item();
        const maxItems = Item.maxItems || 0;

        if (!this.state.selections.length)
            return this.props.onDone();

        if (maxItems < this.state.selections.length)
            return alert("No se puede continuar selecciona " + maxItems + " items");

        this.props.onDone({
            selections: this.state.selections,
            id: this.props.showingID
        });
    }
    componentWillReceiveProps(nextProps: Props) {
        if (nextProps.showingID === null || nextProps.showingID === undefined) {
            this.setState({ selections: [] });
        }
    }
    // MIENTRAS ESTÉ CERRADO, EL COMPONENTE NO SE ACTUALIZARÁ
    shouldComponentUpdate(nextProps) {
        var isOpen = !(this.props.showingID === null || this.props.showingID === undefined);
        var NextisOpen = !(nextProps.showingID === null || nextProps.showingID === undefined);
        return (isOpen === NextisOpen && isOpen === false) ? false : true;
    }
    toggleSelect = event => {
        const { value } = event.currentTarget;
        const Item = this._Item();
        const maxItems = Item.maxItems || 0;
        this.setState(prev => {
            const state: any = { ...prev };
            state.selections = [...state.selections];
            var existsIndex = state.selections.indexOf(+value);
            if (existsIndex >= 0) {
                state.selections.splice(existsIndex, 1);
            } else {
                state.selections.push(+value);
                // quito el primero de la lista para agregar este!
                if (state.selections.length > maxItems) {
                    state.selections.splice(0, 1);
                }
            }
            return state;
        });
    }
    _Item(): Product {
        return this.props.productList.find(x => x.id === this.props.showingID) || {} as any;
    }
    render() {
        const show = !(this.props.showingID === undefined || this.props.showingID === null);
        const { selections } = this.state;
        const Item = this._Item();
        const opcionIDs = (Item.opciones || []).map(x => x.idOpcion);
        const productList = this.props.productList.filter(x => opcionIDs.indexOf(x.id) >= 0);
        return (<Modal
            title="Crea tu combinación"
            onClose={this.handleClose}
            show={show}
            fullScreen={true}
            closeButtonText={selections.length ? "GUARDAR" : "SALIR"}
        >
            <div style={{ textAlign: "center", marginTop: 10, marginBottom: 10 }}>
                <Typography variant="h6">{Item.nombre}</Typography>
            </div>
            <div style={{ paddingLeft: 10, paddingRight: 10, margin: "auto", width: "calc(100% - 20px)" }}>
                <Grid container spacing={3}>
                    {productList.map(x =>
                        (<Grid key={x.id} item xs={12} sm={4}>
                            <ItemCard
                                {...x}
                                onClick={this.toggleSelect}
                                selected={selections.indexOf(x.id) >= 0}
                            />
                        </Grid>))}
                </Grid>
            </div>
        </Modal>);
    }
}