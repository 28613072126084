import React from "react";
import Input from "../../Input";
import { Grid } from "@material-ui/core";
import LoadingButton from "../../Common/LoadingButton";
import InteractionLog from "./InteractionLog";
import API from "../../../Services/API";

interface CRM_data {
    estadoCivil?: string,
    hijos?: string,
    como_supo_natu?: string,
    profesion?: string,
    problemas_salud?: string,
    problemas_piel?: string,
    info_personal_extra?: string
}
export default function CRMTab(props: {
    id?: React.ReactText
    , userId?: React.ReactText
    , onLoadingStateChanged?(isLoading: boolean): void
        , onSaved?(items: { id: React.ReactText }): void
}) {

    const [crm_data, set_crm_data] = React.useState<CRM_data>({});
    const [crm_status, set_crm_status] = React.useState("INITIALIZING");
    const isLoading = ["INITIALIZING", "SUBMIT"].includes(crm_status);

    React.useEffect(() => {
        if (crm_status !== "INITIALIZING") return;
        if ((props.id || "new") !== "new") {
            const promise = API.GetCRMCustomer(props.id);
            promise.then(x => {
                if (x) set_crm_data(x);
            }).catch(ex => {
                console.error(ex);
                alert(ex.message);
            }).finally(() => set_crm_status(""))
            return () => promise.abort();
        } else {
            set_crm_status("");
        }
    }, [crm_status]);

    React.useEffect(() => {
        if (crm_status !== "SUBMIT") return;
        const payload = { ...crm_data, userId: props.userId, id: props.id };
        const promise = payload.id
            ? API.updateCRMCustomer(payload)
            : API.createCRMCustomer(payload);

        promise.then(result => {
            set_crm_status("SAVED");
            props.onSaved(result);
        }).catch(ex => {
            console.error(ex);
            alert(ex.message);
        }).finally(() => set_crm_status(""))

        return () => promise.abort();
    }, [crm_status]);

    return (<div>
        <form onSubmit={e => {
            e.preventDefault();
            set_crm_status("SUBMIT");
        }}>
            <Grid container>
                <Grid item sm={6} xs={12}>
                    <Input disabled={isLoading} value={crm_data.estadoCivil || ""} name="estadoCivil" onChange={e => set_crm_data({ ...crm_data, [e.target.name]: e.target.value })} label="Estado Civil" />
                </Grid>
                <Grid item sm={6} xs={12}>
                    <Input disabled={isLoading} value={crm_data.hijos || ""} name="hijos" onChange={e => set_crm_data({ ...crm_data, [e.target.name]: e.target.value })} label="Hijos" />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item sm={6} xs={12}>
                    <Input disabled={isLoading} value={crm_data.como_supo_natu || ""} name="como_supo_natu" onChange={e => set_crm_data({ ...crm_data, [e.target.name]: e.target.value })} label="Cómo supo de Natu?" />
                </Grid>
                <Grid item sm={6} xs={12}>
                    <Input disabled={isLoading} value={crm_data.profesion || ""} name="profesion" onChange={e => set_crm_data({ ...crm_data, [e.target.name]: e.target.value })} label="Actividad/Profesión" />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item sm={6} xs={12}>
                    <Input disabled={isLoading} value={crm_data.problemas_salud || ""} name="problemas_salud" onChange={e => set_crm_data({ ...crm_data, [e.target.name]: e.target.value })} label="Problemas de salud" />
                </Grid>
                <Grid item sm={6} xs={12}>
                    <Input disabled={isLoading} value={crm_data.problemas_piel || ""} name="problemas_piel" onChange={e => set_crm_data({ ...crm_data, [e.target.name]: e.target.value })} label="Problemas de piel" />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <Input fullWidth multiline minRows={2} disabled={isLoading} value={crm_data.info_personal_extra || ""} name="info_personal_extra" onChange={e => set_crm_data({ ...crm_data, [e.target.name]: e.target.value })} label="Información personal adicional" />
                </Grid>
            </Grid>
            <div style={{ textAlign: "right", margin: 10 }}>
                <LoadingButton isLoading={isLoading} variant="outlined" color="primary" type="submit">
                    {crm_status === "SAVED" ? "Actualizado!" : "Guardar"}
                </LoadingButton>
            </div>
        </form>
        {props.id ? <InteractionLog crmId={props.id} /> : null}
    </div>);
}