import React from "react";
import { Route, useRouteMatch, useHistory } from "react-router-dom";
import API from "../Services/API";
import DataTable from "./DataTable";
import { Typography, Tab, Tabs, CircularProgress } from "@material-ui/core";
import EditCustomer from "./Customers/EditCustomer";
import EditUser from "./Users/Edit";


const customBodyRender = function (value) {
    if (!value) return null;
    return new Date(value).toLocaleString("es-EC", {
        day: "numeric", month: "short", year: "numeric"
    });
}

export default function GlobalFollowUp(props: {
    onMounted?(selected?: string): void
}) {

    React.useEffect(() => {
        props.onMounted && props.onMounted(root_match.path);
    }, []);

    const root_match = useRouteMatch();
    const history = useHistory();
    const [pendientes, set_pendientes] = React.useState([]);
    const [contactados, set_contactados] = React.useState([]);
    const [hbds, set_hbds] = React.useState([]);
    const [status, set_status] = React.useState("LOADING");
    const [tabId, set_tabId] = React.useState("pendientes");

    React.useEffect(() => {
        if (status !== "LOADING") return;
        const promise = API.GlobalFollowUpReport();
        promise.then(data => {
            const _pendientes = [];
            const _contactados = [];
            const _hbds = [];
            data.forEach(item => {
                if (item.birthdayDaysLeft) {
                    _hbds.push(item);
                    return;
                }
                if (item.diasRetraso > 0) {
                    _pendientes.push(item);
                    return;
                }
                _contactados.push(item)
            });
            set_pendientes(_pendientes.sort((a, b) => b.diasRetraso - a.diasRetraso));
            set_contactados(_contactados);
            set_hbds(_hbds.sort((a, b) => a.birthdayDaysLeft - b.birthdayDaysLeft));
        }).catch(ex => {
            console.error(ex);
            alert(ex.message)
        }).finally(() => {
            set_status("")
        });

        return () => promise.abort();
    }, [status]);

    const displayedItems = tabId === "pendientes" ? pendientes : contactados;

    const handleClose = () => {
        history.push(root_match.path);
        set_status("LOADING");
    };

    const handleRowClick = arr_data => {
        if (!arr_data) return;
        var [id, tipo] = arr_data;
        if (!(id && tipo)) return;
        if (tipo.toLocaleLowerCase().trim() === "usuario") {
            return history.push(`${root_match.path}/user/${id}`);
        }
        if (tipo.toLocaleLowerCase().trim() === "cliente") {
            return history.push(`${root_match.path}/customer/${id}`);
        }
        alert("No se encontró el tipo " + tipo);
    }

    const isLoading = status === "LOADING";

    const birthday_list = (<DataTable
        title={"Lista de siguientes cumpleaños"}
        columns={[
            {
                name: "id"
                , label: "ID"
            }, {
                name: "type"
                , label: "Tipo"
                , options: {
                    customBodyRender(value) {
                        if (value === "user") return "Usuario";
                        if (value === "customer") return "Cliente";
                        return value;
                    }
                }
            }, {
                name: "nombre"
                , label: "Nombre"
            }, {
                name: "telefono"
                , label: "Tel"
            }, {
                name: "email"
                , label: "Correo"
            }, {
                name: "Vendedor"
                , label: "Asesora"
            }, {
                name: "birthDay"
                , label: "Cumpleaños"
                , options: {
                    customBodyRender(value, _) {
                        if (!value) return null;
                        const strDate = new Date(value).toLocaleString("es-EC", {
                            day: "numeric"
                            , month: "short"
                        })
                        return strDate;
                        /*
                        const { birthdayDaysLeft } = hbds[meta.rowIndex] as { [key: string]: any };
                        if (birthdayDaysLeft === 0) return strDate + " (hoy)";
                        if (birthdayDaysLeft === 1) return strDate + " (mañana)";
                        if (birthdayDaysLeft > 1) return strDate + " (" + birthdayDaysLeft + " días)";

                        if (birthdayDaysLeft === -1) return strDate + " (ayer)";
                        if (birthdayDaysLeft < -1) return strDate + " (hace " + Math.abs(birthdayDaysLeft) + " días)";
                        if (birthdayDaysLeft < 1) return strDate + " (hace " + Math.abs(birthdayDaysLeft) + " días)";*/
                    }
                }
            }
        ]}
        options={{
            selectableRows: "none"
            , onRowClick: handleRowClick
            , textLabels: {
                body: {
                    noMatch: isLoading ? "Buscando Registros 🔎 .... " : "No hay registros 🤷‍♀️"
                }
            }
        }}
        data={hbds}
    />);

    const content = (
        <DataTable
            title={""}
            options={{
                selectableRows: "none",
                onRowClick: handleRowClick
            }}
            columns={[
                {
                    name: "id"
                    , label: "ID"
                }, {
                    name: "type"
                    , label: "Tipo"
                    , options: {
                        customBodyRender(value) {
                            if (value === "user") return "Usuario";
                            if (value === "customer") return "Cliente";
                            return value;
                        }
                    }
                }, {
                    name: "nombre"
                    , label: "Nombre"
                }, {
                    name: "telefono"
                    , label: "Tel"
                }, {
                    name: "email"
                    , label: "Correo"
                }, {
                    name: "Vendedor"
                    , label: "Vendedor"
                }, {
                    name: "ultimaLlamada"
                    , label: "Ultima Llamada"
                    , options: {
                        customBodyRender
                    }
                }, {
                    name: "fechaSiguienteLlamada"
                    , label: "Siguiente Llamada"
                    , options: {
                        customBodyRender
                    }
                }, {
                    name: "diasRetraso"
                    , label: "Retraso"
                    , options: {
                        customBodyRender(value) {
                            return value > 0
                                ? <Typography color="error" variant="caption" >{value} días</Typography>
                                : null;
                        }
                    }
                }
            ]}
            data={displayedItems}
        />);

    return (<div>
        {isLoading
            ? null
            : birthday_list}
        <Tabs value={tabId} onChange={(_, tabId) => set_tabId(tabId)} style={{ marginTop: 10 }} >
            <Tab value="pendientes" label="Pendientes" />
            <Tab value="contactados" label="Contactados" />
        </Tabs>
        {isLoading
            ? (<div style={{ textAlign: "center", margin: "auto" }}>
                <CircularProgress size={100} />
            </div>)
            : content}
        <Route
            path={root_match.path + "/user/:id"}
            children={({ match }) => <EditUser
                show={!!match?.params?.id}
                grupos={[]}
                defaultActiveTab="crm"
                id={(match?.params?.id || "NUEVO") === "NUEVO" ? undefined : match?.params?.id}
                onClose={handleClose}
            />}
        />

        <Route
            path={root_match.path + "/customer/:id"}
            children={({ match }) => <EditCustomer
                open={match?.params?.id ? true : false}
                id={(match?.params?.id || "new") === "new" ? null : match?.params?.id}
                onClose={handleClose}
            />}
        />
    </div>);
}