import React from "react";
import { CardContent, Card, List, ListItem, Typography } from "@material-ui/core";
import LeftRigthHeader from "./LeftRightHeader";
export default function RankingCard(props: RankProps) {
    const {
        data,
        col1,
        col2,
        secondColType,
        colType,
        ...remainProps
    } = props;

    return (<Card className="dashboard-card" style={{ display: "flex", flexDirection: "column" }} >
        <LeftRigthHeader {...remainProps} />
        <CardContent style={{ flex: 1, overflow: "auto" }}>
            <List>
                {data.sort((a, b) => b[col1] - a[col1])
                    .map((r, index) => {
                        var value1 = r[col1];
                        if (col2) {
                            var value2 = r[col2];
                            if (secondColType && secondColType === "DATE") {
                                value2 = DateToString(r[col2]);
                            } else {
                                value2 = value2.toFixed(2);
                            }
                        }

                        return (<ListItem key={index} id={"ranking-listitem-" + index} >
                            <div style={{ display: "table", width: "100%" }} >

                                <Typography style={{ display: "table-cell", width: 50 }} variant={r.me ? "body1" : "caption"}># {index + 1}</Typography>
                                <Typography style={{ display: "table-cell" }} variant={r.me ? "body1" : "caption"} >
                                    {r.username}
                                </Typography>

                                {colType === "PERFOMANCE" && <PerfomanceCell value={value1} me={r.me} />}

                                {colType === "MONEY" && <Typography style={{ display: "table-cell", textAlign: "right", width: 100 }} variant={r.me ? "body1" : "caption"}>
                                    $ {value1.toFixed(2)}
                                </Typography>}

                                {colType === "NUMBER" && <Typography style={{ display: "table-cell", textAlign: "right", width: 100 }} variant={r.me ? "body1" : "caption"}>
                                    {value1}
                                </Typography>}

                                {col2
                                    ? (secondColType === "PERFOMANCE"
                                        ? <PerfomanceCell value={+value2} me={r.me} />
                                        : <Typography style={{ display: "table-cell", textAlign: "right", width: 100, paddingLeft: 10 }} variant={r.me ? "body1" : "caption"}>
                                            {secondColType === "MONEY" ? "$" : null} {value2}
                                        </Typography>)
                                    : null
                                }
                            </div>
                        </ListItem>)
                    })}
            </List>
        </CardContent>
    </Card>)
}
function PerfomanceCell(props: { value: any, me: boolean }) {
    return <Typography
        color={props.value ? (props.value > 0 ? "primary" : "secondary") : "inherit"}
        style={{ display: "table-cell", textAlign: "right", width: 100 }}
        variant={props.me ? "body1" : "caption"}
    >
        % {props.value.toFixed(2)} {props.value ? (props.value > 0 ? "↑" : "↓") : null}
    </Typography>
}

function DateToString(x) {
    if (!x) return "Nunca";
    var date = new Date(x);
    if (isNaN(+date)) return x;
    var ahora = new Date();
    if (date.getDate() === ahora.getDate() &&
        date.getMonth() === ahora.getMonth() &&
        date.getFullYear() === ahora.getFullYear()) {
        return date.toLocaleTimeString();
    }

    for (let i = 1; i < 30; i++) {
        var este_dia = new Date(ahora.getFullYear(), ahora.getMonth(), ahora.getDate() - i);
        if (date.getDate() === este_dia.getDate() &&
            date.getMonth() === este_dia.getMonth() &&
            date.getFullYear() === este_dia.getFullYear()) {
            if (i === 1) return "Ayer";
            return "Hace " + i + " días";
        }
    }
    return date.toLocaleDateString();
}


export interface RankProps {
    data: Rank[];
    title: string;
    col1: string;
    col2?: string;
    colType: "MONEY" | "PERFOMANCE" | "NUMBER";
    secondColType?: "MONEY" | "DATE" | "PERFOMANCE";

    subtitle?: string;
    onBackClick?: any;
    onNextClick?: any;
    loading?: boolean;
}