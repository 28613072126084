import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../../../Services/API";


const GetProductsThunk = createAsyncThunk(
    "Order/FetchData"
    , function () {
        return Promise.all([API.GetProducts({ tipo_catalogo: "regular" }), API.GetGiftRules()]);
    }
)

export default GetProductsThunk;