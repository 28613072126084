import React from "react";
import { match } from "react-router-dom";
import TabContainer from "../../Layouts/TabLayout";
import GiftRules from "./GiftRules";

const TABS = [
    { tabId: "gift-rules", Component: GiftRules, tabLabel: "Reglas de Regalos" }
]
export default function (props: {
    onMounted(path: string): void;
    match: match
}) {
    return (<TabContainer
        match={props.match}
        onMounted={props.onMounted}
        tabs={TABS}
    />);
}