import React from "react";
import { Hidden, Drawer, IconButton, AppBar, Toolbar, Typography, List, ListItem, ListItemIcon, ListItemText, makeStyles } from "@material-ui/core";
import { ShoppingCart, ListAlt, Menu, Close, Help, Inbox, Report, Category, Policy, PeopleAlt, AssignmentInd, Person, AccountTree, Redeem, Contacts } from "@material-ui/icons";
import SettingsIcon from "@material-ui/icons/Settings";
import { Link } from "react-router-dom";

const OPCIONES = [
    { label: "Inicio", Icon: Inbox, path: "/feed" }
    /** NINGUN PATH puede comenzar por order porque dana algunas partes de la logica */
    , { label: "Nuevo Pedido", Icon: ShoppingCart, path: "/order" }

    , { label: "Pedidos", Icon: ListAlt, path: "/my-orders" }
    , { label: "Pedidos", Icon: ListAlt, path: "/queue" }

    , { label: "Canjea tus Premios", Icon: Redeem, path: "/user-points" }
    , { label: "Mis Documentos", Icon: Policy, path: "/docs" }

    , { label: "Catálogo Regular", Icon: Category, path: "/products" }
    , { label: "Catálogo de Premios", Icon: Category, path: "/products-by-points" }
    , { label: "Usuarios", Icon: PeopleAlt, path: "/users" }
    , { label: "Clientes", Icon: Contacts, path: "/my-customers" }
    , { label: "Clientes", Icon: Contacts, path: "/customers" }
    , { label: "Contactos", Icon: AssignmentInd, path: "/global-follow-up" }

    , { label: "Reportes", Icon: Report, path: "/reports" }
    , { label: "Ciclos", Icon: AccountTree, path: "/multi-level-cycle" }
    , { label: "Perfil", Icon: Person, path: "/my-profile" }
    , { label: "Configuración", Icon: SettingsIcon, path: "/settings" }
];

const drawerWidth = 200;

const useStyles = makeStyles(theme => ({
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0
        }
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
        background: theme.palette.primary.light,
        color: theme.palette.primary.contrastText,
    },
    appBar: {
        display: "block"
    }, ListItem: {
        "&.Mui-selected,&.Mui-selected:hover": {
            backgroundColor: theme.palette.primary.contrastText,
            color: theme.palette.primary.main
        }
    }
    , drawerChildren: {
        display: "flex"
        , flexDirection: "column"
    }
    , drawerChildrenHeader: {
        textAlign: "center"
        , marginTop: 10
        , marginBottom: 10
    }
}));

export default function (props: {
    username: string,
    ParentName: string,
    Rol?: string;
    rol_simbolo: string,
    selectedPath: string,
    access_list: { nombre: string, displayName: string }[],
    window?: any
}) {

    const container = props.window ? props.window().document.body : undefined;

    const { username, ParentName, Rol, rol_simbolo } = props;

    const selected = props.selectedPath;
    const [isMenuOpen, set_isMenuOpen] = React.useState(false);

    const classes = useStyles();

    const accesos = OPCIONES.filter(x => PathIsSecure(props.access_list, x.path));

    const drawerChildren = (<div className={classes.drawerChildren}>
        <div className={classes.drawerChildrenHeader}>
            <Typography style={{ display: "block" }} variant="button">{username}</Typography>
            <Typography style={{ display: "block" }} variant="caption">PATROCINADOR:</Typography>
            <Typography style={{ display: "block" }} variant="caption">{ParentName}</Typography>
            <Typography style={{ display: "block" }} variant="button">{rol_simbolo} {Rol}</Typography>
        </div>
        <List style={{ flex: 1 }}>
            {accesos.map(o => (<ListItem className={classes.ListItem} onClick={() => set_isMenuOpen(false)} selected={selected === o.path} component={Link} to={o.path} button key={o.label}>
                <ListItemIcon style={{ color: "inherit" }} >{<o.Icon color="inherit" />}</ListItemIcon>
                <ListItemText color="inherit" primaryTypographyProps={{
                    variant: "button",
                    style: {
                        fontWeight: selected === o.path ? "bolder" : "normal"
                    }
                }} primary={o.label} />
            </ListItem>))}
        </List>
        <ListItem className="a-link" component="a" target="_a" href="https://wa.me/" >
            <ListItemIcon style={{ color: "inherit" }} > <Help color="inherit" /> </ListItemIcon>
            <ListItemText color="inherit" primaryTypographyProps={{ variant: "button" }} primary="AYUDA" />
        </ListItem>
    </div >);

    return (<>
        <nav className={classes.drawer}>
            <Hidden smUp implementation="css" >
                <Drawer
                    container={container}
                    variant="temporary"
                    anchor="left"
                    open={isMenuOpen}
                    onClose={() => set_isMenuOpen(false)}
                    classes={{ paper: classes.drawerPaper }}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                >
                    <div className={classes.toolbar} style={{ textAlign: "right" }}>
                        <IconButton onClick={() => set_isMenuOpen(false)} color="inherit">
                            <Close color="inherit" />
                        </IconButton>
                    </div>
                    {drawerChildren}
                </Drawer>
            </Hidden>
            <Hidden xsDown implementation="css">
                <Drawer
                    classes={{ paper: classes.drawerPaper }}
                    variant="permanent"
                    open
                >
                    {drawerChildren}
                </Drawer>
            </Hidden>
        </nav>

        <Hidden smUp implementation="css" >
            <AppBar position="fixed">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={() => set_isMenuOpen(true)}
                    >
                        <Menu />
                    </IconButton>
                    <Typography style={{ flexGrow: 1 }}>{(OPCIONES.find(x => x.path === selected) || {}).label}</Typography>
                </Toolbar>
            </AppBar>
        </Hidden>
    </>)
}

export function PathIsSecure(access_list: { nombre: string }[], path: string) {
    const fullPath = path.split("/");
    if (fullPath.length >= 2 && access_list.find(item => item.nombre === "screens." + fullPath[1])) {
        return true;
    }
}