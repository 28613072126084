import React from "react";
import { Tabs, Tab, Typography, CircularProgress, makeStyles } from "@material-ui/core";
import API from "../../Services/API";

type TabName = "natu-rules" | "privacy-policy" | "terms-and-cods";

const useStyles = makeStyles(theme => ({
    root: {
        height: "calc(100% - 20px)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        overflow: "hidden"
    },
    loadingRoot: {
        justifyContent: "center"
    }
    , pdfMinWidth: {
        [theme.breakpoints.down("xs")]: {
            minWidth: "100vw !important"
        }
    }
    , flexContainer: {
        height: "100%"
    }
    , tabText: {
        [theme.breakpoints.down("xs")]: {
            lineHeight: 1
        }
    }
}));

export default function (props: {
    match?: { path: string },
    onMounted?(path: string): void,
    defaultTab?: TabName
}) {


    const [settings, set_settings] = React.useState<any>({});
    const [error, set_error] = React.useState(null);
    const [state, set_state] = React.useState<"LOADING" | "ERROR" | "">("LOADING");
    React.useEffect(() => {
        props.onMounted && props.match && props.onMounted(props.match.path);
    }, []);

    React.useEffect(() => {
        if (state === "LOADING")
            API.GetSettings().then(settings => {
                set_settings(settings);
                set_state("");
            }).catch(ex => {
                set_error(ex.message);
                set_state("ERROR");
                console.error(ex);
            })
    }, [state]);

    const [currentTab, set_currentTab] = React.useState<TabName>(props.defaultTab || "natu-rules");

    const classes = useStyles();
    if (state === "LOADING" || state === "ERROR") return (<div className={[classes.root, classes.loadingRoot].join(" ")}>
        <div style={{ flex: 1, textAlign: "center", paddingTop: "20vh" }} >
            {state === "LOADING"
                ? <>
                    <CircularProgress color="primary" size={50} />
                    <Typography variant="h3">Cargando</Typography>
                </>
                : null}
            {state === "ERROR"
                ? <>
                    <Typography color="error" variant="h6">Ocurrió un error</Typography>
                    <Typography color="error" variant="body2">{error}</Typography>
                </>
                : null}
        </div>
    </div>)

    return (<div className={classes.root}>
        <Tabs classes={{
            flexContainer: classes.flexContainer
        }} variant="fullWidth" onChange={(_, currentTab) => set_currentTab(currentTab)} value={currentTab}>
            <Tab classes={{ root: classes.tabText }} value="natu-rules" label="Plan de Bienestar y Prosperidad" />
            <Tab classes={{ root: classes.tabText }} value="privacy-policy" label="Políticas de Privacidad" />
            <Tab classes={{ root: classes.tabText }} value="terms-and-cods" label="Términos y condiciones" />
        </Tabs>
        <PDFViewer src={settings.planNatuSrc} hidden={currentTab !== "natu-rules"} />
        <PDFViewer src={settings.politicasPrivacidadSrc} hidden={currentTab !== "privacy-policy"} />
        <PDFViewer src={settings.terminosYCondicionesSrc} hidden={currentTab !== "terms-and-cods"} />
    </div>);
}

export function PDFViewer(props: { src: string, hidden: boolean, title?: string }) {
    const classes = useStyles();
    return (<div id="Iframe-Liason-Sheet" className={[classes.pdfMinWidth, "center-block-horiz pdf-frame"].join(" ")} style={props.hidden ? { display: "none" } : undefined}>
        <div className="responsive-wrapper responsive-wrapper-wxh-760x1200">
            <iframe
                title={props.title}
                src={props.src}
            >
            </iframe>
        </div>
    </div>);
}