import React from "react";
import { List, ListItem, ListItemText, ListItemSecondaryAction, Typography, makeStyles } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";


interface ComisionLista {
    usuarios: number,
    activos: number,
    comisionable: number,
    comision: number
};

const useStyles = makeStyles({
    ErrorIcon: {
        margin: "auto"
    },
    ErroMessage: {
        paddingLeft: "1rem"
    },
    ErrorAlert: {
        textAlign: "left",
        margin: 10
    }
})

export default function (props: MultiLevelUserDataWithCalcs) {

    const Niveles = React.useMemo(() => {
        const niveles = [] as ComisionLista[];
        if (props.n1_usuarios) {
            niveles.push({
                usuarios: props.n1_usuarios,
                activos: props.n1_activos,
                comisionable: props.n1_comisionable,
                comision: props.n1_subtotal_comision
            });
        }

        if (!(props.n2_usuarios)) return niveles;
        niveles.push({
            usuarios: props.n2_usuarios,
            activos: props.n2_activos,
            comisionable: props.n2_comisionable,
            comision: props.n2_subtotal_comision
        });

        if (!(props.n3_usuarios)) return niveles;
        niveles.push({
            usuarios: props.n3_usuarios,
            activos: props.n3_activos,
            comisionable: props.n3_comisionable,
            comision: props.n3_subtotal_comision
        });

        return niveles;
    }, Object.values(props));


    const { nX_activos, nX_usuarios } = Niveles.reduce((a, b) => {
        a.nX_activos += b.activos;
        a.nX_usuarios += b.usuarios;
        return a;
    }, { nX_activos: 0, nX_usuarios: 0 });

    const classes = useStyles();
    return (
        <List>
            <ListItem dense>
                <ListItemText
                    primaryTypographyProps={{ variant: "button" }}
                    primary="Venta Comisionable"
                />
                <ListItemSecondaryAction>
                    <Typography variant="button">
                        Comisión
                    </Typography>
                </ListItemSecondaryAction>
            </ListItem>
            <ListItem dense>
                <ListItemText
                    primary="Venta Personal"
                    secondary={`Valor Calificable: ${props.total_calificable.toCurrency()}`}
                />
                <ListItemSecondaryAction></ListItemSecondaryAction>
            </ListItem>
            {Niveles.map((n, index) => (<ListItem dense key={index}>
                <ListItemText
                    primary={`Nivel ${index + 1} (${n.activos} / ${n.usuarios})`}
                    secondary={`Valor Comisionable: ${n.comisionable.toCurrency()}`}
                />
                <ListItemSecondaryAction>{n.comision.toCurrency()}</ListItemSecondaryAction>
            </ListItem>))}
            <ListItem dense style={{ borderTop: "1px solid #eaeaea" }} >
                <ListItemText
                    primaryTypographyProps={{ variant: "button" }}
                    primary="Total"
                    secondary={`${nX_activos} / ${nX_usuarios}`}
                />
                <ListItemSecondaryAction>
                    <Typography variant="button" style={{ textDecoration: props.simular_regla_titulo ? "line-through" : "initial" }} >
                        {props.subtotal_comision.toCurrency()} + imp
                    </Typography>
                </ListItemSecondaryAction>
            </ListItem>
            {props.simular_regla_titulo
                ? (<Alert className={classes.ErrorAlert} severity="error" classes={{
                    icon: classes.ErrorIcon
                    , message: classes.ErroMessage
                }}
                >
                    Para comisionar debes tener una <strong>Venta Personal</strong> en el ciclo de <strong>$120,00</strong> al PVP
                </Alert>)
                : null}
        </List>);
}