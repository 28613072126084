import React from "react";
import { Container, Typography, Box, makeStyles } from "@material-ui/core"
import { BugReport } from "@material-ui/icons"

const useStyles = makeStyles({
    center: {
        textAlign: "center"
        , paddingTop: "5vh"
    }
});

export default function ErrorPage(props: Error & { code?: string }) {
    const classes = useStyles();
    return (<Container className={classes.center}>
        <BugReport color="error" fontSize="large" />
        <Typography color="error" variant="h4" >Ups. algo salió mal</Typography>
        <Typography color="error" variant="body2">{props.message}</Typography>
        <Typography color="error" variant="caption">{props.name}</Typography>
        <Typography color="error" variant="caption">{props.code}</Typography>
    </Container>);
}