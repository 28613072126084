
// Create our number formatter.
const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

Number.prototype.toCurrency = function () {
    let numberToFormat: number = this;
    if (numberToFormat.moneyRound() == 0) {
        numberToFormat = 0;
    }
    return formatter.format(numberToFormat); /* $2,500.00 */
}

Number.prototype.moneyRound = function (decimal_digits: number = 2) {
    var negative = false;
    var n = this;
    var digits = decimal_digits;
    if (digits === undefined) {
        digits = 0;
    }
    if (n < 0) {
        negative = true;
        n = n * -1;
    }
    var multiplicator = Math.pow(10, digits);
    n = parseFloat((n * multiplicator).toFixed(11));
    n = (Math.round(n) / multiplicator).toFixed(digits);
    if (negative) {
        n = (n * -1).toFixed(digits);
    }
    return +n;
}
export { };