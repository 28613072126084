import React from "react";
import { CardHeader, IconButton, Typography } from "@material-ui/core";
import { HourglassEmpty, ArrowLeft, ArrowRight } from "@material-ui/icons";


interface Props {
    onBackClick?: (any) => void,
    onNextClick?: (any) => void,
    loading?: boolean,
    title?: string,
    subtitle?: string
}

export default function LeftRigthHeader(props: Props) {
    const { onBackClick, loading, onNextClick, title, subtitle } = props;
    return (
        <CardHeader
            title={<div style={{ flex: 1, display: "flex", flexDirection: "row" }}>
                {onBackClick && <div><IconButton onClick={onBackClick} disabled={loading}>
                    {loading ? <HourglassEmpty /> : <ArrowLeft />}
                </IconButton></div>}
                <div style={{ flex: 1 }}>
                    <Typography variant="h5">{title}</Typography>
                    <Typography variant="body1">{subtitle}</Typography>
                </div>
                {onNextClick && <div><IconButton onClick={onNextClick} disabled={loading}>
                    {loading ? <HourglassEmpty /> : <ArrowRight />}
                </IconButton></div>}
            </div>}
            disableTypography={true}
            style={{ textAlign: "center" }}
        />);
}