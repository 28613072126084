import React from "react";
import { Button, TextField } from "@material-ui/core";
import * as dateformat from "date-format";
import API from "../../Services/API";

export default function () {

    const aRef = React.createRef<HTMLAnchorElement>();
    const [a_ref_ID] = React.useState(new Date().valueOf());

    const [desde, set_desde] = React.useState(
        dateformat("yyyy-MM-dd", new Date(new Date().getFullYear(), new Date().getMonth(), 1))
    );
    const [hasta, set_hasta] = React.useState(
        dateformat("yyyy-MM-dd", new Date(new Date().getFullYear(), new Date().getMonth() + 1, -1))
    );
    const [estado, set_estado] = React.useState("0");

    const validationError = function () {
        if (!(from && from.toJSON())) return "Digite una fecha `Desde` valida ";
        if (!(to && to.toJSON())) return "Digite una fecha `hasta` valida";
        if (estado === null || estado === undefined) return "Seleccione el estado";
    }

    try {
        var from = dateformat.parse("yyyy-MM-dd hh:mm:ss.SSS", desde + " 00:00:00.000");
        var to = dateformat.parse("yyyy-MM-dd hh:mm:ss.SSS", hasta + " 00:00:00.000");
    } catch (ex) {
        console.error(ex.message, { desde, hasta });
    }

    return (<div style={{ paddingTop: 10, paddingBottom: 20 }}>
        <TextField style={{ marginLeft: 10, marginRight: 10 }} type="date" onChange={ev => set_desde(ev.currentTarget.value as any)} value={desde} />
        <TextField style={{ marginLeft: 10, marginRight: 10 }} type="date" onChange={ev => set_hasta(ev.currentTarget.value as any)} value={hasta} />
        <TextField style={{ marginLeft: 10, marginRight: 10 }} select onChange={ev => set_estado(ev.currentTarget.value as any)} value={estado}
            SelectProps={{
                native: true
            }}
        >
            <option value="0">APROBADO</option>
            <option value="3">ENVIADO</option>
            <option value="5">FACTURADO</option>
        </TextField>
        <Button style={{ marginLeft: 10, marginRight: 10 }} variant="outlined" onClick={() => {
            const err = validationError();
            if (err) return alert(err);
            aRef.current.href = `${API.ServerURL()}/reports/items-invoice-report.xlsx?from=${from.toJSON()}&to=${to.toJSON()}&estado=${estado}`;
            aRef.current.click();
        }} >
            DESCARGAR PRODUCTOS
        </Button>

        <Button style={{ marginLeft: 10, marginRight: 10 }} variant="outlined" onClick={() => {
            const err = validationError();
            if (err) return alert(err);
            aRef.current.href = `${API.ServerURL()}/reports/payments-invoice-report.xlsx?from=${from.toJSON()}&to=${to.toJSON()}&estado=${estado}`;
            aRef.current.click();
        }} >
            DESCARGAR PAGOS
        </Button>
        <a style={{ display: "none" }} ref={aRef} target="_blank" download id={"a-download-no-" + a_ref_ID} ></a>
    </div>)
}