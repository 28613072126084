/// <reference path="../shared/Order.DoAction.d.ts" />
/// <reference path="../shared/GiftRule.d.ts" />
/// <reference path="../shared/app.d.ts" />

import { OrderStateCustomerData } from "../Components/Order/lib/OrderState";

const DOMAIN = (process.env.NODE_ENV === "development"
    ? (window.location.port ? "http://localhost:9000" : "")
    : "/tunnel.php");

class API {
    KEY = "";
    Login(body) { return this.F("user/login", { body, method: "POST" }); }
    GetProducts(options: { todos?: boolean, tipo_catalogo: "premios" | "regular" }): AbortablePromise<FullProduct[]> {
        const query = new URLSearchParams();
        if (options.todos) query.set("todos", "true");
        if (options.tipo_catalogo) query.set("tipo_catalogo", options.tipo_catalogo);
        return this.F("product?" + query.toString());
    }
    SendProductAction(id: number, action: string) { return this.F("product/" + id, { body: { action }, method: "PATCH" }); }
    GetSettings() { return this.F("settings"); }
    GetOrders(): AbortablePromise<GetOrder[]> { return this.F("order"); }
    GetOrder(id: number): AbortablePromise<GetOrder> { return this.F("order/" + id); }
    GetOrderInvoiceUrl(id: number): AbortablePromise<{ ride: string, xml: string, total: number | string }> { return this.F("order/" + id + "/get_contifico_invoice_urls"); }
    SaveOrder(body: CreateOrder) { return this.F("order", { method: "POST", body }); }
    ActionOrder(id: number | string, body: DoOrderAction) { return this.F("order/" + id, { method: "PUT", body }); }
    GetFeed(options?: {
        stats: Array<keyof DashboardResult>,
        semana?: number;
        year?: number;
        cycleIndex?: number;
    }): AbortablePromise<DashboardResult> {
        if (options) {
            var params = new URLSearchParams();
            params.set("data", options.stats.join(","));
            if (options.semana) params.set("semana", options.semana.toString());
            if (options.year) params.set("year", options.year.toString());
            if (!isNaN(+options.cycleIndex)) params.set("cycleIndex", options.cycleIndex.toString());
        }
        return this.F("dashboard?" + (params?.toString() || ""));
    }
    ProductSalesReport(params: { desde: string, hasta: string, estado: any }) {
        var param_str = "";
        if (params && Object.keys(params).length)
            param_str = "?" + new URLSearchParams(params).toString();

        return this.F("reports/product-sales" + param_str);
    }
    SignIn(body: any) { return this.F("user", { method: "POST", body }); }

    GetUsers(columns?: Array<keyof VisibleUser>): AbortablePromise<VisibleUser[]> {
        const params = new URLSearchParams();
        if (columns && columns.length) {
            params.set("cols", columns.join(","));
        }
        return this.F("user" + (params.toString() ? ("?" + params.toString()) : ""));
    }
    GetParents() { return this.F("user/parents"); }
    GetRoles() { return this.F("user/user_roles"); }
    GetUser(userId: number): AbortablePromise<VisibleUser> { return this.F("user/" + userId) }
    GetUserPoints(userId: number): AbortablePromise<{}> { return this.F(`points/${userId}`) }
    GetUserPointsBalance(userId: number): AbortablePromise<{}> { return this.F(`points/${userId}/balance`) }
    addCustomPoints(body: { puntos: number, accion: string, userId: number, observacion?: string }) {
        return this.F(`points/${body.userId}`, {
            method: "POST",
            body
        })
    }
    RedeemPoints(options: {
        productList: { id: number, cantidad: number }[]
        , pedido?: OrderStateCustomerData & { envio: number }
        , userId: number
    }) {
        const { userId, ...body } = options;
        return this.F(`points/${userId}/Redeem`, {
            method: "POST"
            , body
        });
    }
    SaveUser(user: VisibleUser) {
        return this.F("user" + (user.id ? `/${user.id}` : ""), {
            method: user.id ? "PUT" : "POST",
            body: user
        });
    }
    DeleteUser(id: number) {
        return this.F("user/" + id, {
            method: "DELETE"
        });
    }
    SendUserAction(action: "ACCEPT_TERMS_AND_CONDS", id: any = null) {
        return this.F("user" + (id === null ? "" : `/${id}`), { method: "PATCH", body: { action } });
    }

    SaveProduct(item: FullProduct) {
        return this.F("product" + (item.id ? ("/" + item.id) : ""), {
            method: item.id ? "PUT" : "POST",
            body: item
        });
    }
    DeleteProduct(id: number) {
        return this.F("product/" + id, { method: "DELETE" });
    }
    AddStock(options: {
        id: number
        , tipo_catalogo: "premios"
        , cantidad: number
    }) {
        const { id, ...body } = options;
        return this.F("product/" + id + "/stock", { method: "POST", body });
    }
    ServerURL() {
        return DOMAIN;
    }
    GetGiftRules(get_all: boolean = false): AbortablePromise<GiftRule[]> {
        return this.F("gift-rules?" + (get_all ? "all=1" : ""), {
            method: "GET"
        });
    }
    SaveGiftRule(body: GiftRule): AbortablePromise<{ createdAt?: Date, id: number }> {
        return this.F("gift-rules/", {
            method: "POST",
            body
        });
    }
    DeleteGiftRule(id?: number): AbortablePromise<void> {
        return this.F(`gift-rules/${id}`, {
            method: "DELETE"
        });
    }
    GetCycles() {
        return this.F("cycles/", {
            method: "GET"
        });
    }


    GetComissionPayments() {
        return this.F("cycles/payments", {
            method: "GET"
        });
    }
    PayComission(id: number, valor: number, imgFile: JsonFile) {
        return this.F("cycles/payments", {
            method: "POST",
            body: { id, valor, imgFile }
        });
    }
    DeletePaymentComission(id: number) {
        return this.F("cycles/payments/" + id, {
            method: "DELETE"
        });
    }
    GetPendingComissions(userId: number, valor_maximo?: number): AbortablePromise<Comission[]> {
        return this.F(`cycles/pending-comissions?userId=${userId}${isNullOrUndefined(valor_maximo) ? "" : `&monto_hasta=${valor_maximo.moneyRound()}`}`, {
            method: "GET"
        });
    }
    GetMultiLevelUserData(cycle: Cycle) {
        return this.F("cycles/data?cycleIndex=" + cycle.index, {
            method: "GET"
        });
    }
    processMultiLevelUserData(cycleIndex: number) {
        return this.F("cycles/data", {
            method: "POST",
            body: { cycleIndex }
        });
    }
    Rangos(): AbortablePromise<Array<{ id: string, nombre: string }>> {
        return Promise.resolve([
            { id: "A", nombre: "Asesor/a" },
            { id: "LN", nombre: "Asesor/a" },
            { id: "LC", nombre: "Asesor/a" },
            { id: "LL", nombre: "Asesor/a" },
            { id: "LA", nombre: "Asesor/a" },
            { id: "GT", nombre: "Embajador/a Natú" },
            { id: "GGT", nombre: "Embajador/a Natú" }
        ]);
    }
    GetCRMCustomer(customerId: string | number) {
        return this.F("crm/customer/" + customerId);
    }
    GetCRMCustomers(cols: string[] = []) {
        var url = new URLSearchParams();
        if (cols?.length) {
            url.set("cols", cols.join(","));
        }
        return this.F("crm/customer?" + url.toString());
    }
    createCRMCustomer(body: any) {
        return this.F("crm/customer", { method: "POST", body })
    }
    updateCRMCustomer(body: any) {
        return this.F("crm/customer/" + body.id, { method: "PUT", body });
    }
    deleteCRMCustomer(customerId: string | number) {
        return this.F("crm/customer/" + customerId, { method: "DELETE" });
    }
    getCRMCustomerFollowUp(crmId: string | number) {
        return this.F("crm/customer/" + crmId + "/follow-up");
    }
    addCRMCustomerFollowUp(body: { crmId: string | number, [key: string]: any }) {
        return this.F("crm/customer/" + body.crmId + "/follow-up", { method: "POST", body })
    }
    GlobalFollowUpReport() {
        return this.F("crm/global-follow-up-report");
    }
    findCustomer(prop: string, value: string) {
        const query = new URLSearchParams()
        query.append(prop, value);
        return this.F("order/helpers/find_customer?" + query.toString());
    }

    protected F(resourcePath: string, options: SpecialRequest = {}): AbortablePromise<any> {
        const DEFAULTS: any = {
            method: "GET",
            cache: "reload",
            mode: "cors"
        };
        let [URI, queryParams] = (resourcePath || "").split("?");
        var ops: RequestInit = { ...DEFAULTS, ...options };
        if (!ops.headers) ops.headers = {};
        ops.headers['Content-Type'] = 'application/json';
        
        // Integracion con `tunnel.php`
        ops.headers["x-natu-resource"] = URI;
        ops.headers["x-natu-method"] = (ops.method || "GET").toUpperCase().trim();
        ops.method = "PUT";

        ops.headers['Access-Control-Allow-Origin'] = '*';

        if (this.KEY) {
            if (!ops.headers) ops.headers = {};
            ops.headers["Authorization"] = 'Basic ' + this.KEY;
        }

        if (ops.body && (typeof ops.body !== typeof ""))
            ops.body = JSON.stringify(ops.body);
        const abortCtrl = new AbortController();
        if (!ops.signal) {
            ops.signal = abortCtrl.signal;
            var addAbortCtrl = true;
        }
        const promise: AbortablePromise<any> = fetch(DOMAIN + (queryParams ? ("?" + queryParams) : ""), ops).then(response => {
            if (response.ok) return response.json();
            return response.clone().json()
                .catch(() => {
                    return response.text().then(x => {
                        throw {
                            message: x,
                            status: response.status,
                            statusText: response.statusText
                        }
                    })
                }).then(obj => {
                    throw {
                        ...obj,
                        status: response.status,
                        statusText: response.statusText
                    }
                })
        }).catch(ex => {
            if (ex.message === "Failed to fetch") {
                throw {
                    message: "Este servicio no se encuentra disponible por el momento. Intentelo más tarde.",
                    status: 0
                }
            }
            /** Abort error (`the user aborted a request`) */
            if (ex.name === "AbortError" && ex.code === 20) return;
            if (!ex.message) ex.message = ex.statusText;
            throw ex;
        });
        if (addAbortCtrl)
            promise.abort = function () {
                abortCtrl.abort();
            }
        return promise;
    }
}
export default new API();

interface SpecialRequest extends RequestInit {
    body?: any
}

function isNullOrUndefined(value?: any) {
    if (value === null) return true;
    if (value === undefined) return true;
    if (value === "") return true;
    return false;
}