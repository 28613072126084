import React from "react";
import { PaperProps, Paper, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    TabContainer: {
        width: "100%",
        height: "calc(100% - 1px)",
        display: "flex",
        flexDirection: "column",
        padding: 10,
        overflow: "hidden"
    },
    PaperContentTab: {
        textAlign: "center"
        , padding: 10
        , margin: "auto"
        , marginTop: 10
        , width: "100%"
        , height: "100%"
    }
})

export function FlexTabContainer(props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
    const { className, ...divProps } = props;
    const classes = useStyles();
    return (<div {...divProps} className={[classes.TabContainer, className].join(" ")} >
        {props.children}
    </div>);
}
export function FlexPaperContentTab(props: PaperProps) {
    const { className, ...divProps } = props;
    const classes = useStyles();
    return (<Paper {...divProps} className={[classes.PaperContentTab, className].join(" ")} >
        {props.children}
    </Paper>);
};