import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from "@material-ui/core";
import React from "react";
import API from "../../Services/API";
import ImageInput from "../ImageInput";
import UserListInput from "../Users/UserListInput";
import useReadImage from "./useReadImage";


export default function (props: {
    onFinished(insertId?: number): void;
}) {
    const [userId, set_userId] = React.useState(null);
    const [status, set_status] = React.useState<"fetch" | "error" | "submit" | "">("");
    const [error, set_error] = React.useState(null as any);

    const [comissions, set_comissions] = React.useState(null as any[] | null);
    const [selected_comission, set_selected_comission] = React.useState("");
    const [imgFile, imgSrc, set_file, image_status] = useReadImage();

    React.useEffect(() => {
        if (!userId) return;
        set_comissions(null);
        set_selected_comission("");
        API.GetPendingComissions(userId)
            .then(results => {
                set_comissions(results);
                set_status("");
            }).catch(ex => {
                set_error(ex);
                set_status("error");
            })
    }, [userId]);

    React.useEffect(() => {
        if (status !== "submit") return;
        let comission = selected_comission
            ? comissions.find(x => x.id == selected_comission)
            : null;
        if (!(selected_comission && comission)) {
            set_error({
                name: "FormValidationError",
                message: "Selecciona la comisión a pagar"
            });
            set_status("error");
            return;
        }
        if (!userId) {
            set_error({
                name: "FormValidationError",
                message: "Selecciona el usuario"
            });
            set_status("error");
            return;
        }
        if (!imgFile) {
            set_error({
                name: "FormValidationError",
                message: "Agrega la imagen del comprobante de pago"
            });
            set_status("error");
            return;
        }
        API.PayComission(comission.id, comission.monto, imgFile)
            .then(data => {
                props.onFinished(data.id)
            }).catch(ex => {
                set_error(ex);
                set_status("error");
            });
    }, [status]);


    const isLoading = status === "fetch" || status === "submit";

    return (<Dialog open={true} onClose={() => props.onFinished()} PaperProps={{ component: "form" }}
        onSubmit={event => {
            event.preventDefault();
            if (isLoading) return;
            set_status("submit");
        }}
        maxWidth="md"
    >
        <DialogTitle>Pago de Comision</DialogTitle>
        <DialogContent>
            <UserListInput value={userId} onChange={userId => {
                if (isLoading) return;
                set_userId(userId);
            }} />
            <div style={{ paddingLeft: 10, paddingRight: 10, marginTop: 20, marginBottom: 20 }} >
                <TextField
                    fullWidth
                    select
                    disabled={!comissions}
                    SelectProps={{
                        native: true,
                    }}
                    InputLabelProps={{
                        shrink: (comissions || []).length ? true : undefined
                    }}
                    label={status === "fetch" ? "Cargando..." : "Comisión a pagar:"}
                    value={selected_comission}
                    onChange={ev => set_selected_comission(ev.target.value)}
                >
                    {(comissions || []).length ? <option value="">Selecciona un registro</option> : null}
                    {(comissions || []).map(c => (<option key={c.id} value={c.id}>Ciclo {c.ciclo.split(".")[1]} - {c.monto.toCurrency()}</option>))}
                </TextField>
            </div>

            <ImageInput
                loading={image_status === "loading"}
                disabled={image_status === "loading" || isLoading || !selected_comission}
                className="transaction-img"
                onChange={set_file}
                onDelete={() => set_file(null)}
                src={imgSrc}
            />
            {error && <Typography variant="caption" color="error">{error.message}{error.code ? (". codigo: " + error.code) : ""} </Typography>}
        </DialogContent>
        <DialogActions>
            <Button type="submit" disabled={isLoading}>Guardar</Button>
        </DialogActions>
    </Dialog >)
}