import React from "react";
import { Button } from "@material-ui/core";

export default function(props: {
    loadingAction?: string,
    label?: string,
    onClick?: any,
    showReject?: boolean | "ONLY",
    name?: string
}) {
    return <div style={{ display: "flex", flexDirection: "row" }}>
        {props.showReject === "ONLY"
            ? null
            : (<Button
                style={{ margin: 10, flex: 1 }}
                onClick={props.onClick}
                variant="outlined"
                color="primary"
                disabled={Boolean(props.loadingAction)}
                name={props.name || props.label}
            >
                {props.loadingAction === (props.name || props.label)
                    ? "CARGANDO..."
                    : props.label}
            </Button>)
        }
        {props.showReject &&
            (<Button
                style={{ margin: 10, flex: 1 }}
                onClick={props.onClick}
                variant="outlined"
                color="secondary"
                disabled={Boolean(props.loadingAction)}
                name="RECHAZAR"
            >
                {props.loadingAction === "RECHAZAR"
                    ? "CARGANDO..."
                    : "RECHAZAR"}
            </Button>)}
    </div>
}