import React from "react";
import { InputProps, Typography } from "@material-ui/core";
import Input from "../Input";
import API from "../../Services/API";

export default function RolInput(props: {
    readOnlyValue?: string
    , loading: boolean
    , value: string
    onChange: InputProps["onChange"]
}) {
    const [roles, isLoading, error] = useData(!props.readOnlyValue);
    if (props.readOnlyValue) {
        return (<div style={{ marginTop: 10, marginBottom: 10 }}>
            <Typography style={{ display: "inline" }} >Rol: </Typography>
            <Typography style={{ display: "inline" }} variant="button" >{props.readOnlyValue}</Typography>
        </div>);
    }


    return (<Input
        select
        disabled={props.loading || isLoading}
        value={props.value || ""}
        name="rol"
        label="Rol"
        SelectProps={{ native: true }}
        InputLabelProps={{
            shrink: true
        }}
        onChange={props.onChange}
        error={error?.message}
    >
        <option>- Selecciona un rol -</option>
        {roles.map(rol => <option key={rol.id} value={rol.id} >{rol.nombre}</option>)}
    </Input>)
}


function useData(execute: boolean): [{ id: string | number, nombre: string }[], boolean, Error | null] {
    const [roles, set_roles] = React.useState<{ id: string | number, nombre: string }[]>([]);
    const [error, set_error] = React.useState<Error | null>(null);
    const [isLoading, set_isLoading] = React.useState(true);
    React.useEffect(() => {
        if (!execute) return set_isLoading(false);
        const promise = API.GetRoles();
        promise.then(roles => {
            set_roles(roles);
            set_error(null);
        }).catch(ex => {
            set_error(ex);
        }).finally(() => {
            set_isLoading(false);
        });
        return () => promise.abort();
    }, []);

    return [roles, isLoading, error];
}