export class OrderStateCustomerData implements Order_Datos_Envio, Order_Datos_Facturacion {
    tipo_envio: TIPO_ENVIO = "PICK-UP";
    direccion: string = "";
    sector: string = "";
    ciudad: string = "";
    referencia: string = "";

    documento: "1" | "2" | "3" = "1";
    razonSocial: string = "";
    identificacion: string = "";
    telefono_factura: string = "";
    direccion_factura: string = "";
    email_factura: string = "";

    documento_contacto: "1" | "2" | "3" = "2";
    identificacion_contacto: string = "";
    nombre_contacto: string = "";
    telefono_contacto: string = "";

    observacion: string = "";
    numeroTransaccion: string = "";
    imgTransaccionList = [] as { src: string, file: File, isLoading?: boolean }[];

    usarImagen: boolean = true;
    constructor(defaults: Partial<OrderStateCustomerData> = {}) {
        for (const key in defaults) {
            if (Object.prototype.hasOwnProperty.call(defaults, key)) {
                this[key] = defaults[key];
            }
        }
    }
}

export default class OrderState extends OrderStateCustomerData {
    productList: Product[] = [];
    productoSelecciones: { [idProducto: number]: number[][] } = {};
    descuentoAplica: DescuentoCalc = null;
    subtotal: number = 0;

    comisiones_cruzar: Comission[] = [];
}