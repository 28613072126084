import React from "react";
import { Paper, Typography, TextField, Button, Divider, CircularProgress, Switch, FormControlLabel, FormLabel, Checkbox, FormControl, Chip, Avatar } from "@material-ui/core";
import ImageInput from "../ImageInput";
import useFetchUserComissions from "./useFetchUserComissions";
import AvailableComissionsCheck from "./AvailableComissionsCheck";
export default function (props: {

    userId?: number;
    comissions?: Comission[];
    onSelectedComissionsChange?(comissions: Comission[]): void;

    onImgChange(imageFile?: File, index?: number): void;
    onImgDelete(index: number): void;

    imgTransaccionList: { src: string, file: File, isLoading?: boolean }[];
    onDone(): void;
    total_pagar: number;
    puntosAcumulados?: number;
    loading: boolean;
}): JSX.Element {

    const [comissionsAreLoading, comisiones, error] = useFetchUserComissions(props.userId, props.total_pagar);
    const [info_message, set_info_message] = React.useState("");

    const usarImagen = true;
    const numeroTransaccion = "";
    const onValueChange = (propName, propValue) => console.error("Se esta intentando cambiar " + propName + " a " + propValue);
    const comissionList = props.comissions || [];

    React.useEffect(() => {
        if (!comissionsAreLoading) return;
        // si esta escogida alguna comisión, pero al hacer fetch ya no hay disponible, se borran todas las comisiones
        if (comissionList.length && !comisiones.length) {
            props.onSelectedComissionsChange([]);
            return;
        }
        // Si por el fetch, las comisiones no son las mismas, se borra todo y se le pide al usuario seleccionar de neuvo
        var not_in_list = comissionList.filter(x => !comisiones.find(com => com.id === x.id));
        if (not_in_list.length) {
            props.onSelectedComissionsChange([]);
            set_info_message("Las comisiones han cambiado, habilita el pago de comisiones nuevamente.");
        }
    }, [comisiones]);

    const PUEDE_CAMBIAR_A_NUMERO = null;
    const total_comisiones_seleccionadas = comissionList.reduce((a, b) => a + b.monto, 0);

    const disable_submit = props.loading || comissionsAreLoading;

    return (<Paper style={{ maxWidth: 400, padding: 10, marginTop: 10, height: "100%", marginLeft: "auto", marginRight: "auto" }}>
        <form onSubmit={event => {
            event.preventDefault();
            if (disable_submit) return;
            props.onDone();
        }} style={{ textAlign: "center" }}>
            <div style={{ textAlign: "center", paddingBottom: 20 }}>

                <AvailableComissionsCheck
                    loading={comissionsAreLoading}
                    error={error}
                    info_message={info_message}
                    comisiones={comisiones}
                    comissions={comissionList}
                    onChange={props.onSelectedComissionsChange}
                />

                <Typography style={{ marginTop: 10 }} variant="h6">
                    Total a Pagar : {(props.total_pagar - total_comisiones_seleccionadas).toCurrency()}
                </Typography>
                {props.puntosAcumulados
                    ? <Chip
                        color="primary"
                        label={<>✨ Con este pedido ganarás <strong>{props.puntosAcumulados}</strong> puntos. ✨</>}
                    />
                    : null}
            </div>
            <Divider />
            <div style={{ padding: 20, paddingBottom: 0 }}>
                <Typography variant="h6" >Realiza tu pago mediante Transferencia o Deposito Bancario a la cuenta:</Typography>
                <Typography>BANCO PICHINCHA Cta. Ahorros #2205511631</Typography>
                <Typography>RUC. 1792627192001</Typography>
                <Typography>COSMETICOSNATU CIA LTDA</Typography>
                <Typography>pagos@natucosmetic.com</Typography>
                <div style={{ marginTop: 10 }}>
                    <Typography variant="h6">O realiza tu pago con tarjeta de crédito:</Typography>
                    <Typography>Para pagos con Tarjeta de Credito/Debito comunicate al <a title="Número de teléfono" href="tel:0999532098"></a>099 953 2098<a /></Typography>
                    {/*<!-- PayPal Logo -->
                    <table cellPadding="10" cellSpacing="0" style={{ textAlign: "center", border: 0, margin: "auto", padding: 0 }} >
                        <tr>
                            <td align="center" style={{ padding: 0 }} >
                                <a href="https://www.paypal.me/natucosmeticoficial"
                                    target="_blank"
                                    title="Paga con paypal aqui"
                                >
                                    <img src="https://www.paypalobjects.com/webstatic/mktg/logo/AM_mc_vs_dc_ae.jpg" alt="PayPal Acceptance Mark"></img>
                                </a>
                            </td>
                        </tr>
                    </table>
                    <!-- PayPal Logo -->*/}
                </div>
            </div>
            {PUEDE_CAMBIAR_A_NUMERO && <FormControlLabel
                label="Subir una Imagen"
                control={<Switch
                    disabled={props.loading}
                    checked={Boolean(usarImagen)}
                    onChange={e => onValueChange("usarImagen", e.currentTarget.checked)}
                />}
            />}

            {PUEDE_CAMBIAR_A_NUMERO && !usarImagen && <TextField
                style={{ width: "calc(100% - 20px)", margin: 10 }}
                variant="outlined"
                inputMode="numeric"
                inputProps={{ id: "numeroTransaccion" }}
                onChange={event => onValueChange("numeroTransaccion", event.currentTarget.value)}
                value={numeroTransaccion}
                label="Numero de Comprobante *"
            />}
            {usarImagen && <ImageList
                items={props.imgTransaccionList}
                onImgChange={props.onImgChange}
                onImgDelete={props.onImgDelete}
            />}

            <Button
                type="submit"
                disabled={disable_submit}
                variant="outlined"
                color="primary"
                style={{ margin: 10, width: "calc(100% - 20px)" }}
            >
                {props.loading ? <CircularProgress size={25} /> : "ENVIAR"}
            </Button>
        </form>
    </Paper >)
}

function ImageList(props: {
    items: { src: string, file: File, isLoading?: boolean }[],
    loading?: boolean;
    onImgChange(File?, index?: number): void;
    onImgDelete(index: number): void;
}) {
    const { items } = props;
    return (<>
        {items.map((item, index) => (<ImageInput key={index}
            loading={item.isLoading}
            disabled={props.loading}
            className="transaction-img"
            onChange={file => props.onImgChange(file, index)}
            onDelete={() => props.onImgDelete(index)}
            src={item.src}
        />))}
        <ImageInput
            loading={false}
            disabled={props.loading}
            className="transaction-img"
            onChange={file => props.onImgChange(file)}
            src=""
        />
    </>);
}