import React from "react";
import { CycleTraveleableProps } from "../lib/CardWithCycleTravel";
import { Grid, Stepper, Step, StepLabel, Hidden, Typography, Tooltip } from "@material-ui/core";
import HierarchyGraph, { ExplanationContainer } from "./HierarchyGraph";
import HierarchyResume from "./HierarchyResume";

export default function HierarchyFeed(props: CycleTraveleableProps) {

    const { descendants, descendants_resume } = props.data;

    const rango = descendants_resume?.titulo;
    const selectedStepIndex = ["GT", "GGT"].indexOf(rango) >= 0 ? 1 : 0;

    return (<Grid container spacing={2}>
        <Grid item xs={12} >
            <Stepper
                style={{ width: "100%", padding: 0, paddingTop: 0, paddingBottom: 10 }}
                alternativeLabel={true}
                activeStep={selectedStepIndex}
                color="primary">
                <Step>
                    <Tooltip title="Asesor/a">
                        <StepLabel>
                            <Typography variant="caption">
                                Asesor/a
                            </Typography>
                        </StepLabel>
                    </Tooltip>
                </Step>
                <Step>
                    <Tooltip title="Embajador/a">
                        <StepLabel>
                            <Typography variant="caption">
                                Embajador/a
                            </Typography>
                        </StepLabel>
                    </Tooltip>
                </Step>
            </Stepper>
        </Grid>
        <Grid item xs={12} sm={8}>
            <div className="dashboard-card">
                <HierarchyGraph data={descendants} />
            </div>
            <Hidden smUp>
                <Grid item xs={12}>
                    <ExplanationContainer usuarios={descendants} />
                    <hr />
                </Grid>
            </Hidden>
        </Grid>
        <Grid item xs={12} sm={4}>
            <HierarchyResume {...(descendants_resume || {
                esUsuarioNuevo: false,
                id: -1,
                isCurrentUser: true,
                n1_activos: 0,
                n1_comisionable: 0,
                n1_subtotal_comision: 0,
                n1_usuarios: 0,
                n2_activos: 0,
                n2_comisionable: 0,
                n2_subtotal_comision: 0,
                n2_usuarios: 0,
                n3_activos: 0,
                n3_comisionable: 0,
                n3_subtotal_comision: 0,
                n3_usuarios: 0,
                nX_total_calificable: 0,
                nombre: "",
                rol: "",
                subtotal_comision: 0,
                total_calificable: 0,
                total_comision: 0,
                total_comisionable: 0,
                total_descuento: 0,
                total_pedidos: 0,
                __isHistoricRecord: true
            })} />
        </Grid>
        <Hidden xsDown >
            <Grid item xs={12}>
                <ExplanationContainer usuarios={descendants} />
            </Grid>
        </Hidden>
    </Grid>);
}