import React from "react";
import { Table, TableHead, TextField, TableCell, TableRow, Button, CircularProgress, Typography, TableBody } from "@material-ui/core";
import API from "../../Services/API";
export default class ProductSales extends React.Component {
    state = {
        loading: null,
        error: null,
        data: []
    }
    handleClick = () => {
        if (this.state.loading) return;
        var desde = this.valueOf("desde");
        var hasta = this.valueOf("hasta");
        var estado = this.valueOf("estado");
        if (!(desde && !isNaN(+new Date(desde)))) return alert("Selecciona la fecha desde");
        if (!(hasta && !isNaN(+new Date(hasta)))) return alert("Selecciona la fecha hasta");
        if (!estado) return alert("Selecciona el estado");

        this.setState({ loading: true });

        desde = new Date(desde).toJSON();
        hasta = new Date(hasta).toJSON();

        API.ProductSalesReport({ desde, hasta, estado })
            .then(data => this.setState({ data, loading: null, error: null }))
            .catch(error => this.setState({ error, loading: null }));
    }
    valueOf(ID: string) {
        var el = document.getElementById(ID) as any;
        return el && el.value;
    }
    render() {
        const { data, error, loading } = this.state;
        return (<div style={{ display: "flex", flexDirection: "column" }}>
            <div>
                <TextField style={{ marginLeft: 10, marginRight: 10 }} type="datetime-local" inputProps={{ id: "desde" }} />
                <TextField style={{ marginLeft: 10, marginRight: 10 }} type="datetime-local" inputProps={{ id: "hasta" }} />
                <TextField style={{ marginLeft: 10, marginRight: 10 }} select SelectProps={{ native: true }} inputProps={{ id: "estado" }} >
                    <option value={1}>PENDIENTE</option>
                    <option value={0}>APROBADO</option>
                    <option value={2}>RECHAZADO</option>
                    <option value={3}>ENTREGADO</option>
                </TextField>
                <Button disabled={loading} style={{ marginLeft: 10, marginRight: 10 }} variant="outlined" onClick={this.handleClick} >GENERAR</Button>
            </div>
            {loading
                ? (<div style={{ width: "100%", marginTop: 100, textAlign: "center" }} >
                    <CircularProgress size={100} />
                    <Typography variant="h6">CARGANDO...</Typography>
                </div>)
                : (error
                    ? (<div style={{ width: "100%", marginTop: 100, textAlign: "center" }} >
                        <Typography variant="h4">OCURRIÓ UN ERROR</Typography>
                        <Typography variant="subtitle1">{error.message}</Typography>
                        <Typography variant="subtitle2" >{error.code} {error.status}</Typography>
                    </div>)
                    : (<div>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell component="th" >Fecha</TableCell>
                                    <TableCell component="th" >#</TableCell>
                                    <TableCell component="th" >Usuario</TableCell>
                                    <TableCell component="th" >Estado</TableCell>
                                    <TableCell component="th" >Observacion</TableCell>
                                    <TableCell component="th" >Fecha Envío</TableCell>
                                    <TableCell component="th" >Entregado</TableCell>
                                    <TableCell component="th" >Motivo Anulacion</TableCell>
                                    <TableCell component="th" >Producto</TableCell>
                                    <TableCell component="th" >Cant</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.map(d => (<TableRow key={d.id}>
                                    <TableCell >{new Date(d.createdAt).toLocaleDateString()}</TableCell>
                                    <TableCell>{d.idPedido}</TableCell>
                                    <TableCell>{d.username}</TableCell>
                                    <TableCell>{d.estado}</TableCell>
                                    <TableCell>{d.observacion}</TableCell>
                                    <TableCell>{d.fechaEntrega && new Date(d.fechaEntrega).toLocaleDateString()}</TableCell>
                                    <TableCell>{d.fechaEntrega && new Date(d.fechaEntrega).toLocaleDateString()}</TableCell>
                                    <TableCell>{d.motivoAnulacion}</TableCell>
                                    <TableCell>{d.descripcion}</TableCell>
                                    <TableCell>{d.cantidad}</TableCell>
                                </TableRow>))}
                            </TableBody>
                        </Table>
                    </div>)
                )}
        </div >)
    }
}