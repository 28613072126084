export class ValidateInput {
    static tel(value) {
        if (!value) return;
        if (value.length < 7) return "El número de telefono debe tener al menos 7 digitos";
        for (let i = 0; i < value.length; i++) {
            if (!(value[i] === "+" || isDigit(value[i]) || value[i].trim() === "")) {
                return "El número debe tener uno de estos formatos: '09123456789' o '+5931234567890'";
            }
        }
    }
    static email(value) {
        if (!value) return;
        if (value.length < 5) return "El email no está escrito correctamente";
        let twoParts = value.split("@");
        if (twoParts.length !== 2) return "El email no está escrito correctamente";
        if (!(twoParts[1].indexOf(".") >= 0)) return "El email no está escrito correctamente";
        if (value.trim().indexOf(" ") >= 0) return "El email no está escrito correctamente";
        if (value.trim().indexOf("\t") >= 0) return "El email no está escrito correctamente";
        if (value.trim().indexOf("\r") >= 0) return "El email no está escrito correctamente";
    }
}

export function ValidarIdentificacion(value: string, documento: any) {
    if (!value) value = "";
    documento = +documento;
    if (documento === 3) { return; }
    if (documento === 1) {
        if (value.length !== 13) return "Número de RUC debe tener 13 digitos";
        if (value.length === 13 && value === "9999999999999") return;
    }
    if (documento === 2) {
        if (value.length !== 10) return "Número de Cedula debe tener 10 digitos";
    }
    return "";
}

export function isDigit(char) { return char >= "0" && char <= "9"; }
function isNumber(obj) { return !isNaN(parseFloat(obj)) }
function isEmptyOrNull(obj) {
    if (obj === '' || obj == undefined || obj == null || obj == 'undefined') {
        return true
    } else {
        return false;
    }
}