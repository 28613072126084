import { createAsyncThunk } from "@reduxjs/toolkit";
import Preferences from "../../../../Utils/Preferences";
import { RootState } from "../../../../Redux/Store";

const PREFERENCES_KEY = "current_order";

const LocalStoreOrder = createAsyncThunk("pedido/LocalStoreOrder", function (params: { clean?: boolean, reportCompleted?: Function }, ThunkAPI) {

    if (params.clean) {
        if (params.reportCompleted) params.reportCompleted();
        return Promise.resolve(Preferences.Set(PREFERENCES_KEY));
    }

    const state = (ThunkAPI.getState() as RootState).Order.data;
    // actualizar todo!
    var choosen = state.productList.filter(x => x.cantidad).map(x => ({ id: x.id, cantidad: x.cantidad }));

    const { numeroTransaccion, observacion,
        direccion, ciudad, sector, referencia,
        documento, identificacion, razonSocial, telefono_factura, direccion_factura, email_factura, tipo_envio,
        documento_contacto, identificacion_contacto, nombre_contacto, telefono_contacto, productoSelecciones
    } = state;

    Preferences.Set(PREFERENCES_KEY, {
        numeroTransaccion, direccion, ciudad, choosen,
        sector, observacion, referencia,
        documento, identificacion, razonSocial, telefono_factura, direccion_factura, email_factura, tipo_envio,
        documento_contacto, identificacion_contacto, nombre_contacto, telefono_contacto, productoSelecciones
    });

    params.reportCompleted && params.reportCompleted();
    return Promise.resolve();

});
export default LocalStoreOrder;
