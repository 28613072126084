import React from "react";
import { Card, CardContent, TextField, Button, InputAdornment, IconButton, Typography, Divider } from "@material-ui/core";
import API from "../Services/API";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Link } from "react-router-dom";
import Preferences from "../Utils/Preferences";

export default class Login extends React.Component<{ onLoggedIn: (params: LoggedIn) => void }, LoginState> {
    state = new LoginState();
    constructor(props: any) {
        super(props);
        API.KEY = "";
        this.state.user = Preferences.Get("user") || "";
    }
    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.currentTarget;
        this.setState({
            [name]: value
        } as any);
    }
    componentDidMount() {
        setTimeout(() =>
            window.requestAnimationFrame(() => {
                if (this.state.user) {
                    document.getElementById("password-textfield").focus();
                } else {
                    document.getElementById("user-textfield").focus();
                }
            }), 100);
    }
    handleSubmit = (event: any) => {
        event.preventDefault();
        if (this.state.loading) return;
        const user = this.state.user && this.state.user.toString().trim();
        const pwd = this.state.pwd && this.state.pwd.toString().trim();
        if (!user) return this.setState({ error: { message: "Digite su usuario" } });
        if (!pwd) return this.setState({ error: { message: "Digite su contraseña" } });
        this.setState({ loading: true, error: null });
        API.Login({ user, pwd })
            .then(r => {
                // importante, se debe ejecutar ANTES de onLoggedIn
                Preferences.Set("user", user);
                this.setState({ loading: null });
                this.props.onLoggedIn({ ...r });
            })
            .catch(error => this.setState({ loading: null, error }));
    }
    handlePassword = (event) => {
        this.setState(prev => {
            const state = { ...prev };
            state.isPasswordVisible = !state.isPasswordVisible;
            return state;
        });
    }
    render() {
        const { loading, error, user, pwd, isPasswordVisible } = this.state;
        return (<div style={{ paddingTop: 20 }}>
            <Card style={{ width: "100%", maxWidth: 300, margin: "auto", textAlign: "center" }}>
                <form onSubmit={this.handleSubmit}>
                    <CardContent>
                        <img src={process.env.PUBLIC_URL + "/logo.png"} alt="Logo"
                            style={{ height: 150, minHeight: 150, width: "auto" }} />
                        <TextField
                            inputProps={{ id: "user-textfield" }}
                            onChange={this.handleChange}
                            value={user}
                            label="Usuario"
                            name="user"
                            fullWidth
                            disabled={loading}
                            style={defaultStyle}
                        />
                        <TextField
                            onChange={this.handleChange}
                            value={pwd}
                            name="pwd"
                            label="Contraseña"
                            type={isPasswordVisible ? "input" : "password"}
                            fullWidth
                            disabled={loading}
                            style={defaultStyle}
                            inputProps={{ id: "password-textfield" }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <IconButton onClick={this.handlePassword}>
                                        {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }}
                        />
                        {error && <Typography color="secondary" variant="body1" style={{ textAlign: "center" }}>{error.message}</Typography>}
                        <Button type="submit" variant="outlined" style={defaultStyle} color="primary" disabled={loading} fullWidth >
                            {loading ? "CARGANDO..." : "ENTRAR"}
                        </Button>
                        <Divider style={{ margin: 20 }} />
                        <Typography style={{ display: "block", textAlign: "center", margin: 5 }} variant="body2" component={Link} to="/signin">No eres asesor/a natú? Registrate aquí!</Typography>
                    </CardContent>
                </form>
            </Card>
        </div>)
    }
}
const defaultStyle = {
    marginTop: 10,
    marginBottom: 10
}

class LoginState {
    user = "";
    pwd = "";
    loading = false;
    isPasswordVisible = false;
    error = null;
}