import React from "react";
import { CardContent, Button, Card, Typography, CircularProgress } from "@material-ui/core";
import NewInteraction, { Interaction } from "./NewInteraction";
import API from "../../../Services/API";

export default function InteractionLog(props: {
    crmId?: React.ReactText
}) {
    const [data, set_data] = React.useState([]);
    const [status, set_status] = React.useState("LOADING");
    const [error, set_error] = React.useState<Error | null>(null);

    React.useEffect(() => {
        console.info("Loading checked", props);
        if (status !== "LOADING") return;
        var promise = API.getCRMCustomerFollowUp(props.crmId);
        promise.then(items => {
            set_data(items);
            console.info("Loading recovering items ", items);
            set_status("");
        }).catch(ex => {
            console.error(ex);
            set_error(ex);
            set_status("ERROR")
        });
        return () => promise.abort();
    }, [status]);

    const isLoading = status === "LOADING";

    if (error)
        return (<Typography color="error">{error.message}</Typography>);

    return (<div>
        <div style={{ margin: 10 }}>
            <NewInteraction
                crmId={props.crmId}
                onCompleted={new_item => {
                    set_data([new_item, ...data]);
                }}
            />
        </div>
        {isLoading
            ? <div style={{ margin: "auto", textAlign: "center" }} ><CircularProgress size={40} /></div>
            : <div>
                <Button style={{ margin: 10 }} variant="outlined" disabled={isLoading} onClick={() => set_status("LOADING")}>
                    Actualizar
                </Button>
                {data.map(item => <div key={item.id} style={{ margin: 10 }}><InteractionCard {...item} /></div>)}
            </div>
        }
    </div>);
}

function InteractionCard(props: Interaction) {
    return (<Card>
        <CardContent>
            <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ flex: 1, padding: 10 }}>
                    <Typography component="small" variant="caption">Resumen:</Typography>
                    <Typography> {props.resumen} </Typography>
                </div>
                <div style={{ padding: 10 }} >
                    <Typography component="small" variant="caption">Fecha:</Typography>
                    <Typography>{new Date(props.fechaLlamada).toLocaleString("es-EC", { day: "numeric", month: "short", year: "numeric" })}</Typography>
                </div>
            </div>
            <div style={{ textAlign: "right", padding: 10 }}>
                <Typography component="small" variant="caption">Sgte llamada:</Typography>
                <Typography>{props.fechaSiguienteLlamada && new Date(props.fechaSiguienteLlamada).toLocaleString("es-EC", { day: "numeric", month: "short", year: "numeric" })}</Typography>
            </div>
        </CardContent>
    </Card>)
}