import React from "react";
import { Typography } from "@material-ui/core";
import Input from "../../Input";

export interface PersonalInfoBlockInputContent {
    nombre?: string;
    identificacion?: string;
    telefono?: string;
    email?: string;
    nacionalidad?: string;
    fechaNacimiento?: string;
    ciudad?: string;
    direccion?: string;
}
export default function PersonalInfoBlock(props: {
    loading?: boolean
    , error: PersonalInfoBlockInputContent
    , onChange: React.ComponentProps<typeof Input>["onChange"]
    , sectionTitles?: {
        personal_data?: string,
        address?: string
    }
} & PersonalInfoBlockInputContent) {
    const { loading, error, onChange, sectionTitles, ...state } = props;

    return (<div>
        <Typography variant="h6" style={{ marginTop: 30 }} >{sectionTitles?.personal_data || "Información Personal"}</Typography>
        <Input
            disabled={loading}
            error={error.nombre}
            onChange={onChange}
            value={state.nombre || ""}
            name="nombre"
            label="Nombres Completos"
        />
        <Input
            disabled={loading}
            error={error.identificacion}
            onChange={onChange}
            value={state.identificacion || ""}
            name="identificacion"
            label="Identificacion (CI o Pasaporte)"
            type="phone"
        />
        <Input
            disabled={loading}
            error={error.telefono}
            onChange={onChange}
            value={state.telefono || ""}
            name="telefono"
            label="Telefono"
            type="phone"
        />
        <Input
            disabled={loading}
            error={error.email}
            onChange={onChange}
            value={state.email || ""}
            name="email"
            label="Correo Electronico"
            type="email"
        />
        <Input
            disabled={loading}
            error={error.nacionalidad}
            onChange={onChange}
            value={state.nacionalidad || ""}
            name="nacionalidad"
            label="Nacionalidad"
        />
        <Input
            InputLabelProps={{ shrink: true }}
            disabled={loading}
            error={error.fechaNacimiento}
            onChange={onChange}
            value={state.fechaNacimiento || ""}
            name="fechaNacimiento"
            label="Fecha Nacimiento"
            type="date"
        />

        <Typography variant="h6" style={{ marginTop: 30 }}>{sectionTitles?.address || "Dirección"}</Typography>
        <Input disabled={loading}
            error={error.ciudad}
            onChange={onChange}
            value={state.ciudad || ""}
            name="ciudad"
            label="Ciudad"
        />
        <Input disabled={loading}
            error={error.direccion}
            onChange={onChange}
            value={state.direccion || ""}
            name="direccion"
            label="Direccion"
        />
    </div>);
}