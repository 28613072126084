import  OrderState  from "../Components/Order/lib/OrderState";

var prefix = "";
export default class Preferences {
    static setPrefix(_prefix: string) {
        prefix = _prefix || "";
    }
    static Get(_key_: "current_order" | "direccion" | "user" | "factura") {
        var key = prefix + _key_;
        var x = window.localStorage.getItem(key);
        try {
            return JSON.parse(x);
        } catch (ex) { }
        return x;
    }
    static Set(_key_: "current_order" | "direccion" | "user" | "factura", value: any = undefined) {
        var key = prefix + _key_;
        if (value === undefined) {
            window.localStorage.removeItem(key);
            return;
        } else if (value === null) {
            window.localStorage.removeItem(key);
            return;
        }
        var value_txt = value;
        if (typeof value === typeof {})
            value_txt = JSON.stringify(value);

        window.localStorage.setItem(key, value_txt);
    }
    static NormalizeProductoSelecciones(productoSelecciones: OrderState["productoSelecciones"], productList: Product[]) {
        if (!(productoSelecciones && Object.keys(productoSelecciones).length)) return false;
        for (const key in productoSelecciones) {
            var product = productList.find(x => x.id === +key);
            // el producto no está en la lista, se borra
            if (!product || !(product.opciones && product.opciones.length)) {
                delete productoSelecciones[key];
                continue;
            }
            var list = productoSelecciones[key];
            // algun item no está en la lista, se borra el conjunto
            list = list.filter(combo => {
                var existingItems = combo.filter(ID => productList.find(x => x.id === +ID));
                return existingItems.length === combo.length;
            });
            productoSelecciones[key] = list;
            // la lista no tiene items, se borra
            if (!productoSelecciones[key].length) delete productoSelecciones[key];
        }
        return Object.keys(productoSelecciones).length > 0;
    }
}