import React from "react";
import { Tabs, Tab, Paper } from "@material-ui/core";
import ProductSales from "./ProductSales";
import InvoiceReport from "./InvoiceReport";

export default class Reports extends React.Component<any> {
    state = {
        currentTab: 0
    }
    constructor(props) {
        super(props);
        props.onMounted(props.match.path);
    }
    handleChange = (x, currentTab) => {
        this.setState({ currentTab });
    }
    render() {
        const { currentTab } = this.state;
        return (
            <Paper style={{ margin: 10 }}>
                <Tabs style={{ marginBottom: 10 }} value={currentTab} onChange={this.handleChange}>
                    <Tab label="Detalle de Productos" />
                    <Tab label="Reporte de Facturación" />
                </Tabs>
                {currentTab === 0 && <ProductSales />}
                {currentTab === 1 && <InvoiceReport />}
            </Paper>)
    }
}