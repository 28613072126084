import React from "react";
import { Button, withStyles, Dialog, DialogContent, DialogActions, Tab, Tabs, withMobileDialog } from "@material-ui/core";
import UserDataForm from "./UserDataForm";
import ValidateUserForm, { FocusOn } from "./ValidateUserForm";
import API from "../../Services/API";
import { Focus } from "../../lib/DOMActions";
import UserPointsHistory from "./UserPointsHistory";
import CRMTab from "./CRM";

interface EditUserProps extends WithStylesProps {
    show: boolean;
    id: React.ReactText;
    grupos: any[];
    onClose: (shouldUpdate?: boolean) => void;
}
interface WithStylesProps {
    fullScreen?: boolean
    classes?: { [name: string]: string }
    defaultActiveTab?: EditTabKeys
}
type EditTabKeys = "datos-personales" | "crm" | "historial-pedidos";
interface State extends VisibleUser {
    loading: boolean;
    error?: any;
    validarPassword?: boolean;
    currentTab: string
}

const EditUserData = withStyles({
    dialogContent: {
        padding: 0
    }
})(
    withMobileDialog({ breakpoint: "sm" })(class EditUserData extends React.Component<EditUserProps, State> {
        state = {
            currentTab: "datos-personales" as EditTabKeys
        } as State;

        constructor(props: EditUserProps) {
            super(props);
            if (props.show) this.loadData();
        }
        loadData = () => {
            this.setState({ loading: true });
            if (this.props.id) {
                API.GetUser(this.props.id as number)
                    .then(user => {
                        this.setState(prev_state => {
                            const new_state: Partial<typeof prev_state> = {};
                            for (const key in prev_state) {
                                new_state[key] = "";
                            }
                            return {
                                ...new_state
                                , currentTab: this.props.defaultActiveTab || "datos-personales"
                                , loading: false
                                , validarPassword: false
                                , password: ""
                                , ...user
                            };
                        })
                    }).catch(error => {
                        this.setState({ error, loading: false });
                    });
            } else {
                Promise.resolve(() =>
                    this.setState(prev_state => {
                        const new_state: Partial<typeof prev_state> = {};
                        for (const key in prev_state) {
                            new_state[key] = "";
                        }
                        return {
                            ...new_state
                            , currentTab: this.props.defaultActiveTab || "datos-personales"
                            , loading: false
                            , validarPassword: true
                            , id: this.props.id as number
                        }
                    })
                );

            }
        }
        // MIENTRAS ESTÉ CERRADO, EL COMPONENTE NO SE ACTUALIZARÁ
        shouldComponentUpdate(nextProps: EditUserProps) {
            var isOpen = this.props.show;
            var NextisOpen = nextProps.show;
            return (isOpen === NextisOpen && isOpen === false) ? false : true;
        }
        componentDidUpdate(prevProps: EditUserProps) {
            if (!prevProps.show && this.props.show)
                this.loadData();

        }

        handleClose = () => {
            if (this.state.loading) return;
            this.props.onClose();
        }

        handleChange = event => {
            var { name, value, type, checked } = event.currentTarget;
            value = type === "checkbox" ? checked : value;
            this.setState({ [name]: value } as any);
        }

        handleSubmit = () => {
            // divido el objeto para evitar enviar TODOS los parametros secundarios al servidor
            const { loading, error, validarPassword, ...userData } = this.state;
            if (loading) return;
            var validationErrors = ValidateUserForm(userData, {
                passwordOpcional: !validarPassword
            });
            if (validationErrors) return this.setState({ error: validationErrors });
            this.setState({ loading: true });
            API.SaveUser(userData)
                .then(() => this.props.onClose(true))
                .catch(error => this.setState({ error, loading: false },
                    () => Object.keys(error).forEach(FocusOn))
                );
        }
        handleDelete = () => {
            if (!this.state.id) return;
            if (this.state.loading) return;
            var isOk = window.confirm("Deseas eliminar este usuario?");
            if (!isOk) return;
            this.setState({ loading: true });
            API.DeleteUser(this.state.id)
                .then(() => this.props.onClose(true))
                .catch(err => this.setState({
                    error: { guardar: err.message },
                    loading: false
                }, () => Focus("guardar")));
        }
        onTabChange = (_, currentTab: string) => {
            this.setState({
                currentTab
            });
        }
        render() {
            const { grupos, fullScreen } = this.props;
            const { loading, error, currentTab, ...state } = this.state;

            const tableMode = ["historial-pedidos", "crm"].includes(this.state.currentTab);

            const footer = (<>
                <Button disabled={loading} variant="outlined" onClick={this.handleDelete} color="secondary">ELIMINAR</Button>
                <Button disabled={loading} variant="outlined" onClick={this.handleSubmit} color="primary">GUARDAR</Button>
                <Button disabled={loading} onClick={this.handleClose} color="primary">SALIR</Button>
            </>);


            return (<Dialog
                open={this.props.show}
                title="Usuarios"
                onClose={this.handleClose}
                scroll="paper"
                fullWidth
                fullScreen={fullScreen}
                maxWidth={tableMode ? "lg" : "sm"}
                style={{
                    height: "100%"
                }}
            >
                <Tabs variant={fullScreen ? "fullWidth" : undefined} value={currentTab} color="primary" onChange={this.onTabChange}>
                    <Tab value="datos-personales" label="Datos personales" />
                    <Tab value="crm" label="CRM" />
                    <Tab value="historial-pedidos" label="Historial de puntos" />
                </Tabs>
                <DialogContent classes={{ root: tableMode ? this.props.classes?.dialogContent : undefined }}>
                    {this.state.currentTab === "datos-personales"
                        ? <UserDataForm
                            formType="EDIT"
                            editionType="ADMIN"
                            grupos={grupos}
                            {...state as any}
                            loading={loading}
                            error={error}
                            onSubmit={this.handleSubmit}
                            onChange={this.handleChange}
                        /> : null}
                    {this.state.currentTab === "historial-pedidos"
                        ? <UserPointsHistory key={this.state.id} userId={this.state.id} /> : null}
                    {this.state.currentTab === "crm" ? <CRMTab onSaved={data => this.setState({ crmId: data.id as number })} userId={this.state.id} id={this.state.crmId} /> : null}
                </DialogContent>
                {tableMode
                    ? null
                    : <DialogActions>{footer}</DialogActions>}
            </Dialog>);
        }
    })
);
export default EditUserData;