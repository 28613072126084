import React from "react";
import { Typography, List, ListItem, ListItemSecondaryAction, ListItemText, IconButton } from "@material-ui/core";
import Modal from "../Modal";
import { Delete } from "@material-ui/icons";

interface Props {
    productList: Product[];
    selections: { [idProducto: number]: number[][] };
    onDeleteSelection: (DoneObject?) => void;
    id: string;
}

export default function ProductSelections(props: Props) {

    const { id, productList, selections } = props;
    const show = !(id === undefined || id === null);
    const Item = productList.find(x => x.id === +id) || {} as Product;
    const Rows = ((selections || {})[id] || []);

    function deletionClick(event) {
        const index = +event.currentTarget.value;
        if (index === null || index === undefined || isNaN(index)) return;
        props.onDeleteSelection({ id, index });
    }

    return (<Modal
        title="Elimina una combinación"
        onClose={props.onDeleteSelection}
        show={show}
        fullScreen={true}
    >
        <div style={{ textAlign: "center", marginTop: 10, marginBottom: 10 }}>
            <Typography variant="h6">{Item.nombre}</Typography>
        </div>
        <div style={{ paddingLeft: 10, paddingRight: 10, margin: "auto", width: "calc(100% - 20px)" }}>
            <List>
                {Rows.map((r, index) => <ListItem>
                    <ListItemText>
                        {r.map(idOpcion => <Typography>{(
                            productList.find(x => x.id === idOpcion) ||
                            { nombre: `id ${idOpcion} - Not Found` }
                        ).nombre}
                        </Typography>)}
                    </ListItemText>
                    <ListItemSecondaryAction>
                        <IconButton onClick={deletionClick} value={index}>
                            <Delete />
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>)}
            </List>
        </div>
    </Modal>);
}
