import React from "react";
import { Card, CardContent, Typography, CardHeader } from "@material-ui/core";

export default function ProfitCard(props: ProfitSummary) {

    const descuento = {
        acumulado: props?.descuento?.acumulado || 0,
        ciclo: props?.descuento?.ciclo || 0
    }

    const comision = {
        acumulado: props?.comision?.acumulado || 0,
        ciclo: props?.comision?.ciclo || 0
    }

    return (<Card className="dashboard-card">
        <CardHeader title="GANANCIAS" style={{ textAlign: "center" }} />
        <CardContent>
            <Typography variant="overline">Descuentos</Typography>
            <div style={{ display: "flex", flexDirection: "row", marginBottom: 10 }}>
                <div style={{ textAlign: "center", flex: 1 }}>
                    <Typography color="primary" variant="h5" style={{ flex: 1 }}>{reduceMoney(descuento.ciclo)}</Typography>
                    <Typography color="primary">ESTE CICLO</Typography>
                </div>
                <div style={{ borderLeft: "1px solid black", textAlign: "center", flex: 1 }}>
                    <Typography variant="h5" style={{ flex: 1 }} >{reduceMoney(descuento.acumulado)}</Typography>
                    <Typography>ACUMULADO</Typography>
                </div>
            </div>
            <Typography variant="overline">Comisiones</Typography>
            <div style={{ display: "flex", flexDirection: "row", marginBottom: 10 }}>
                <div style={{ textAlign: "center", flex: 1 }}>
                    <Typography color="primary" variant="h5" style={{ flex: 1 }}>{reduceMoney(comision.ciclo)}</Typography>
                    <Typography color="primary">ESTE CICLO</Typography>
                </div>
                <div style={{ borderLeft: "1px solid black", textAlign: "center", flex: 1 }}>
                    <Typography variant="h5" style={{ flex: 1 }} >{reduceMoney(comision.acumulado)}</Typography>
                    <Typography>ACUMULADO</Typography>
                </div>
            </div>
            <Typography variant="overline">Total</Typography>
            <div style={{ display: "flex", flexDirection: "row", marginBottom: 10 }}>
                <div style={{ textAlign: "center", flex: 1 }}>
                    <Typography color="primary" variant="h5" style={{ flex: 1 }}>
                        {reduceMoney(comision.ciclo + descuento.ciclo)}
                    </Typography>
                    <Typography color="primary">ESTE CICLO</Typography>
                </div>
                <div style={{ borderLeft: "1px solid black", textAlign: "center", flex: 1 }}>
                    <Typography variant="h5" style={{ flex: 1 }}>
                        {reduceMoney(comision.acumulado + descuento.acumulado)}
                    </Typography>
                    <Typography>ACUMULADO</Typography>
                </div>
            </div>
        </CardContent>
    </Card >)
}
function reduceMoney(value) {
    if (!value) return (0).toCurrency();
    /*
    if (value > 1000) return (+(value / 1000).toFixed(1)) + " K";
    if (value > 1000000) return (+(value / 1000).toFixed(1)) + " M";
    */
    return (+value).toCurrency();
}