import React from "react";
import UserDataForm from "./Users/UserDataForm";
import API from "../Services/API";
import ValidateUserForm from "./Users/ValidateUserForm";
import { Button } from "@material-ui/core";

export default function (props: {
    userId: number,
    onSubmit?: Function
    onMounted?: Function,
    match?: any,
}) {
    const [user, set_user] = React.useState({} as VisibleUser);
    const [status, set_status] = React.useState("" as "GUARDAR" | "" | "GUARDADO");
    const [errores, set_errores] = React.useState(undefined);

    function userchanged(event: React.ChangeEvent<HTMLInputElement>) {
        set_user({ ...user, [event.target.name]: event.target.value });
    }

    React.useEffect(() => {
        props.onMounted && props.match && props.onMounted(props.match.path);
        API.GetUser(props.userId).then(set_user);
    }, []);
    React.useEffect(() => {
        if (!status) return;
        var validationErrors = ValidateUserForm(user, { passwordOpcional: true });
        if (validationErrors) {
            set_status("");
            set_errores(validationErrors);
            return;
        }
        API.SaveUser(user).then(x => {
            set_status("GUARDADO");
            set_errores(undefined);
            props.onSubmit && props.onSubmit();
        }).catch(ex => {
            set_status("");
            set_errores({
                guardar: ex.message
            });
        });
    }, [status]);

    const isLoading = user.id === undefined || (status === "GUARDAR");

    return <div style={{ padding: 15 }}>
        <UserDataForm
            onSubmit={() => set_status("GUARDAR")}
            onChange={userchanged}
            error={errores}
            formType="EDIT"
            editionType="USER"
            loading={isLoading}
            {...user as any}
        >
            <div style={{ padding: 5 }}>
                <Button fullWidth type="submit" color={status === "GUARDADO" ? "secondary" : "primary"} disabled={isLoading} variant="outlined" >
                    {status === "GUARDADO" ? "Listo!" : "Actualizar datos"}
                </Button>
            </div>
        </UserDataForm>
    </div>
}