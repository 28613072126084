import React from "react";
import TabLayout, { TabLayoutItem } from "../../Layouts/TabLayout";
import { connect } from "react-redux";
import { RootState } from "../../Redux/Store";
import CanjearPuntosProductList from "./CanjearPuntosProductList";
import { FlexPaperContentTab } from "../../Layouts/FlexTab";
import CheckoutCanjearPuntos from "./CheckoutCanjearPuntos";

const Tabs: TabLayoutItem[] = [
    { tabLabel: "Productos", tabId: "products", Component: CanjearPuntosProductList }
    , { tabLabel: "Envío", tabId: "envio",  Component: CheckoutCanjearPuntos }
];

function CanjearPuntos() {
    return (<FlexPaperContentTab>
        <TabLayout
            style={{ padding: 20 }}
            tabs={Tabs}
        />
    </FlexPaperContentTab>);
}

export default connect(({ UserPoints }: RootState) => ({
    productList: UserPoints.productList,
    puntos_disponibles: UserPoints.resumen_puntos.disponible
}))(CanjearPuntos);