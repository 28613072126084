import React from "react";
import { TextField, Paper, Grid, Typography, CircularProgress } from "@material-ui/core";
import { DocToString } from "../../Utils/Calcs";
import { ValidarIdentificacion, ValidateInput } from "../../Utils/Validation";
import Input from "../Input";
import TipoEnvioInput from "./TipoEnvioInput";
import API from "../../Services/API";

export default class OrderCustomerData extends React.PureComponent<DataProps, {}> {
    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.currentTarget;
        this.searchPerson(name, value);
        this.props.onValueChange(name, value);
    }
    state = {
        persona_encontrada_identificacion: null as string | null,
        searchingPerson: false
    }
    searchPerson(prop: string, value: string) {
        if (!["identificacion"].includes(prop)) return;
        if ((value || "").trim() === this.state.persona_encontrada_identificacion) return;
        if (prop === "identificacion" &&
            this.state.persona_encontrada_identificacion &&
            value != this.state.persona_encontrada_identificacion) {
            this.setState({
                persona_encontrada_identificacion: null
            })
            for (const key of ["razonSocial", "direccion_factura", "telefono_factura", "email_factura"]) {
                this.props.onValueChange(key, "");
            }
        }
        if (!(value && value.trim().length > 5)) return;
        this.setState({ searchingPerson: true });
        API.findCustomer(prop, value)
            .then(customer => {
                if (customer.identificacion != value) return;
                if (!customer) return;
                for (const key in customer) {
                    if (Object.prototype.hasOwnProperty.call(customer, key)) {
                        const element = customer[key];
                        this.props.onValueChange(key, element || "");
                    }
                }
                this.setState({
                    persona_encontrada_identificacion: customer.identificacion
                });
            }).catch(void(0)).finally(() => {
                this.setState({ searchingPerson: false })
            })
    }
    render() {

        const { direccion, tipo_envio, ciudad, referencia, sector,
            documento, identificacion, razonSocial,
            direccion_factura, telefono_factura, email_factura, valor_pedido_calculo_envio,
            nombre_contacto, telefono_contacto, identificacion_contacto, documento_contacto
        } = this.props;

        let identificacion_e = identificacion
            ? ValidarIdentificacion(identificacion, documento)
            : "";
        let telefono_e = ValidateInput.tel(telefono_factura);
        let email_e = ValidateInput.email(email_factura);

        let identificacion_contacto_e = identificacion_contacto
            ? ValidarIdentificacion(identificacion_contacto, documento_contacto)
            : "";
        let telefono_contacto_e = ValidateInput.tel(telefono_contacto);

        const mostrar_ciudad = tipo_envio !== "DENTRO DE CIUDAD" ? true : null;
        const mostrar_datos_envio = tipo_envio !== "PICK-UP" ? true : null;


        const md_column_size = this.props.thirdColumnContent ? 4 : 6;
        const sm_column_size = this.props.thirdColumnContent ? 6 : 6;

        return (<Paper style={{ margin: "auto", height: "100%", width: "100%", padding: 10, marginTop: 10 }}>

            <Grid container >
                <Grid item sm={sm_column_size} md={md_column_size}>
                    <Typography variant="subtitle1" style={{ marginLeft: 10 }}>Datos de Envío</Typography>
                    <TextField
                        variant="outlined"
                        name="nombre_contacto"
                        inputProps={{ id: "nombre_contacto" }}
                        value={nombre_contacto}
                        onChange={this.handleChange}
                        label="Nombre *"
                        multiline
                        rows={2}
                        style={{ margin: 10, width: "calc(100% - 20px)" }}
                    />
                    <TextField
                        name="documento_contacto"
                        value={documento_contacto}
                        inputProps={{ id: "documento_contacto" }}
                        variant="outlined"
                        select
                        SelectProps={{ native: true }}
                        onChange={this.handleChange}
                        label="Documento *"
                        style={{ margin: 10, width: "calc(100% - 20px)" }}
                    >
                        <option value="1">{DocToString("1")}</option>
                        <option value="2">{DocToString("2")}</option>
                        <option value="3">{DocToString("3")}</option>
                    </TextField>
                    <Input
                        variant="outlined"
                        name="identificacion_contacto"
                        value={identificacion_contacto}
                        error={identificacion_contacto_e}
                        onChange={this.handleChange}
                        type="phone"
                        maxLength={20}
                        label={"Numero de " + DocToString(documento_contacto) + " *"}
                    />
                    <Input
                        variant="outlined"
                        name="telefono_contacto"
                        value={telefono_contacto}
                        error={telefono_contacto_e}
                        maxLength={20}
                        onChange={this.handleChange}
                        label="Telefono *"
                        type="phone"
                    />
                    {this.props.hide_tipo_envio
                        ? null
                        : (<TipoEnvioInput
                            name="tipo_envio"
                            value={tipo_envio}
                            valor_pedido={valor_pedido_calculo_envio}
                            onChange={this.handleChange}
                        />)}
                    {mostrar_datos_envio && (<>
                        {mostrar_ciudad && <TextField
                            variant="outlined"
                            name="ciudad"
                            inputProps={{ id: "ciudad" }}
                            value={ciudad}
                            onChange={this.handleChange}
                            label="Ciudad *"
                            style={{ margin: 10, width: "calc(100% - 20px)" }}
                        />}
                        <TextField
                            variant="outlined"
                            name="sector"
                            inputProps={{ id: "sector" }}
                            value={sector}
                            onChange={this.handleChange}
                            label="Sector *"
                            style={{ margin: 10, width: "calc(100% - 20px)" }}
                        />
                        <TextField
                            variant="outlined"
                            name="direccion"
                            inputProps={{ id: "direccion" }}
                            value={direccion}
                            onChange={this.handleChange}
                            label="Direccion Exacta* "
                            multiline
                            rows={3}
                            style={{ margin: 10, width: "calc(100% - 20px)" }}
                        />
                        <TextField
                            variant="outlined"
                            name="referencia"
                            inputProps={{ id: "referencia" }}
                            value={referencia}
                            onChange={this.handleChange}
                            label="Referencia"
                            multiline
                            rows={2}
                            style={{ margin: 10, width: "calc(100% - 20px)" }}
                        />
                    </>)}
                </Grid>
                <Grid item sm={sm_column_size} md={md_column_size}>
                    <Typography style={{ marginLeft: 10 }} variant="subtitle1">Datos de Facturación</Typography>
                    <TextField
                        name="documento"
                        value={documento}
                        inputProps={{ id: "documento" }}
                        variant="outlined"
                        select
                        SelectProps={{ native: true }}
                        onChange={this.handleChange}
                        label="Documento *"
                        style={{ margin: 10, width: "calc(100% - 20px)" }}
                    >
                        <option value="1">{DocToString("1")}</option>
                        <option value="2">{DocToString("2")}</option>
                        <option value="3">{DocToString("3")}</option>
                    </TextField>
                    <Input
                        variant="outlined"
                        name="identificacion"
                        value={identificacion}
                        error={identificacion_e}
                        onChange={this.handleChange}
                        type="phone"
                        maxLength={20}
                        label={"Numero de " + DocToString(documento) + " *"}
                        InputProps={{
                            endAdornment: this.state.searchingPerson ? <CircularProgress size="1em" color="primary" /> : undefined
                        }}
                    />
                    <TextField
                        variant="outlined"
                        name="razonSocial"
                        value={razonSocial}
                        onChange={this.handleChange}
                        label="Nombre / Empresa *"
                        inputProps={{ maxLength: 200, id: "razonSocial" }}
                        style={{ margin: 10, width: "calc(100% - 20px)" }}
                    />
                    <TextField
                        variant="outlined"
                        name="direccion_factura"
                        value={direccion_factura}
                        onChange={this.handleChange}
                        label="Direccion *"
                        inputProps={{ maxLength: 300, id: "direccion_factura" }}
                        multiline
                        rows={2}
                        style={{ margin: 10, width: "calc(100% - 20px)" }}
                    />
                    <Input
                        variant="outlined"
                        name="telefono_factura"
                        value={telefono_factura}
                        error={telefono_e}
                        maxLength={20}
                        onChange={this.handleChange}
                        label="Telefono *"
                        type="phone"
                    />
                    <Input
                        variant="outlined"
                        name="email_factura"
                        value={email_factura}
                        error={email_e}
                        maxLength={50}
                        onChange={this.handleChange}
                        label="Email *"
                        type="email"
                    />
                </Grid>
                {this.props.thirdColumnContent
                    ? <Grid item sm={sm_column_size} md={md_column_size}>
                        {this.props.thirdColumnContent}
                    </Grid>
                    : null}
            </Grid >

        </Paper >)
    }
}
interface DataProps extends Order_Datos_Envio, Order_Datos_Facturacion {
    visible?: boolean,
    onValueChange: (prop: string, value: string) => void;
    thirdColumnContent?: React.ReactNode;
    hide_tipo_envio?: boolean;
    valor_pedido_calculo_envio?: number
} 