interface Options {
    passwordOpcional?: boolean;
}
export default function ValidateUserForm(state: VisibleUser, options: Options = {}) {
    const { nombre,
        telefono,
        identificacion,
        email,
        fechaNacimiento,
        nacionalidad,
        ciudad,
        direccion,
        username,
        password,
        parentId,
        confirm_password
    } = state;

    const result: any = {};

    if (isEmpty(nombre)) result.nombre = "Digita tu nombre";

    if (isEmpty(telefono)) {
        result.telefono = "Digita tu telefono";
    } else {
        if (isNaN(parseInt(telefono))) result.telefono = "El telefono solo puede contener numeros";
        if (telefono.length <= 5) result.telefono = "El telefono debe tener mínimo 6 digitos";
    }

    if (isEmpty(identificacion)) {
        result.identificacion = "Digita tu identificacion";
    }

    if (isEmpty(email)) {
        result.email = "Digita tu email";
    } else {
        if (email.indexOf("@") === -1) result.email = "El correo es incorrecto";
        if (email.indexOf(".") === -1) result.email = "El correo es incorrecto";
    }

    if (isEmpty(fechaNacimiento)) {
        result.fechaNacimiento = "Digita tu fecha de nacimiento";
    } else {
        if (isNaN(+new Date(fechaNacimiento))) result.fechaNacimiento = "La fecha no es válida";
    }

    if (isEmpty(nacionalidad)) result.nacionalidad = "Digita tu nacionalidad";
    if (isEmpty(ciudad)) result.ciudad = "Digita tu ciudad";
    if (isEmpty(direccion)) result.direccion = "Digita tu direccion";

    if (isEmpty(username)) result.username = "Digita tu Usuario";
    if (isEmpty(password)) {
        if (!options.passwordOpcional)
            result.password = "Digita la contraseña";
    } else {
        if (password.length < 5) result.password = "La contraseña debe tener más de 6 caracteres";
        if (password.trim() !== (confirm_password || "").trim()) result.confirm_password = "Las contraseñas no concuerdan entre si";
    }
    /*
    if (isEmpty(grupo)) {
        if (+rol === 2) result.grupo = "Digita el nombre de tu grupo";
        if (+rol === 1 || rol === undefined) result.grupo = "Selecciona tu grupo";
    }*/

    if (state.id !== 1 && isEmpty(parentId)) {
        result.parentId = "Selecciona tu Mamá Natú";
    }

    if (Object.keys(result).length) {
        var key = Object.keys(result)[0];
        FocusOn(key);
        return result;
    }
    return null;
}
export function FocusOn(id) {
    window.requestAnimationFrame(() => {
        try {
            document.getElementById(id).focus();
        } catch (ex) { }
    });
}
function isEmpty(value) {
    if (value === null) return true;
    if (value === undefined) return true;
    if (!value.toString().length) return true;
    return false;
}