/// <reference path="./lib/UserDataFormProps.d.ts" />
import React from "react";
import { InputAdornment, TextField, IconButton, Typography, Button, CircularProgress, FormControlLabel, Switch, RadioGroup, Radio, Checkbox } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import Input from "../Input";
import RolInput from "./RolInput";
import UserListInput from "./UserListInput";
import AcceptAgreementsInput from "../AcceptAgreementsInput";
import PersonalInfoBlock from "./UserForm/PersonalInfoBlock";


export default function (props: UserDataFormProps) {

    const {
        loading
        , id
        , onSubmit
        , onChange
        , formType
        , editionType
        , ...state } = props;


    const accept_policies = formType === "SIGN-IN" ? state.acceptAgrements : true;

    // parentId = 1 (no tiene parent)
    // parentId = null (no se ha seleccionado, pero esta disponible la lista)
    // parentId > 1 (ID de la asesora madre)

    const [useRootParent, set_useRootParent] = React.useState(1 == state.parentId ? "1" : "0");

    const radioGroupChanged = function (event: React.ChangeEvent<HTMLInputElement>) {
        set_useRootParent(event.target.value);
        if (event.target.value === "1") {
            (event.target as any).value = +event.target.value;
        } else {
            event.target.value = null;
        }
        (event.currentTarget as any).value = event.target.value;
        props.onChange(event);
    };


    function _onSubmit(event) {
        event.preventDefault();
        event.stopPropagation();
        if (accept_policies) onSubmit();
    }

    const editarEstado = formType === "EDIT" && editionType !== "USER" ? true : null;
    const editarTermsAndConds = formType === "EDIT" && editionType !== "USER";
    const editarParent = formType === "SIGN-IN" || (formType === "EDIT" && editionType === "ADMIN");

    const error = props.error || {};

    return (<form style={{ margin: "auto", maxWidth: 600 }} noValidate onSubmit={_onSubmit}>

        {editarParent
            ? (<RadioGroup name="parentId" value={useRootParent} onChange={radioGroupChanged}>
                <FormControlLabel value="1" control={<Radio disabled={loading} />} label="Ingreso independiente" />
                <FormControlLabel value="0" control={<Radio disabled={loading} />} label="Asesor/a qué te invitó" />
                <div style={useRootParent === "0" ? { display: "block", marginLeft: 20, marginTop: -10 } : { display: "none" }} >
                    <UserListInput
                        value={state.parentId}
                        readOnlyValue={editionType === "ADMIN" ? undefined : state.ParentName}
                        onChange={(value: any) => onChange({
                            currentTarget: { name: "parentId", value }, target: { name: "parentId", value }
                        } as any)}
                        error={error.parentId}
                        loading={loading}
                    />
                </div>
            </RadioGroup>)
            : <Typography>Asesor/a que te invitó: {props.ParentName || "Ninguno"}</Typography>
        }
        <PersonalInfoBlock
            {...state}
            error={error}
            onChange={onChange}
            loading={loading}
            sectionTitles={{
                address: formType === "SIGN-IN" ? "Agrega tu dirección" : "Dirección"
            }}
        />
        <Typography variant="h6" style={{ marginTop: 30 }}>{formType === "SIGN-IN" ? "Crea tu Usuario" : "Usuario"}</Typography>
        <Input
            disabled={loading}
            error={error.username}
            onChange={onChange}
            value={state.username || ""}
            name="username"
            label="Usuario"
        />
        <Password
            required={formType === "SIGN-IN"}
            disabled={loading}
            error={error.password}
            onChange={onChange}
            value={state.password || ""}
            name="password"
            label={formType === "EDIT" ? "Nueva contraseña" : "Contraseña"}
        />
        <Password
            required={formType === "SIGN-IN"}
            disabled={loading}
            error={error.confirm_password}
            onChange={onChange}
            value={state.confirm_password || ""}
            name="confirm_password"
            label={formType === "EDIT" ? "confirma la nueva contraseña" : "Confirma tu contraseña"}
        />


        {
            formType === "EDIT"
                ? <RolInput value={"" + (state.rol || "")} loading={loading} onChange={onChange} readOnlyValue={editionType === "ADMIN" ? "" : state.Rol} />
                : null
        }

        {
            editarEstado &&
            <Input
                select
                disabled={loading}
                value={(state.estado === undefined || state.estado === null) ? "" : state.estado.toString()}
                name="estado"
                label="Estado"
                SelectProps={{ native: true }}
                onChange={onChange}
            >
                <option value="1" >PENDIENTE</option>
                <option value="0" >APROBADO (Habilitado)</option>
                <option value="2" >RECHAZADO (Deshabilitado)</option>
            </Input>
        }

        {
            formType === "SIGN-IN"
                ? (<div style={{ marginTop: 20 }} >
                    <AcceptAgreementsInput
                        checked={state.acceptAgrements || false}
                        onChange={onChange}
                        name="acceptAgrements"
                        disabled={loading}
                    />
                </div>)
                : null
        }

        {
            editarTermsAndConds &&
            <div style={{ margin: "auto", textAlign: "center", padding: 20 }}>
                <FormControlLabel
                    control={<Checkbox
                        onChange={onChange}
                        name="mustAcceptTermsAndConds"
                        checked={state.mustAcceptTermsAndConds || false}
                    />}
                    label="Mostrar 'Aceptar Terminos y Condiciones'; y 'aceptar Políticas de privacidad' al iniciar"
                />
                {state.termsAndCondsAcceptedAt && <div style={{ marginTop: 10 }}>
                    <Typography variant="button">
                        Este usuario ha aceptado los Términos y Condiciones el:
                        {new Date(state.termsAndCondsAcceptedAt).toLocaleString()}
                    </Typography>
                </div>}
            </div>
        }


        {(error.guardar) ? <Typography tabIndex={0} id="guardar" color="secondary">{error.guardar}</Typography> : null}
        {
            formType === "SIGN-IN" &&
            (<Button disabled={!accept_policies} type="submit" color="primary" variant="outlined" fullWidth style={{ marginTop: 20 }} >
                {loading ? <CircularProgress size={20} color="inherit" /> : "ENVIAR"}
            </Button>)
        }
        {props.children}
    </form >
    );
}
function Password(props: any) {
    const { error, ...other_props } = props;
    const [show_password, switchShowPassword] = React.useState(false);
    var input_props: any = { inputProps: { id: props.name } };
    if (error) {
        input_props.error = true;
        input_props.helperText = error;
    }
    if (!other_props.style) other_props.style = {
        margin: 10,
        width: "calc(100% - 20px)"
    }
    return (<TextField
        type={show_password ? "text" : "password"}
        required
        fullWidth
        {...other_props}
        {...input_props}
        InputProps={{
            endAdornment: <InputAdornment position="end" >
                <IconButton onClick={x => switchShowPassword(!show_password)}>
                    {show_password ? <Visibility /> : <VisibilityOff />}
                </IconButton>
            </InputAdornment>
        }}
    />);
}