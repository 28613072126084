import React from "react";
import { Paper, Typography, Divider, makeStyles } from "@material-ui/core";
import API from "../Services/API";
import { Link } from "react-router-dom";
import Preferences from "../Utils/Preferences";
import UserDataForm from "../Components/Users/UserDataForm";
import ValidateUserForm, { FocusOn } from "../Components/Users/ValidateUserForm";

export default class SignIn extends React.Component {
    state = {
        nombre: "",
        identificacion: "",
        telefono: "",
        email: "",
        fechaNacimiento: "",
        nacionalidad: "",
        ciudad: "",
        direccion: "",
        username: "",
        password: "",
        confirm_password: "",
        acceptAgrements: false,
        error: null,
        parentId: null,
        loading: false,
        fin_registro: null
    }
    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type, checked } = event.currentTarget;
        if (type === "checkbox") return this.setState({ [name]: checked });
        this.setState({ [name]: value });
    }
    handleSubmit = () => {
        if (this.state.loading) return;
        if (!this.state.acceptAgrements) return;
        var error = ValidateUserForm(this.state);
        if (error) return this.setState({ error });
        this.setState({ loading: true, error: null });
        setTimeout(() => {
            // AQUI POR DEFAULT los envio como ROL=3 (asesores)
            API.SignIn({ ...this.state })
                .then(x => {
                    this.setState({ loading: false, fin_registro: x.email });
                    Preferences.Set("user", this.state.username);
                }).catch(error =>
                    this.setState({ error, loading: false },
                        () => {
                            Object.keys(error).forEach(a => {
                                FocusOn(a);
                            });
                        })
                );
        }, 2000);
    }
    render() {
        const { fin_registro, ...state } = this.state;

        if (fin_registro) return (<div style={{ width: "100%", padding: 30, textAlign: "center" }}>
            <Typography variant="h4">Registro completado!</Typography>
            <Typography variant="subtitle1">
                Para habilitar tu cuenta entra a tu correo ({fin_registro})
                y abre el enlace que te enviamos.
            </Typography>
            <Typography variant="body1">
                Si no encuentras nuestro correo en tu Bandeja de Entrada, es posible que encuentre en SPAM o NO DESEADOS.
            </Typography>
        </div>);

        return (<main style={{ width: "calc(100% - 20px)", padding: 10, textAlign: "center", backgroundColor: "#fafafa" }}>
            <Paper style={{ padding: 20, margin: "auto", maxWidth: 600 }}>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <SignInImage />
                    <div style={{ flexGrow: 1, margin: 20, textAlign: "left" }}>
                        <Typography variant="h4">REGISTRATE</Typography>
                        <Typography component={Link} to="/login" >Ya tienes una cuenta? Inicia sesión aquí</Typography>
                    </div>
                </div>
                <Divider />
                <UserDataForm editionType="USER" {...state} onChange={this.handleChange} onSubmit={this.handleSubmit} formType="SIGN-IN" />
            </Paper>
        </main>);
    }
}


const useStyles = makeStyles(theme => ({
    img: {
        margin: "auto",
        [theme.breakpoints.down("sm")]: {
            maxHeight: "100%",
            maxWidth: "25vw"
        }
    }
}));
function SignInImage() {
    const classes = useStyles();
    return (<div>
        <img className={classes.img} src="./logo.png" alt="Natu - Logo" />
    </div>);
}