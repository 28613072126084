import { Card, CardContent, Table, TableHead, TableBody, TableCell, TableRow, CardHeader } from "@material-ui/core";
import React from "react";

export default function (props: {
    data: ComisionBalanceRow[];
}) {

    const [resumen, data] = React.useMemo(() => {

        const [resumen, data] = [...props.data]
            .sort((a, b) => new Date(a.createdAt).valueOf() - new Date(b.createdAt).valueOf())
            .reduce(([resumen, data]: any, b: any, _, all) => {
                resumen.credito += b.credito;
                resumen.debito += b.debito;
                resumen.saldo += (b.credito - b.debito);
                if (b.tipo === "comision") {
                    let paidAt: any;
                    if (b.credito === 0) {
                        paidAt = b.createdAt;
                    } else {
                        paidAt = all.find(pago => pago.id === b.id && pago.debito)?.createdAt;
                    }
                    data.push({ ...b, paidAt, saldo: resumen.saldo, fecha: new Date(b.createdAt) });
                }
                return [resumen, data];
            }, [{ credito: 0, debito: 0, saldo: 0 }, []]);

        data.sort((a, b) => b.fecha.valueOf() - a.fecha.valueOf());

        return [resumen, data];
    }, [props.data]);

    return (
        <Card className="dashboard-card" style={{ display: "flex", flexDirection: "column" }} >
            <CardHeader style={{ textAlign: "center" }} title="Detalle de comisiones" subheader={("Pendiente " + resumen.saldo.toCurrency())} />
            <CardContent style={{ flex: 1, overflow: "auto" }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell component="th">Cobrado</TableCell>
                            <TableCell component="th">Ciclo</TableCell>
                            <TableCell component="th" style={{ textAlign: "right" }}>Monto</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((item, index) => (<TableRow key={index}>
                            <TableCell>{item.paidAt ? "✅" : null}</TableCell>
                            <TableCell>{item.ciclo_name || `Ciclo ${item.ciclo.toString().split(".")[1]}`}</TableCell>
                            <TableCell style={{ textAlign: "right" }}>{item.credito.toCurrency()}</TableCell>
                        </TableRow>))}
                    </TableBody>
                </Table>
            </CardContent>
        </Card>);
}