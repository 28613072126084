import React from "react";
import { Typography } from "@material-ui/core";

export default function ContificoDocInfo(props: {
    ride?: string,
    xml?: string,
    total?: string | number,
    gran_total?: number,
    tipo_documento?: string
}) {

    const total = parseFloat(props?.total?.toString() || "0").moneyRound();

    if (total == 0) return null;
    const gran_total = parseFloat(props?.gran_total?.toString() || "0").moneyRound();
    var tipo_documento = "";
    if (props.tipo_documento === "FAC") tipo_documento = "Factura";
    if (props.tipo_documento === "PRE") tipo_documento = "Prefactura";

    const diferencia = total != gran_total;

    return (<>
        {props.ride && <>|&nbsp;<a href={props.ride} target="_blank">ver RIDE</a></>}
        {props.ride && <>&nbsp;/&nbsp;<a href={props.xml} target="_blank" >ver XML</a></>}
        &nbsp;{diferencia
            ? (<Typography component="span">{tipo_documento} tiene una ({total.toCurrency()}) diferencia de {(total - gran_total).moneyRound().toCurrency()} con el valor del pedido.</Typography>)
            : null}
    </>);
}