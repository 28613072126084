/**
 * Lista de nombres de accesos. Se debe usar exclusivamente este enum
 * para referirse a accesos tanto en consultas a base de datos como en el codigo.
 * El uso de reglas en texto literal está prohibido.
 */
enum ROLE_ACCESS {
    DebeHacerPedidos = "rules.debe-hacer-pedidos"
    , puedeUsarYapas = "rules.puede-tener-yapas"
    , puedeSeleccionarDescuentoPedido = "rules.descuento-seleccionable-en-toma-pedido"
    , puedeEditarPreciosPedido = "rules.editar-precios-en-toma-pedido"
};

export default ROLE_ACCESS;