import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../../../Services/API";
import { validateCustomerData } from "../../../Order/Redux/Thunks/OrderSaveThunk";
import { RootState } from "../../../../Redux/Store";
import { getValorEnvio } from "../../../Order/mapOrderState";

const RedeemPointsThunk = createAsyncThunk(
    "Premio/Redeem"
    , function (_, Thunk) {
        const { UserPoints: params, User } = Thunk.getState() as RootState;
        if (!(params && params.order && params.productList)) return Thunk.rejectWithValue({
            message: "No hay información para guardar. Ingrese información para continuar."
        });
        const productList = params.productList.filter(x => x.cantidad).map(x => ({ cantidad: x.cantidad, id: x.id }));
        if (!productList.length) return Thunk.rejectWithValue({
            message: "Seleccione al menos 1 producto"
        });
        const envio = getValorEnvio(0, params.order.tipo_envio);
        if (envio) {
            const validations = validateCustomerData({ ...params.order, detalle: productList });
            if (validations) {
                const key = Object.keys(validations)[0];
                return Thunk.rejectWithValue({
                    message: validations[key]
                });
            }
        }
        return API.RedeemPoints({
            userId: User.userId
            , productList
            , pedido: { ...params.order, envio }
        }).catch(ex => {
            return Thunk.rejectWithValue(ex);
        });
    }
);

export default RedeemPointsThunk;