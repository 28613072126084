import React from "react";
import { ListItem, List, ListItemIcon, IconButton, ListItemText, ListItemSecondaryAction, TextField } from "@material-ui/core";
import { Add, Delete } from "@material-ui/icons";

interface InputOptions {
    label?: string;
    type: "number" | "string";
    allowZero?: boolean;
    name: string;
}

interface Props {
    dataSource: any[];
    onAdd: (results: { name?: string, item?: any, [key: string]: any }) => void;
    onRemove: (results: { name?: string, item?: any, index?: number }) => void;
    items: any[];
    name?: string;
    IdProp: string;
    DisplayProp: string;
    SecondaryDisplayProp?: string;

    inputs: InputOptions[]
}

var COUNT = 0;

export default class ItemList extends React.PureComponent<Props> {
    state = {
        inputValues: {},
        selectValue: "",
        showErrors: false
    }
    INPUT_ID = "CUSTOM_INPUT_ID_NO_";
    SELECT_ID = "CUSTOM_SELECT_ID_NO_";
    constructor(props) {
        super(props);
        this.INPUT_ID += COUNT;
        this.SELECT_ID += COUNT;
        COUNT++;
    }
    handleDelete = event => {
        const index = event.currentTarget.value;
        var item = this.props.items[index];
        this.props.onRemove({ name: this.props.name, item, index });
    }
    handleAdd = () => {
        const { inputs, IdProp, dataSource, onAdd, name } = this.props;
        const { inputValues, selectValue } = this.state;

        if (inputs.length) {
            var errors = {};
            inputs.forEach(input => {
                var error = validateInput(inputValues[input.name], input);
                if (error) errors[input.name] = error;
            });
            if (Object.keys(errors).length) {
                window.requestAnimationFrame(() => {
                    try { document.getElementById(this.INPUT_ID).focus() } catch (ex) { }
                });
                return this.setState({ showErrors: true });
            }
        }
        if (!selectValue) {
            window.requestAnimationFrame(() => {
                try { document.getElementById(this.SELECT_ID).focus() } catch (ex) { }
            });
            return this.setState({ showErrors: true });
        }
        var item = dataSource.find(x => x[IdProp].toString() === selectValue.toString());
        onAdd({ name: name, item: { ...item }, ...inputValues });
        this.setState(() => ({ selectValue: "", inputValues: {}, showErrors: false }));
    }
    handleChange = (event) => {
        const name = event.currentTarget.name as string;
        const value = event.currentTarget.value as any;
        if (name.startsWith("inputValues.")) {
            this.setState({
                inputValues: {
                    ...this.state.inputValues
                    , [name.replace("inputValues.", "")]: value
                }
            })
        } else {
            this.setState({ [name]: value } as any);
        }
    }
    render() {
        const { inputs, DisplayProp, IdProp, SecondaryDisplayProp, items } = this.props;
        var dataSource = this.props.dataSource;
        const { inputValues, selectValue, showErrors } = this.state;

        const inputErrors = showErrors ? {} : null;
        if (inputErrors)
            inputs.forEach(input => {
                const err = validateInput(inputValues[input.name], input);
                if (err) inputErrors[input.name] = err;
            });
        const selectError = showErrors
            ? (selectValue ? "" : "Selecciona un item")
            : "";

        var IDs = this.props.items.map(x => x[IdProp]);
        dataSource = dataSource.filter(x => IDs.indexOf(x[IdProp]) < 0);

        return (<>
            <div style={{ display: "flex", flexDirection: "row", width: "calc(100% - 20px)", margin: "auto" }}>
                <TextField
                    style={{ flex: 1, marginTop: 8, marginBottom: 8 }}
                    inputProps={{ id: this.SELECT_ID }}
                    SelectProps={{ native: true }}
                    error={!!selectError} helperText={selectError}
                    select
                    name="selectValue"
                    value={selectValue}
                    onChange={this.handleChange}
                >
                    <option value=""> - SELECCIONA UNA OPCIÓN - </option>
                    {dataSource.map(x => (
                        <option key={x[IdProp]} value={x[IdProp]}>{x[DisplayProp]}</option>
                    ))}
                </TextField>
                {inputs.map(inputConfig => (
                    <TextField
                        key={inputConfig.name}
                        error={!!(inputErrors && inputErrors[inputConfig.name])}
                        helperText={inputErrors && inputErrors[inputConfig.name]}
                        style={{ margin: 10 }}
                        margin="dense"
                        type={inputConfig.type || "text"}
                        value={inputValues[inputConfig.name] || ""}
                        name={"inputValues." + inputConfig.name}
                        onChange={this.handleChange}
                        placeholder={inputConfig.label || ""}
                        inputProps={{ id: this.INPUT_ID }}
                    />
                ))}
                <div>
                    <IconButton onClick={this.handleAdd} >
                        <Add />
                    </IconButton>
                </div>
            </div>
            {items && items.length
                ? (<List>
                    {items.map((x, index) => (<ListItem key={x[IdProp]} dense >
                        {SecondaryDisplayProp && <ListItemIcon>{x[SecondaryDisplayProp]}</ListItemIcon>}
                        <ListItemText>{x[DisplayProp]}</ListItemText>
                        <ListItemSecondaryAction>
                            <IconButton value={index} name="delete" onClick={this.handleDelete}>
                                <Delete />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>))}
                </List>)
                : null}
        </>);
    }
}

function validateInput(value: string, input: InputOptions) {
    var x = (value || "").trim();
    if (!x) return "Digite un valor";
    if (input.type === "number") {
        if (isNaN(+x)) return "Digite un número";
    }
    return;
}