import React from "react";
import { CardActionArea, CardMedia, Card, Typography, IconButton } from "@material-ui/core";
import { Close, Image } from "@material-ui/icons";
interface Props {
    src: string;
    onChange(imageFile?: File): void;
    onDelete?(): void;
    className: string;
    title?: string;
    disabled?: boolean;
    loading?: boolean;
}
export default class ImageInput extends React.PureComponent<Props> {
    COMPONENT_ID = "IMAGE_INPUT_" + new Date().valueOf()
    handleClick = _ => {
        document.getElementById(this.COMPONENT_ID).click();
    }
    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.currentTarget.files;
        this.props.onChange((files && files.length && files[0]) || null);
    }
    render() {
        const { src, title, className, loading, disabled, onDelete } = this.props;
        return (<Card>
            {onDelete && <div style={{ textAlign: "right" }}>
                <IconButton onClick={onDelete}>
                    <Close />
                </IconButton>
            </div>}
            <CardActionArea disabled={disabled} onClick={this.handleClick} style={{ textAlign: "center" }} type="button">
                <input
                    hidden={true}
                    type="file"
                    onChange={this.handleChange}
                    accept="image/*"
                    id={this.COMPONENT_ID}
                />
                {src
                    ? <CardMedia component="img" className={className} title={title || "Imagen"} image={src} />
                    : <div className={className} style={{ display: "table", textAlign: "center", margin: "auto" }} >
                        <div style={{ display: "table-cell", verticalAlign: "middle" }}>
                            {loading ?
                                <Typography variant="h3">CARGANDO...</Typography>
                                : <>
                                    <Image />
                                    <Typography>Aplasta aquí para subir una imagen</Typography>
                                </>}
                        </div>
                    </div>}
            </CardActionArea>
        </Card>);
    }
}