import React from "react";
import { Dialog, DialogContent, Typography, TextField, DialogActions, Button, CircularProgress } from "@material-ui/core";
import API from "../../Services/API";
import { withRouter } from "react-router-dom";

export default withRouter(class Survey extends React.Component<any, any> {
    state = { txt1: "", txt2: "", loading: false, id: "" };
    constructor(props) {
        super(props);
        this.handleClose = this.handleClose.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state.id = props.match.params.id;
    }
    handleChange(event) {
        const { name, value } = event.currentTarget;
        this.setState({ [name]: value });
    }
    unblock() { }
    componentDidMount() {
        this.unblock = this.props.history.block = () =>
            this.state.loading ? "Espera un momento, tu información se está guardando" : null
    }

    handleSubmit(event) {
        event.preventDefault();
        const { txt1, txt2, loading, id } = this.state;
        if (loading) return;
        if (!txt1 && !txt2) {
            return this.props.history.goBack();
        }
        this.setState({ loading: true });
        API.ActionOrder(id, {
            action: "SURVEY",
            value: [txt1, txt2]
        }).then(x => {
            this.setState({ loading: false });
            this.props.history.goBack();
        }).catch(ex => {
            this.setState({ loading: false });
            alert(ex.message);
        });
    }
    handleClose() {
        if (this.state.loading) return;
        this.props.history.goBack();
    }
    render() {
        const { props } = this;
        const { txt1, txt2, loading } = this.state;
        return (<Dialog open={true} onClose={this.handleClose}>
            <form onSubmit={this.handleSubmit}>
                <DialogContent>
                    <Typography style={{ textAlign: "center" }} variant="h6" >{props.title}</Typography>
                    <Typography style={{ marginTop: 10 }} variant="subtitle1">Cómo podemos ayudarte a mejorar tus ventas?</Typography>
                    <TextField autoFocus={true} name="txt1" multiline rows={2} label="Escribe tu respuesta" value={txt1} onChange={this.handleChange} fullWidth />

                    <Typography style={{ marginTop: 10 }} variant="subtitle1">Compártenos alguna experiencia que hayan tenido tus clientes usando nuestros productos</Typography>
                    <TextField name="txt2" multiline rows={3} label="Escribe tu respuesta" value={txt2} onChange={this.handleChange} fullWidth />
                </DialogContent>
                <DialogActions>
                    <Button type="submit" variant="outlined" >{loading ? <CircularProgress size={25} /> : "GUARDAR"}</Button>
                </DialogActions>
            </form>
        </Dialog>)
    }
});