import React from "react";
import { TextField, TextFieldProps } from "@material-ui/core";
interface InputProps extends Omit<TextFieldProps, "error"> {
    type?: string;
    value?: string;
    error?: string;
    name?: string;
    maxLength?: number;
    style?: React.CSSProperties;
}

export default function Input(props: InputProps) {
    var { error, maxLength, ..._props } = props;
    var error_props: any = {};

    if (error) {
        error_props.error = true;
        error_props.helperText = error;
    } else {
        error_props.helperText = " "
    }

    if (!_props.inputProps) _props.inputProps = {};
    _props.inputProps.id = _props.name;
    if (maxLength) _props.inputProps.maxLength = maxLength;
    if (!_props.style) _props.style = {
        margin: 10,
        width: "calc(100% - 20px)"
    }

    return <TextField
        {...error_props}
        {..._props}
    >{props.children}
    </TextField>
}